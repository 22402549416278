$button-medium: 0.5rem 2rem;
$button-small: 0.8rem 2rem;
$button-tiny: 0.4rem 2rem;

.button,
input[type="submit"] {
  display: inline-block;
  color: $black;
  background: $gold;
  font-weight: 700;
  padding: $button-medium;
  text-align: center;
  font-family: $font-condensed;
  transition: $transition-fast;

  &:hover {
    background-color: darken($gold, 10%);
  }

  &.button--large {
    font-size: $body-font-size;
  }

  &.button--secondary {
    border-color: $secondary;
    border: 3px solid $secondary;
    color: $white;
    position: relative;
    transition: transform $time $bezier;
    margin-top: 0.5rem;
    word-break: keep-all;
    white-space: nowrap;

    //&:after {
    //  content: '';
    //  background-image: url("img/icons/arrow-black-right.svg");
    //  background-size: contain;
    //  background-repeat: no-repeat;
    //  position: absolute;
    //  width: 1.5rem;
    //  height: 1.5rem;
    //  right:-0.5rem;
    //  bottom: 0.35rem;
    //}

    &:hover {
      color: $black;
      background: $secondary;
    }
  }

  &.button--share {
    padding: $button-tiny;
    width: 18rem;
  }

  &.button--flex {
    display: flex;
    align-items: center;
  }

  &.button--testimonial {
    padding: $button-medium;

    @include breakpoint(medium up) {
      margin-bottom: 2rem;
    }
  }

  &.button--nobreak {
    padding: 0.8rem 1.8rem;
    width: 100%;
  }
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button__block {
  margin: $general-margin / 2 0 $general-margin 0;
}

.button__block--center {
  width: 100%;

  button {
    margin: 0 auto;
    display: block;
  }
}

.download__icon {
  margin-left: 1rem;

  svg {
    width: 1rem;
    height: 100%;
    transform: translateY(2px);

    @include breakpoint(medium) {
      width: 1.25rem;
    }
  }
}

// general link styling

.link-item {
  display: flex;
  align-items: center;

  .appendix-box--projects & {
    margin-left: auto;
    width: fit-content;
  }

  &.link-item--small {
    width: fit-content;
  }

  &:hover:not(.no-hover) {
    //.link-item__rect {
    //  transform: scaleX(0);
    //  transition-delay: 0s;
    //  animation: none;
    //}
    .link-item__text {
      //transform: translateX(-40px);
      //transition-delay: 0s;

      @extend %topic-theme-font-color;
    }
  }
}

.link-item--hover {
  &:hover {
    .link-item__rect {
      transform: scaleX(1);
    }

    .link-item__text {
      @extend %topic-theme-font-color;
      transform: translateX(0);

      @include breakpoint(small only) {
        .home-teaser__box & {
          color: $black;
        }
      }
    }
  }
}

.link-item__rect {
  position: relative;
  width: 30px;
  height: $rect-height;
  margin-right: 1rem;
  transform-origin: left;
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);

  @extend %topic-theme-color-background-cover;

  .link-item--sleeping & {
    transform: scaleX(0);
    transition-delay: 0s;
    animation: none;
  }

  @include breakpoint(small only) {
    .home-teaser__box & {
      background-color: $black;
    }
  }

  &.link-item__rect--white {
    background-color: $white;
  }
}

.link-item__text {
  display: block;
  //line-height: 0;
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1),
    color 0.4s cubic-bezier(0.25, 1, 0.5, 1);

  .link-item--sleeping & {
    transform: translateX(-42px);
  }
}
