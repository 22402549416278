.slider {
  overflow: hidden;

  @extend %topic-theme-color-background-small;

  @include breakpoint(small only) {
    padding: 2rem 1rem;
  }
}

.factslider__slides {
  overflow: hidden;
  position: relative;
}

.factslider__slide {
  position: relative;
  z-index: 100;
  //overflow: hidden;
}

.factslider__slider {
  display: flex;
  overflow: hidden;
}

.facts {
  position: relative;
  //height: 120vh;
  overflow: hidden;

  @include breakpoint(medium) {
    padding-bottom: 10rem;
  }

  @include breakpoint(large) {
    padding-bottom: 25rem;
  }
}

.facts__container {
  position: relative;
  display: flex;
  z-index: 100;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 5vh;
  width: 100%;
  height: 100%;
  @include breakpoint(medium) {
    overflow: hidden;

    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 0;
  }
}

.fact {
  flex: 0 1 50%;

  @include breakpoint(medium) {
    flex: 0 1 50%;
  }
}

.fact__content-wrapper {
  display: flex;
}

.fact__content {
  max-width: 80%;
}

$fact-divider-length: 30rem;

.fact__divider {
  height: $fact-divider-length;
  width: 2px;
  background: $white;
  //margin-right: 2rem;
  position: relative;
  display: none;

  &:after {
    content: "";
    position: absolute;
    bottom: 0;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $white;
    transform: translate(calc(-50% + 1px), 0);
  }

  @include breakpoint(medium) {
    display: block;
  }

  &.fact__divider--large {
    height: $fact-divider-length;
  }

  &.fact__divider--medium {
    height: $fact-divider-length * 0.8;
  }

  &.fact__divider--small {
    height: $fact-divider-length * 0.6;
  }
}

@keyframes factsAnimationMobile {
  from {
    transform: translateY(-150px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.2;
  }
}

.slider__container {
  display: flex;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.left__wrapper {
  height: 100%;
  width: 100%;
  //padding: 0 4rem;
  position: relative;
  z-index: 20;

  @include breakpoint(small only) {
    background-color: transparent !important;
  }
}

.left__inner-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;

  @include breakpoint(small only) {
    min-height: 10rem;
    width: 100%;
  }

  .slider--subpage & {
    @include breakpoint(medium) {
      //margin-left: 4vw;
    }

    @include breakpoint(large) {
      //margin-left: calc(4 * (3vw + 1vh - 1vmin));
    }
  }
}

.slider--left-content {
  position: absolute;
  text-align: left;
  width: 80%;

  @include breakpoint(small only) {
    display: flex;
    flex-direction: column;
    [data-metric] {
      order: 2;
      margin-top: 0.5rem;
      color: $black;
    }

    .slider--subline {
      color: $black;
    }
  }

  @include breakpoint(medium) {
    text-align: right;

    position: absolute;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }

  @include breakpoint(large) {
    width: 65%;
    margin-left: 10%;
    margin-right: 25%;
  }
}

@keyframes factsAnimationRight {
  from {
    transform: translateX(150px);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes factsAnimationLeft {
  from {
    transform: translateX(-150px);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

[data-metric] {
  position: relative;
  line-height: normal;
  visibility: hidden;
  width: 100%;
  color: $white;

  &[data-current] {
    animation: 0.8s ease-out 0.1s 1 normal forwards running fadeInSlide;
    //[data-dir="1"] & {
    //  animation: 0.5s ease-out 0.1s 1 normal forwards running factsAnimationLeft;
    //}
    //[data-dir="-1"] & {
    //  animation: 0.5s ease-out 0.1s 1 normal forwards running
    //    factsAnimationRight;
    //}

    //@include breakpoint(medium) {
    //  [data-dir="-1"] & {
    //    animation: 0.5s ease-out 0.1s 1 normal forwards running
    //      factsAnimationLeft;
    //  }
    //  [data-dir="1"] & {
    //    animation: 0.5s ease-out 0.1s 1 normal forwards running
    //      factsAnimationRight;
    //  }
    //}
  }
}

.slider--subline {
  position: relative;
  line-height: normal;
  visibility: hidden;

  width: 100%;
  color: $white;
  display: block;

  &[data-current] {
    animation: 0.8s ease-out 0.2s 1 normal forwards running fadeInSlide;
    //
    //[data-dir="1"] & {
    //  animation: 0.5s ease-out 0.1s 1 normal forwards running factsAnimationLeft;
    //}
    //[data-dir="-1"] & {
    //  animation: 0.5s ease-out 0.1s 1 normal forwards running
    //    factsAnimationRight;
    //}
    //
    //@include breakpoint(medium) {
    //  [data-dir="-1"] & {
    //    animation: 0.5s ease-out 0.1s 1 normal forwards running
    //      factsAnimationLeft;
    //  }
    //  [data-dir="1"] & {
    //    animation: 0.5s ease-out 0.1s 1 normal forwards running
    //      factsAnimationRight;
    //  }
    //}
  }
}

.right__wrapper {
  position: relative;
  z-index: 20;

  .slider--subpage & {
    //padding: 6rem 0;
  }
}

.factslide__content {
  margin-bottom: 6rem;
  @include breakpoint(medium) {
    margin: 3rem 3rem 4rem 2rem;

    .slider--subpage & {
      max-width: 90%;
    }
  }
  @include breakpoint(large) {
    margin: 4rem 4rem 6rem 4rem;

    .slider--subpage & {
      max-width: 80%;
    }
  }
}

.media-slider__thumbs {
  display: flex;
  position: absolute;
  bottom: 1rem;
  justify-content: flex-end;
  right: 0.5rem;

  a[data-thumb] {
    position: relative;
    height: 12px;
    width: 8px;
    margin-right: 0.5rem;
    padding: 0;
    background-color: $white;
    border: 0 none;
    outline: none;
    cursor: pointer;

    &[data-current] {
      opacity: 1;
      position: relative;
      transition: all ease-in-out 200ms;
      @extend %topic-theme-color-background-cover;
    }
  }
}

.media-slider__go-wrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  z-index: z-index("controls");

  @include breakpoint(medium) {
    left: -100px;
  }
}

.media-slider__go {
  display: block;
  line-height: 0;

  &[data-go="-1"] {
    margin-right: 2px;

    &:after {
      //transform: translate(-60%, -38%);
    }
  }

  &[data-go="1"] {
    &:after {
      //content: url("img/icons/arrow-right.svg");
      //transform: translate(-40%, -38%);
    }
  }
}

.svg-stroke-theme-color {
  @extend %topic-theme-color-stroke;
}

// Subpage Slider

.slider__title {
  @include resprop(
    "font-size",
    (
      $h1-font-size * 2,
      $h1-font-size * 0.75,
      $h1-font-size * 0.9,
      $h1-font-size-xlarge * 0.8
    )
  );
  margin-bottom: $general-margin * 1.5;
  margin-top: $general-margin;
}

.factslide {
  opacity: 0;
  transition: opacity 0.8s cubic-bezier(0.76, 0, 0.24, 1) 0.35s;

  .slider--subpage & {
    display: flex;
    align-items: center;
  }

  a:not(.link-item) {
    @extend %topic-theme-font-color;
  }
}

.slider--subpage {
  &.slider--subpage-filled {
    @extend %topic-theme-color-background;
  }

  &.slider--subpage-home {
    position: relative;
    @include breakpoint(medium) {
      &:after,
      &:before {
        content: "";
        position: absolute;
        height: 100%;
        width: 50%;
        top: 0;
        z-index: 0;
      }

      &:after {
        background-color: $gold;
        left: 0;
      }

      &:before {
        background-color: $gold-alpha;
        right: 0;
      }
    }
  }
}

.subpage-slider__list--links,
.topic-slider__list--links {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subpage-slider__list-element {
  list-style: none;
  display: flex;
  align-items: center;
  color: $black;
  width: 100%;

  margin-bottom: 1rem;

  @include breakpoint(medium) {
    height: 4rem;
    margin-bottom: 0;
  }

  span {
    width: 100%;
  }

  &:before {
    content: "";
    height: 2px;
    width: 1rem;
    background-color: $gold;
    display: block;
    transform: translateY(2px);
    margin-right: 1rem;

    @include breakpoint(medium) {
      background-color: $white;
    }
  }
}

.topic-slider__list-element {
  list-style: none;
  display: flex;
  align-items: center;
  color: $black;

  width: 100%;

  margin-bottom: 1rem;

  .topic-slider__page-links {
    transition: opacity 0.35s;
    opacity: 0.4;
  }

  @include breakpoint(medium) {
    height: 4rem;
    margin-bottom: 0;
  }

  span {
    width: 100%;
  }

  &:before {
    content: "";
    height: 2px;
    width: 1rem;
    background-color: $gold;
    display: block;
    margin-right: 1rem;
    transition: width 0.55s cubic-bezier(0, 0, 0.175, 1) 0.15s, transform 0.25s;
    transform-origin: center center;
    transform: scaleY(1) translateY(0px);
  }

  &[data-current] {
    &:before {
      transform: scaleY(2.5) translateY(0px);
      width: 2rem;
    }
  }
}

.subpage-slider__list {
  display: flex;
  align-content: center;
  height: 100%;
  position: relative;
  width: 100%;
}

.subpage-slider__list-container,
.topic-slider__list-container {
  display: flex;
  padding: 0;
  margin-left: 0;
  margin-top: 2rem;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  //width: 100%;
  opacity: 0;
  transition: opacity 0.35s;
  pointer-events: none;

  &:hover {
    .subpage-slider__page-links {
      opacity: 0.6;
    }
  }

  &[data-current] {
    opacity: 1;
    pointer-events: all;
  }
}

.topic-slider__list-container {
  opacity: 1;
  pointer-events: all;
}

.subpage-slider__page-links {
  display: block;
  width: fit-content;
  transition: opacity 0.35s;

  &:hover {
    opacity: 1 !important;
  }
}

.media-slider__go-wrapper--testimonials {
  left: auto;
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-items: flex-end;
  [data-go="-1"] {
    margin-left: auto;
  }
  .svg-stroke-theme-color {
    stroke: $white !important;
  }
  .svg-bg-theme-color {
    @extend %topic-theme-color-fill;
  }
}

// Topic Slider

.topic-slider__content {
  margin-bottom: 3rem;

  @include breakpoint(medium) {
    padding-top: 8vh;
  }
  @include breakpoint(large) {
    padding-top: 12vh;
  }
}

.topic-slider__go-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: $gold;
  padding: 1rem 1rem;
  width: 230px;

  @include breakpoint(medium) {
    right: 30%;
    width: 300px;
  }

  .topic-slider__go {
    &.topic-slider__go--right {
      svg {
        transform: rotate(-180deg) translateY(-4px);
      }
    }
    &.topic-slider__go--left {
      svg {
        transform: translateY(4px);
      }
    }
  }
}

.topic-slider__go-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.topic-slider__previous-slide {
}

.topic-slider__next-slide {
}

.topic-slider__page-links {
  [data-current] & {
    opacity: 1;
  }
}
