// Page Header

.page-header {
  @extend %page-teaser-gradient;

  padding: 6rem 0;

  @include breakpoint(medium) {
    padding: 12rem 0;
  }

  color: $white;
}

.page-header__content {
  @include breakpoint(large) {
    margin-top: 3rem;
  }
}

.page-header__image-container {
  margin-left: 3rem;
  padding: 1rem;

  @include breakpoint(large) {
    margin-left: 5rem;
  }

  img {
    max-width: 100%;
    display: block;
  }
}

.page-header__toptitle {
  @extend %small-title-font-styles;

  margin-bottom: 0.5rem;
}
