.topic-entry--section {
  position: relative;

  @include breakpoint(small only) {
    margin-top: 10rem;
  }
}

.topic-entry__square {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;

  transform: translate(-50%, 25%);
  transform-origin: center center;

  @extend %topic-theme-color-background;

  @include breakpoint(medium) {
    display: block;
    height: 500px;
    width: 500px;
    transform: translate(-25%, -50%);
    transform-origin: center center;
  }

  @include breakpoint(large) {
    height: 600px;
    width: 600px;
    transform: translate(-10%, -50%);
    transform-origin: center center;
  }
}

.topic-entry--right {
  //height: 100%;
  //margin-top: auto;
  //margin-bottom: 6rem;
  //margin-left: auto;
  //
  //@include breakpoint(small only) {
  //  margin-top: $general-margin;
  //  margin-bottom: $general-margin;
  //  margin-left: 0;
  //}
}

.project-filter__teaser-box {
  margin-bottom: 15rem;
}

.appendix-box__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.appendix-box {
  display: block;
  padding: 3rem 3rem 1.5rem 1.5rem;
  @extend %topic-theme-color-background-cover;
  margin-top: 4rem;
  margin-left: 0;
  pointer-events: all;
  user-select: all;
  max-width: 100%;

  .page--home & {
    background-color: $secondary;
  }

  &.appendix-box--projects {
    position: relative;
    text-align: right;
    padding-top: 0;
    margin-top: 0;
    margin-left: 15vw;

    .section--full & {
      margin-top: -6rem;
      transform: translate(0, 42%);
    }
  }

  &.appendix-box--page-teaser {
    margin-top: 0;
  }

  @include breakpoint(medium) {
    position: absolute;
    max-width: 20rem;
    margin-top: 0;
    right: 0;
    top: 50%;
    transform: translate(0, 40%);
    padding: 9rem 3.5rem 1.5rem 1.5rem;

    &.appendix-box--page-teaser {
      position: relative;
      margin-top: auto;
      margin-left: auto;
      transform: translate(0);
    }

    &.appendix-box--projects {
      margin-top: 0;
      margin-left: auto;
      position: relative;
      top: auto;
      transform: translate(0, 0);

      .section--full & {
        transform: translate(0, 40%);
      }
    }

    .project-teaser--default & {
      position: relative;
      left: 0;
      top: 0;
    }
  }

  @include breakpoint(large) {
    transform: translate(0, 40%);
    padding: 9rem 3.5rem 2rem 1.5rem;

    &.appendix-box--projects {
      margin-top: 0;
    }

    .project-teaser--default & {
      transform: none;
      margin-left: 0;
    }
  }
}

.appendix-box--projects-numbers {
  display: block;
  transform: translateY(0.5rem);

  @include breakpoint(medium) {
    transform: translateY(1.5rem);
  }
  .page--home & {
    color: $gold;
  }
}

.topic__box {
  padding: 2rem;
  @extend %topic-theme-color-background;

  @include breakpoint(medium) {
    padding: 4rem;
  }
}

// topic chart image

.chart-image {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
    align-items: center;
  }
}

.chart-image__custom {
  width: 100%;
}

.chart-image--with-thumb {
  height: 8rem;
  display: block;

  img {
    display: block;
    object-fit: contain;
    height: 100%;
  }
}

.chart-image__thumb {
  margin-bottom: $general-block-margin;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }

  a {
    margin-right: $general-margin;
    background-color: $white;
    padding: 1rem;
    border-radius: 50%;
    min-width: 160px;
    height: 160px;

    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.35s;

    &.chart-image__thumb--no-circle {
      border: 0;
      background: none;

      &:hover {
        background: none;
      }
    }

    &:hover {
      @extend %topic-theme-color-background-cover;

      .chart-thumbnail-svg > path {
        fill: $white !important;
      }
    }
  }
}

// bigpicture text canvas close btn

.bp-xc {
  display: none !important;
}

.chart-thumbnail-svg > path {
  transition: fill 0.35s;
  @extend %topic-theme-color-fill;
}

// topic links

.topic-links__container {
  position: fixed;
  right: 0;
  top: 10rem;
  z-index: z-index("topic-links");
  cursor: pointer;
}

.page--topic .main-content:hover {
  .topic-link--icon-wrapper {
    &,
    .topic-link--image {
      transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s,
        transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
    }
  }
}

.topic-link {
  margin-bottom: 0.45rem;

  &:hover .topic-link--icon-wrapper,
  &.topic-link--active:hover .topic-link--icon-wrapper {
    &,
    .topic-link--image {
      opacity: 1;
      transform: translateX(0);
      transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s,
        transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
    }
  }
}

@keyframes initTopicIcons {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translate(40px);
  }
}

.topic-link--icon-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  height: rem-calc(44px);
  width: rem-calc(50px);
  background-color: $gold;

  position: relative;
  transform: translateX(40px);
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s,
    transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;

  @include breakpoint(medium) {
    display: flex;
  }

  .scrolled & {
    &,
    .topic-link--image {
      transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s,
        transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s !important;
    }
  }

  .topic-link--active & {
    transform: translateX(0);

    .topic-link--image {
      opacity: 1;
    }

    .scrolled & {
      transform: translateX(40px);
      transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;

      .topic-link--image {
        opacity: 0;
      }
    }
  }

  &--dark-blue {
    background-color: $dark-blue;
  }

  &--light-blue {
    background-color: $light-blue;
  }

  &--dark-red {
    background-color: $dark-red;
  }

  &--dark-pink {
    background-color: $dark-pink;
  }

  &--vivid-red {
    background-color: $vivid-red;
  }

  &--light-red {
    background-color: $light-red;
  }

  img {
    width: 100%;
    height: rem-calc(25px);
    transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s;
    opacity: 0;
  }
}

// Topic Breadcrumbs

.topic-breadcrumbs__container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transition: opacity 0.5s cubic-bezier(0.76, 0, 0.24, 1) 0s;
  opacity: 0;
  .scrolled--top & {
    opacity: 1;
  }
  //.scrolled {
  //  animation: 0.8s ease-out 0.2s 1 normal forwards running fadeIn;
  //}

  &:hover {
    opacity: 1 !important;
  }
}

.topic-breadcrumbs {
  display: flex;
}

.topic-breadcrumb {
  margin-right: rem-calc(11px);
}

.topic-breadcrumb--link {
  display: block;
  height: rem-calc(18px);
  width: rem-calc(11px);
  background-color: $white;

  &.active {
    @extend %topic-theme-color-background-cover;
  }
}
