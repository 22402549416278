// Textblock

.textblock {
  padding: $general-margin 0;

  @include breakpoint(medium) {
    padding: $general-margin * 2 0;
  }

  &.textblock--intro {
    padding: 0;
  }

  .home--main & {
    background-color: $deep-teal;
    color: $white;
  }
  .home--outro & {
    //background-color: $atlantis;
    color: $white;
    position: relative;
    z-index: 10;
  }
}

.textblock__intro-content {
  @include breakpoint(large) {
    max-width: 60%;
  }
}
