.footer__main {
  background-color: $secondary;
  padding: 3rem 0 2rem 0;
}

.footer__meta {
  background-color: $secondary;
  padding-bottom: 2rem;
}

.footer__second {
  background-color: $gold;
  padding: 0.25rem 0 0.4rem 0;
}

.footer__link-container {
  margin-bottom: 2.5rem;
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.footer__link {
  border-top: 1px solid $gold;

  &:last-of-type {
    border-bottom: 1px solid $gold;
  }

  a {
    padding: 0.5rem 0;
    display: block;
  }
}

.footer__link-container--contact {
  margin-top: 3rem;

  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.footer--logos {
  display: flex;
  align-items: center;
  height: 100%;
}

.footer--logo {
  display: block;
  margin-right: 2rem;
  img {
    width: 20rem;
    display: block;
  }

  &.footer--logo-small {
    img {
      width: 10rem;
    }
  }
}
