.section--projects {
  margin-top: 8rem;

  @include breakpoint(medium) {
    margin-top: 15rem;
  }
}

.project--english-hint {
  display: block;
  margin-top: -3rem;
  margin-bottom: 3rem;
}

.project-list__controls {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    justify-content: space-between;
    flex-direction: row;
  }
}

.project-list__filter--toggle {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  order: 2;
  margin-bottom: $general-block-margin;

  @include breakpoint(medium) {
    order: 1;
    margin-bottom: 0;
  }
}

.project-list__search--toggle {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  order: 2;
  margin-bottom: $general-block-margin;

  @include breakpoint(medium) {
    justify-content: flex-end;
  }
}

.navigation--trigger-search {
  .js-foldout-body {
    padding-top: 0;
  }

  svg {
    width: 12px;
    margin-left: 1rem;
  }
}

.project-list__filter--trigger {
  display: flex;
  align-items: center;

  svg {
    width: 8px;
    transform: rotate(-90deg);
    margin-left: 1rem;
    transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
    transform-origin: 50% 50%;
    will-change: transform;

    .toggled & {
      transform: rotate(90deg);
    }
  }
}

.project-list__filter--content {
  @include breakpoint(small only) {
    .js-foldout-body {
      padding-top: 0;
    }
  }

  &:hover,
  &.project-list__filter--item {
    color: rgba(4, 12, 38, 0.4);
  }
}

.project-list__filter--list {
  @include breakpoint(medium) {
    max-width: 70%;
  }

  li {
    display: inline-block;
    margin-bottom: $general-block-margin;
    margin-right: $general-block-margin;

    &:hover a {
      color: $black !important;
    }

    & a.active {
      color: $gold !important;
    }
  }
}

.project-list__filter--left {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.project-filter__result-container {
  display: flex;

  order: 1;

  @include breakpoint(medium) {
    margin-left: 3rem;
    order: 2;
  }
}

.project-filter__current-category {
  display: flex;
  justify-content: center;
  border: 1px solid #d7d7d7;
  border-radius: 16px;
  padding: 8px 16px 6px 18px;
  line-height: 1;
  margin-left: 1rem;

  @include breakpoint(small only) {
    margin-left: 0;
    width: fit-content;
    margin-bottom: $general-block-margin;
  }

  &::selection {
    background: none;
  }

  svg {
    margin-left: 0.75rem;
    width: 16px;
    height: 16px;
  }
}

.project-list--search-form {
  .field-container {
    border: 0;
    //margin: 0.5em 0;
    padding: 0.5em 0.5em 0.5em 1em;
    input {
      background: transparent;
      border: 0;
      color: inherit;
      font: inherit;
      outline: 0;
      background: $gold-alpha;

      margin: 0.5em 0;
      padding: 0.5em 0.5em 0.5em 1em;
      width: 100%;
      @include breakpoint(medium) {
        width: 300px;
      }
    }

    #search:hover,
    #search:focus {
      background: $gold-alpha;
    }
  }
}

.js-foldout-body--search {
  padding-top: 0;
}

// Pagination

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;

  background: $gold;
  width: 300px;
  margin: 0 auto;
  padding: 1rem 1rem;
}

.pagination__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-arrow {
  &.pagination-arrow--right {
    svg {
      transform: rotate(-180deg) translateY(-4px);
    }
  }
  &.pagination-arrow--left {
    svg {
      transform: translateY(4px);
    }
  }
}
