.letter__wrapper {
  height: 100%;
  flex: 1;
  position: relative;

  @include breakpoint(medium) {
    top: -10rem;
  }
}

.letter__viewer {
  padding: 2rem 4rem 3rem 2rem;
  position: relative;
  filter: drop-shadow(0px 8px 6px #00000029);

  background: $white;

  @include breakpoint(large) {
    padding: 5rem 4rem 3rem 6rem;
  }

  &:after {
    content: "";
    display: block;
    height: 9rem;
    width: 100%;
    position: absolute;
    background-color: $white;
    bottom: -8.99rem;
    z-index: 10;
    left: 0;
    clip-path: polygon(50% 20%, 0 0, 100% 0);
    border-top: 15px solid $white;
    //box-shadow: 0px 8px 6px #00000029;
  }
}

.letter__footer {
  padding: 2rem 4rem 3rem 2rem;
  margin-top: 3rem;
  position: relative;
  filter: drop-shadow(0px 8px 6px #00000029);

  background: #f2f2f2;

  @include breakpoint(large) {
    padding: 2rem 4rem 3rem 6rem;
  }

  &:after {
    content: "";
    display: block;
    height: 9rem;
    width: 100%;
    position: absolute;
    background-color: #f2f2f2;
    top: -8.99rem;
    z-index: 10;
    left: 0;
    clip-path: polygon(100% 100%, 0 100%, 0 80%, 50% 100%, 100% 80%);
    border-top: 15px solid $white;
    //box-shadow: 0px 8px 6px #00000029;
  }
}

.letter__element--thur,
.letter__element--concept {
  transition: $transition;
  animation-duration: 0.8s;
  animation-name: slidein;
}

.letter__element--thur {
  &:not(:first-child) {
    &:before {
      content: ", ";
    }
  }

  &.letter__element--thur-1,
  &.letter__element--thur-2 {
    &:not(:first-child) {
      &:before {
        content: " und ";
      }
    }

    &:not(:last-child) {
      &:before {
        content: ", ";
      }
    }
  }

  &.letter__element--thur-0,
  &.letter__element--thur-1 {
    &:last-child {
      &:after {
        content: ".";
      }
    }
  }

  &.letter__element--thur-thurgauer {
    &:last-child {
      &:after {
        content: " ";
      }
    }
  }
}

.letter__element--concept {
  &:not(:first-child) {
    &:before {
      content: " ";
    }
  }

  //&.letter__element--concept-1,
  //&.letter__element--concept-2 {
  //  &:before {
  //    content: ", weil ";
  //  }
  //
  //  &:not(:first-child) {
  //    &:before {
  //      content: " und weil ";
  //    }
  //  }
  //
  //  &:not(:last-child) {
  //    &:before {
  //      content: ", weil ";
  //    }
  //  }
  //}
}

@keyframes slidein {
  from {
    color: $atlantis;
  }
  to {
    color: $black;
  }
}

.letter__list {
  padding: 0;
  margin-bottom: $general-margin;

  li {
    list-style: none;
    margin-bottom: $general-block-margin * 0.5;

    label {
      display: flex;
      cursor: pointer;

      input {
        margin-right: 0.75rem;
        margin-top: 0.25rem;
      }
    }
  }
}

.letter__element--info {
  &:after {
    content: " ";
  }

  &.letter__element--id_email {
    display: none;
  }

  &.letter__element--info-1,
  &.letter__element--info-2 {
    &:after {
      content: "\a";
      white-space: pre;
    }
  }
}

.sharing-image {
  position: relative;

  margin-top: $general-margin;

  @include breakpoint(medium) {
    margin-top: 0;
    top: -8rem;
  }

  @include breakpoint(large) {
    top: -10rem;
  }
}

.at-share-btn-elements {
  a {
    margin-right: 0.75rem !important;
  }
}

.social-btns {
  display: flex;
  a {
    background: $viridian;
    border-radius: 50%;
    margin-right: 1rem;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.25rem;
    div {
      margin-right: 0;
    }
  }
}
