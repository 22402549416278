@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=9e19379b-13fa-42b8-8618-e21be2aab3b4");
@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url("Fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"),
    url("Fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_65 Medium";
  src: url("Fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"),
    url("Fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_75 Bold";
  src: url("Fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"),
    url("Fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff");
}
@font-face {
  font-family: "Helvetica Neue LT W05_77 Bd Cn";
  src: url("Fonts/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"),
    url("Fonts/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff");
}

$font-main: "Helvetica Neue LT W05_55 Roman", sans-serif;
$font-condensed: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
$font-bold: "Helvetica Neue LT W05_75 Bold", sans-serif;
$font-medium: "Helvetica Neue LT W05_65 Medium", sans-serif;

$body-font-size-tiny: rem-calc(14);
$body-font-size-small: rem-calc(15);
$body-font-medium: rem-calc(20);
$body-font-size: rem-calc(18);
$body-font-size-large: rem-calc(25);
$body-line-height: 1.6;
$headings-line-height: 1.2;

$h1-font-size: 5.5vw;
$h1-font-size-xlarge: rem-calc(90px);
$h2-font-size: rem-calc(40px);
$h3-font-size: rem-calc(32px);
$h4-font-size: rem-calc(28px);
$h5-font-size: rem-calc(18px);

$regular: 400;
$semi-bold: 500;
$bold: 700;

$font-weight-main: 500;

$general-block-margin: 1.5rem;
$general-margin: rem-calc(40px);
$general-padding: 2rem;
$general-border-style: dashed;

$letter-spacing: 1px;

%body-font {
  font-family: $font-main;
  font-weight: $font-weight-main;
  font-style: normal;
}

%h1-font-size {
  @include resprop(
    "font-size",
    (
      $h1-font-size * 2,
      $h1-font-size * 1.2,
      $h1-font-size,
      $h1-font-size-xlarge
    )
  );
}

%h2-font-size {
  @include resprop(
    "font-size",
    ($h2-font-size * 0.7, $h2-font-size * 0.8, $h2-font-size)
  );
}

%h3-font-size {
  @include resprop("font-size", ($h3-font-size * 0.8, $h3-font-size));
}

%h4-font-size {
  @include resprop(
    "font-size",
    ($h4-font-size * 0.7, $h4-font-size * 0.8, $h4-font-size)
  );
}

%h5-font-size {
  @include resprop("font-size", ($h5-font-size * 0.9, $h5-font-size));
}

%menu-font-styles {
  font-family: $font-condensed;
  font-weight: $bold;
  font-size: $body-font-medium;
  text-transform: uppercase;
}

%small-title-font-styles {
  font-family: $font-condensed;
  font-weight: $bold;
  font-size: $body-font-medium;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: $general-block-margin;
  font-family: $font-bold;
  line-height: $headings-line-height;
}

h1,
.h1 {
  font-family: $font-condensed;
  @extend %h1-font-size;

  &.heading--smaller {
    @include resprop(
      "font-size",
      (
        $h1-font-size * 2,
        $h1-font-size * 1.2,
        $h1-font-size * 0.8,
        $h1-font-size * 0.9
      )
    );
  }
}

h2,
.h2 {
  font-family: $font-condensed;
  @extend %h2-font-size;
  line-height: $headings-line-height;

  a {
    color: $black !important;
  }
}

h3 {
  @extend %h3-font-size;

  .plugin__richtext & {
    @include resprop("font-size", ($h3-font-size * 0.7));
    margin-bottom: $general-block-margin;
  }

  .page--home &,
  .overlay & {
    @include resprop(
      "font-size",
      ($h3-font-size * 0.7, $h3-font-size * 0.8, $h3-font-size)
    );
    //margin-bottom: $general-block-margin;
  }

  .factslide__content & {
    @include resprop("font-size", ($h3-font-size * 0.8));
    margin-bottom: $general-block-margin;
  }
}

.home--title {
  margin-top: $general-margin;

  @include breakpoint(xLarge) {
    margin-bottom: $general-margin * 1.5;
  }
}

h4 {
  @extend %h4-font-size;
  margin-bottom: $general-block-margin * 0.6;
}

h5 {
  @extend %h5-font-size;
  margin-bottom: $general-block-margin * 0.6;
  margin-top: 0;
}

// to be extended where it is
%indent-list-items {
  margin-left: 1em;
}

body {
  font-family: $font-main;
  font-size: $body-font-size-small;
  line-height: $body-line-height;
  color: $black;

  @include breakpoint(large up) {
    font-size: $body-font-size;
  }
}

p,
form,
table {
  margin-bottom: $general-block-margin;
}

ul,
ol {
  margin-bottom: $general-block-margin;
  padding-left: 1rem;
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: $black;
}

strong {
  font-family: $font-bold;
}

address {
  font-style: normal;
}

.plugin__richtext {
  sub {
    font-size: 60%;
  }

  ul,
  ol {
    margin-left: 1.5em;

    li {
      padding-left: 0.2em;
    }
  }

  ul,
  ol {
    li:not(:last-child) {
      margin-bottom: 0.5em;
    }
  }

  a:not(.link-item) {
    @extend %topic-theme-font-color;
  }

  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
    margin-top: $general-block-margin;
    margin-bottom: $general-block-margin;

    @include breakpoint(medium) {
      margin-top: $general-block-margin * 2;
    }

    &[style="text-align:center"] {
      max-width: 80%;
      margin: $general-block-margin * 2 auto;
      line-height: 1.4;

      @include breakpoint(medium) {
        margin: $general-block-margin * 2.5 auto;
      }
    }
  }

  //.page--home & {
  //  @include breakpoint(medium) {
  //    padding: 0 3rem;
  //  }
  //}
}

.list {
  margin: 0;
  padding: 0;

  &.list--anchors {
    margin-top: $general-margin;
  }

  li {
    margin: 0;
    padding: 0;
    list-style: none;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}

//.plugin--padding {
//  .page--home & {
//    @include breakpoint(medium) {
//      padding: 0 3rem;
//    }
//  }
//}

.text--white {
  color: $white;
}

.text--gray {
  color: $secondary-gray;

  @include breakpoint(small only) {
    .home-teaser__box & {
      color: darken($secondary-gray, 30%);
    }
  }
}

.text--black {
  color: $black;
}

.text--margin {
  display: block;
  margin-bottom: $general-block-margin;
}
.text--no-margin {
  margin-bottom: 0;
}

.text--oversized {
  font-size: 80px;
}

.text--large {
  line-height: normal;
  font-size: $body-font-medium;

  @include breakpoint(medium) {
    font-size: $body-font-size-large;
  }
}
.text--medium {
  font-size: $body-font-medium;
}

.text--small {
  font-size: $body-font-size-small;
}

.text--tiny {
  font-size: $body-font-size-tiny;
}

.text--ultra-large {
  @include resprop(
    "font-size",
    (rem-calc(150px) * 0.75, rem-calc(150px) * 0.8, rem-calc(150px))
  );
  line-height: 0;
}

.font--bold {
  font-family: $font-bold;
}

.font--semibold {
  font-family: $font-medium;
}

.text--yellow {
  color: $manz;
}

.text--gold {
  color: $gold;
}

.text--yellow-darken {
  color: $wattle;
}

.text--uppercase {
  text-transform: uppercase;
  letter-spacing: $letter-spacing;
}

.font--condensed {
  font-family: $font-condensed;
  line-height: normal;
}

.font--theme-color {
  @extend %topic-theme-font-color;
}

.font--margin {
  margin-bottom: $general-block-margin;
  display: block;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.back-link {
  a img {
    transform: rotate(180deg);
  }

  margin-bottom: $general-block-margin;
}

.text--marked {
  background-repeat: no-repeat;
  text-shadow: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 0.1rem 0.5rem;
  line-height: 1.6;
  background: $manz;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 60%,
    $manz 60%,
    $manz 100%
  );
  font-family: $font-condensed;
}

.anchor-item {
  margin-top: $general-margin;
  display: flex;
  align-items: center;
  margin-bottom: $general-block-margin;
}

.anchor-item__rect {
  position: relative;
  height: $rect-height;
  margin-left: 1rem;
  width: 120px;

  @extend %topic-theme-color-background-cover;
}
