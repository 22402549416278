%transition-ease {
  transition: 0.2s ease;
}

// Sticky Footer
// =============

body {
  display: flex;
  flex-direction: column;
  // I would rather use min-height, but IE11 crashes the party
  // https://github.com/philipwalton/flexbugs#3-min-height-on-a-flex-container-wont-apply-to-its-flex-items
  min-height: 100vh;
  &.nav-is-open {
    //position: fixed;
  }
  -webkit-overflow-scrolling: touch;
  background-color: #f8f8f8;
}

.main-content {
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;

  //background-color: $light-gray;
  //@include resprop("padding-top", (rem-calc(76px), rem-calc(78px), rem-calc(172px)));

  //padding-top: $general-margin;

  &.main--invert {
    background-color: $white;
  }

  .page--home & {
    @include breakpoint(medium) {
      padding-top: 10vh;
    }
    background-color: $white;
    margin-bottom: 0;
  }
  .page--topic & {
    margin-bottom: $general-margin * 2;
  }
}

// General elements / behavior styling
// ===================================

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
}

.section {
  position: relative;
  //@include sectionPadding(
  //  "padding-top",
  //  ($general-padding * 0.5, $general-padding * 0.8, $general-padding)
  //);
  //@include sectionPadding(
  //  "padding-bottom",
  //  ($general-padding * 0.5, $general-padding * 0.8, $general-padding)
  //);

  padding: 2rem 0;

  @include breakpoint(medium) {
    padding: 4rem 0;
  }

  &.section--no-paddings {
    padding-bottom: 0;
  }

  &.slider--topics {
    padding-top: 0;
  }
}

.subpage-entry--section {
  margin-top: 10rem;

  @include breakpoint(medium) {
    margin-top: 15rem;
  }
}

.align-center--small {
  @include breakpoint(small only) {
    justify-content: center;
  }
}
.align-center--medium {
  @include breakpoint(medium down) {
    justify-content: center;
  }
}

.grid-container--margin {
  @include resprop("margin-bottom", ($general-margin, $general-margin * 1.5));
}

.full.grid-container--margin {
  @include resprop("margin-bottom", ($general-margin, $general-margin * 1.5));
}

.grid-container--margin-half {
  @include resprop("margin-bottom", ($general-margin / 3, $general-margin / 2));
}

.breadcrumb__link {
  margin-top: $general-block-margin;
  display: flex;
  align-items: center;
  line-height: 0;
  @extend %topic-theme-font-color;
  .breadcrumb__link--arrow {
    margin-right: 1rem;

    svg {
      width: 8px;

      path {
        @extend %topic-theme-color-stroke;
      }
    }
  }
}

.section--full {
  @include resprop("padding-top", ($general-margin, $general-margin * 1.5));
  padding-bottom: 1px;
  @extend %topic-theme-color-background;

  &.section--full-cover {
    @extend %topic-theme-color-background-cover;
  }

  &.section--full-teaser {
    padding: $general-margin * 2 0;

    @include breakpoint(medium) {
      padding: 0;
    }
  }
}

.show-for-medium-down {
  display: none;
  @include breakpoint(medium down) {
    display: block;
  }
}

.flex-ordering {
  @include breakpoint(small only) {
    .flex-order--1 {
      order: 1;
    }

    .flex-order--2 {
      order: 2;
    }
  }
}

.cell--right {
  @include breakpoint(medium) {
    margin-left: auto;
  }
}

.cell--left {
  @include breakpoint(medium) {
    margin-right: auto;
  }
}

.margin-top {
  margin-top: $general-margin;
}

// 404
.page--notfound {
  position: relative;
  width: 100%;
  height: 100vh;
}
.page--background-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  &:after {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(19, 50, 45, 0.5);
    mix-blend-mode: overlay;
    z-index: 0;
  }
}

.notfound-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  z-index: 100;

  h1 {
    line-height: 0.9;
  }
  span {
    display: block;
    //transform: translate(10px, 0);
    //
    //@include breakpoint(medium) {
    //  transform: translate(20px, 0);
    //}
    font-weight: $semi-bold;
  }
}

.grid-vh100 {
  @include resprop("min-height", (100%, 100vh, 100vh));
  @include breakpoint(medium) {
    padding-top: 10rem;
  }
}

.grid-vh80 {
  @include resprop("min-height", (100%, 80vh, 80vh));
}

.grid-vh90 {
  @include resprop("min-height", (100%, 90vh, 90vh));
}

.z10 {
  z-index: 10;
  position: relative;
}

.no-pointer-event {
  pointer-events: none;
  user-select: none;
}

.align-middle {
  // IE Fix
  @include breakpoint(medium) {
    height: 1px;
  }
}

.debug-viewport {
  position: absolute;
  top: 0;
  right: 20rem;
  width: 10rem;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
}
