.poi-text {
  h3 {
    @include resprop("font-size", ($h3-font-size * 0.6));
    margin-bottom: 0.5rem;
  }

  span.text--bold {
    font-size: $body-font-size-tiny;
    line-height: 1.4;
    display: block;
    margin-bottom: $general-block-margin / 2;

    @include breakpoint(medium) {
      max-width: 80%;
    }
  }

  p {
    font-size: 0.7em;
  }

  .button__block {
    margin-bottom: 0;
    a {
      font-size: $body-font-size-tiny;

      .download__icon {
        margin-left: 0.5rem;
      }

      svg {
        height: 1rem;
      }
    }
  }
}

.poi-image {
  @include breakpoint(medium) {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    object-position: bottom;

    &.poi-image--center {
      object-position: center 40%;
    }
  }
}

.km-button-label {
  font-size: 12px;
  line-height: normal;
  @include breakpoint(medium) {
    margin-top: -30px !important;
  }
}

.km-button {
  @include breakpoint(medium) {
    width: 8.5px !important;
    height: 8.5px !important;
  }
}

.vertical-line {
  @include breakpoint(medium) {
    height: 583px !important;
  }
}

.poi-card__image-caption.text--small {
  font-size: 0.65rem;
  padding: 0 10px 10px 30px;
  display: block;
  margin-bottom: -1rem;
}

.text--city {
  opacity: 0.6;
}
