.home-entry__teaser-text {
  @include breakpoint(medium) {
    margin-top: 2rem;
    margin-left: 6rem;
  }
  @include breakpoint(xLarge) {
    margin-top: 3rem;
  }
}

.home-entry--section {
  position: relative;
  margin-top: 10rem;
  @include breakpoint(medium) {
    margin-top: 0;
  }
}

.home-teaser__box {
  color: $black;
  padding: 4vh 0 0 0;

  .page--en & {
    padding: 4vh 0 10vw 0;
  }

  @include breakpoint(medium) {
    padding: 8vh 3vw 0 3vw;
    background-color: $white;
    transform: translateY(4rem);

    .page--en & {
      padding: 8vh 3vw 10vw 3vw;
    }
  }
}

.page-teaser__button-wrapper {
  margin-top: 2rem;

  @include breakpoint(medium) {
    margin-top: 50vh;
  }

  a {
    display: block;
    margin-left: auto;
    width: fit-content;
  }
}

.page-scroll__icon-wrapper {
  position: absolute;
  right: 15%;
  bottom: 5vh;
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s,
    transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
  transform: translateY(0);
  opacity: 1;

  @include breakpoint(medium down) {
    display: none;
  }

  .page-scroll__icon {
    height: 5rem;
    width: 5rem;
    display: block;
  }

  .scrolled & {
    transform: translateY(-50px);
    opacity: 0;
  }
}
