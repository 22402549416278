.next-page-teaser {
  @extend %topic-theme-color-background;
  padding: 3rem 0;
  margin-top: $general-margin * 6;
  position: relative;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scaleX(0);
    transition-delay: 0s;
    animation: none;
    background-color: $gold;
    transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
    transform-origin: left;
  }

  &:hover {
    &:after {
      transform: scaleX(1);
    }

    &,
    .next-page-teaser__headline span {
      color: $white;
    }
  }
}

.next-page-teaser__headline {
  position: relative;
  z-index: 100;

  span {
    transition: color 0.4s cubic-bezier(0, 0, 0.175, 1);
  }
}

.next-page-teaser__result-wrapper {
  position: relative;
  margin-left: auto;
  max-width: 70%;
  padding: 3rem 0 2rem 0;
  z-index: 100;
  transition: color 0.4s cubic-bezier(0, 0, 0.175, 1);
}
