.image-comparison {
  background-color: $deep-teal;
  padding-bottom: $general-margin;

  @include breakpoint(medium) {
    padding-bottom: $general-margin * 2;
  }
}

.image-comparison__container {
  position: relative;
  z-index: 100;
}

img-comparison-slider {
  margin: 0 auto;
  --hint-size: 300px;
  --hint-opacity: 1;
  --divider: 5px solid #e5eb50;

  &:focus {
    outline: none;
  }
}
.custom-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-38%, -50%);
}
