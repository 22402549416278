.plugin--image {
  margin-bottom: $general-block-margin;

  img {
    width: 100%;
  }
}

#bp_container img {
  background-color: #fff !important;

  @include breakpoint(medium) {
    max-height: 80% !important;
    max-width: 80% !important;
    padding: 4rem;
  }
}
