.project-teaser {
  display: block;
  border-top-width: 2px;
  border-top-style: solid;
  @extend %topic-theme-color-border;
  padding-top: 2rem;
  margin-bottom: $general-margin;

  &:hover .project-teaser__title {
    @extend %topic-theme-font-color;
  }

  @include breakpoint(small only) {
    .home-teaser__box & {
      border-color: $black;
    }

    &:hover .project-teaser__title {
      .home-teaser__box & {
        color: $black;
      }
    }
  }
}

.project-teaser__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-teaser__title {
  margin: 1rem 0 3rem 0;

  @include breakpoint(medium) {
    max-width: 80%;
  }

  h3 {
    @include resprop("font-size", ($h3-font-size * 0.7));
    margin-bottom: $general-block-margin;
  }
}
