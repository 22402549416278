$hover--opacity: 0.6;
$fade-out: cubic-bezier(0.41, 0.29, 0.18, 0.97);
body {
  &[data-overlay="true"] {
    overflow: hidden;

    @include breakpoint(medium) {
      padding-right: 15px;
    }
  }
}

.overlay {
  position: fixed;
  //display: none;

  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;

  &:before {
    content: "";
    background: #eeeeeeb3;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: opacity 600ms $fade-out;

    [data-overlay="true"] &,
    &.active {
      opacity: 1;
    }
  }

  [data-overlay="true"] & {
    pointer-events: all;
  }
}

.overlay__content {
  position: fixed;
  background-color: $white;
  width: 100%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  height: 100vh;

  @include breakpoint(small only) {
    overflow: scroll;
    height: 100%;
  }

  @include breakpoint(medium) {
    width: 100%;
  }

  @include breakpoint(large) {
    width: 80vw;
  }

  [data-overlay="true"] & {
    z-index: 1000;
    transition: transform 0.8s cubic-bezier(0, 0, 0.175, 1);
  }

  [data-overlay="false"] & {
    z-index: 1000;
    transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
  }

  [data-overlay-level="first"] & {
    transform: translateX(0);
  }
  [data-overlay-level="second"] & {
    transform: translateX(0);

    @include breakpoint(medium) {
      transform: translateX(20%);
    }

    @include breakpoint(large) {
      transform: translateX(40%);
    }
  }
}

.help--button {
  position: fixed;
  top: 50%;
  z-index: 1000;
  right: 50px;
  transform-origin: right;
  transform: rotate(-90deg) translate(50%, 0%);

  a {
    display: block;
  }

  [data-overlay="true"] & {
    right: 65px;
  }
}

.overlay__content--container {
  display: flex;

  height: 100%;

  @include breakpoint(small only) {
    flex-direction: column;
    justify-content: space-between;
  }

  @include breakpoint(medium) {
    //padding: 140px 90px 0px 50px;
  }

  @include breakpoint(large) {
    //padding: 140px 180px 0px 100px;
  }
}

.overlay__content--left-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  padding: 100px 20px 0px 20px;

  @include breakpoint(medium) {
    height: 100%;
    padding: 80px 0 0 120px;
  }

  @include breakpoint(large) {
    width: 50rem;
  }

  @include breakpoint(xLarge) {
    padding: 140px 0 0 120px;
  }

  &[data-overlay-content-level="first"] {
    display: none;

    [data-overlay-level="first"] & {
      display: flex;
    }
  }

  &[data-overlay-content-level="second"] {
    display: none;

    [data-overlay-level="second"] & {
      display: flex;

      @include breakpoint(medium) {
        width: 30rem;
      }
      @include breakpoint(large) {
      }
    }
  }
}

.overlay__menu {
}

.overlay__content--right-wrapper {
  display: none;
  //margin-top: $general-margin * 1.6;
  position: relative;
  width: 35rem;
  height: 100%;
  background-color: $gold-alpha;
  margin-left: auto;

  @include breakpoint(medium) {
    display: block;
    transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
    transform: translateX(100%);
    transition-delay: 0s;
    animation: none;
    transform-origin: right;

    .active--token & {
      transform: translateX(0);
    }
  }

  @include breakpoint(large) {
    //padding: 140px 50px 0 0;
  }
}

.overlay--navigation {
  display: flex;
  flex-direction: column;
  transition: height 0.55s cubic-bezier(0, 0, 0.175, 1);

  margin: 0;
  padding: 0;
  width: 100%;
  @include breakpoint(medium) {
    //width: auto;
  }
}

.overlay__menu--container {
  display: flex;
  width: 100%;
  justify-content: space-between;
  //&.active--token {
  //  .navigation--item {
  //    opacity: $hover--opacity;
  //  }
  //}

  &:hover {
    .navigation--item {
      opacity: $hover--opacity;
    }
  }
}

.overlay--menu-breadcrumb {
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
  .active--token & {
    transform: translateX(-2.75rem);
  }
}

.navigation--item {
  list-style: none;
  position: relative;
  transition: all 0.55s cubic-bezier(0, 0, 0.175, 1);

  @include breakpoint(small only) {
    padding-bottom: $general-block-margin * 0.5;
  }

  @include breakpoint(medium) {
    margin-bottom: $general-block-margin;
  }

  &.active,
  &:hover {
    opacity: 1 !important;
  }

  &[data-overlay-accordion="true"] {
    //border-bottom: 1px solid $black;
    //padding-bottom: $general-block-margin;
    transition: all 300ms ease;
  }

  @include breakpoint(medium) {
    display: flex;
    align-content: center;
    align-items: center;
    transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
    position: relative;
    .active--token & {
      transform: translateX(-2.75rem);
    }

    &:before {
      content: "";
      width: 2rem;
      height: 6px;
      background-color: $gold;
      left: -2.75rem;
      position: absolute;
      transition: transform 0.8s cubic-bezier(0.76, 0, 0.24, 1),
        opacity 0.8s cubic-bezier(0.76, 0, 0.24, 1);
      opacity: 0;
      transform: scaleX(0.5);
      transform-origin: left;
    }

    &.active {
      transition: color 0.3s ease-out,
        transform 0.8s cubic-bezier(0.76, 0, 0.24, 1);
      padding-bottom: 0;
      transform: translateX(0);

      &:before {
        opacity: 1;
        transform: scaleX(1);
      }
    }
  }
}

.navigation--item-link {
  display: block;
  padding: 0.25rem 0;
  cursor: pointer;
  margin: 0;

  [data-overlay-accordion="true"] & {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: space-between;
    margin: 0;
  }

  .overlay--navigation--sitebar & {
    //text-align: right;
  }
}

.navigation--trigger-icon--circle {
  position: relative;
  &:after {
    position: absolute;
    min-width: 3rem;
    height: 3rem;
    content: "";
    border-width: 2px;
    border-style: solid;
    @extend %topic-theme-color-border;
    border-radius: 50%;
    transform: translate(-25%, -48%);
    transform-origin: 0 0;
  }

  &.trigger-icon--circle--black:after {
    border-color: $black;
  }
}

.navigation--children {
  position: absolute;
  //opacity: 0;
  counter-reset: list;
  margin-left: 0;
  width: 100%;
  pointer-events: none;
  margin-bottom: 0;
  opacity: 0;
  //transform: translateX(-30px);

  @include breakpoint(medium) {
    transform: translate(30px);
    top: 100px;

    transition: opacity 0.35s;
    &.active {
      transition: opacity 0.35s 0.2s;
      opacity: 1;
    }
  }

  .overlay--navigation--sitebar & {
    //text-align: right;
  }

  &:hover {
    .navigation--child-item {
      color: lighten($black, 50%);
    }
  }

  [data-overlay-accordion="true"] & {
    position: relative;

    visibility: hidden;
    overflow: hidden;

    max-height: 0;
    //opacity: 0;
    transform: translate(0, 50%);
  }

  &.active {
    //opacity: 1;
    pointer-events: all;
    //transition: max-height 0.35s, margin 0.35s, transform 0.35s 0.25s,
    //  opacity 0.35s 0.25s;
    margin-bottom: $general-block-margin;

    [data-overlay-accordion="true"] & {
      max-height: 800px;
      transform: translate(0, 0);
      opacity: 1;
      visibility: visible;
      margin-top: $general-block-margin;
      //margin-bottom: $general-block-margin;

      [data-overlay-level="second"] & {
        margin-bottom: $general-block-margin;
      }

      .navigation--child-item:last-child {
        border-bottom: none;
      }
    }
  }

  @include breakpoint(small only) {
    height: 0;
    overflow: hidden;

    transition: height 1.2s cubic-bezier(0.19, 1, 0.22, 1),
      margin 1.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.35s;

    &.active {
      opacity: 1;
    }
  }
}

.navigation--child-item {
  display: flex;
  border-bottom: 1px solid #fff;
  line-height: normal;
  margin-bottom: 0 !important;
  list-style: none;
  opacity: 0;
  transform: translate(30px);
  //animation-name: fadeOutRight;
  //transition-delay: var(--var-delay-out);
  transition: transform 0.35s var(--var-delay-out), opacity 0.1s,
    color 0.1s ease-out;
  //transition: transform 0.35s var(--var-delay-out), opacity 0.35s var(--var-delay-out);

  .active & {
    transform: translate(0);
    opacity: 1;

    transition: transform 0.35s var(--var-delay-out),
      opacity 0.35s var(--var-delay-out), color 0.1s ease-out;
    //animation-name: fadeInLeft;
    @include breakpoint(small only) {
      transform: translate(0);
    }
  }

  @include breakpoint(medium) {
    border-bottom: 0;
    //margin-left: 3rem;
  }

  &:hover {
    color: $black !important;
  }

  @include breakpoint(small only) {
    display: flex;
    align-items: center;
    margin-left: 0;
    transform: translateX(30px);
    opacity: 0;
    &:before {
      content: "";
      height: 2px;
      min-width: 1rem;
      background-color: $gold;
      display: block;
      margin-right: 1rem;
      transition: width 0.55s cubic-bezier(0, 0, 0.175, 1) 0.15s,
        transform 0.25s;
      transform-origin: center center;
      transform: scaleY(1) translateY(0px);
    }
  }
}

.navigation--child-item-link {
  padding: 0.8rem 0 0.9rem;
}

.help--button {
  display: none;
  @include breakpoint(medium) {
    display: block;
  }
}

.navigation--trigger-icon {
  position: relative;
  display: none;
  align-items: center;
  background-color: #0000;
  transition: background-color 0.3s, border 0.3s;
  text-decoration: none;
  margin: 0;

  &.trigger-icon--theme {
    @extend %topic-theme-color-background-cover;
  }

  [data-overlay-accordion="true"] &,
  .accordion--headline-wrapper &,
  .dropdown--toggle & {
    display: flex;
    height: 100%;
    width: 2rem;
  }

  &:after,
  &:before {
    content: "";
    background-color: $white;
    height: 3px;
    width: 25px;
    position: absolute;
    top: 0;
    left: 0;
    transition: transform 0.5s ease;
  }

  &.trigger-icon--black {
    &:after,
    &:before {
      background-color: $black;
    }
  }

  &.trigger-icon--theme {
    &:after,
    &:before {
      @extend %topic-theme-color-background-cover;
    }
  }

  &.icon--small {
    width: 1rem;

    &:after,
    &:before {
      width: 16px;
      height: 0.12rem;
    }
  }

  &:before {
    transform: rotate(180deg);
  }
  &:after {
    transform: rotate(90deg);
  }

  .active &,
  .toggled &,
  .toggle--collapsed & {
    //&:after,
    //&:before {
    //  background-color: $white;
    //}

    &:before {
      transform: rotate(0deg);
    }
    &:after {
      transform: rotate(0deg);
    }
  }
}

// Language

.overlay--language {
  display: flex;
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
  .active--token & {
    transform: translateX(-2.75rem);
  }
  li {
    padding: 0;
    margin-left: 0;
    margin-right: 0.25rem;

    &:last-of-type {
      margin-left: 0.25rem;
    }
  }
}
