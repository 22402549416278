// testimonials

.testimonial-container {
  //background: $white;
  //box-shadow: $box-shadow;
}

.testimonial-wrapper {
  //  max-width: $global-width;
  //  margin-bottom: $content-element-margin-bottom;
  margin: 1rem 0 3rem 0;
}

.testimonial-slide {
  position: relative;
}

.testimonial__slides {
  overflow-x: hidden;
}

.testimonial__slider {
  display: flex;
}

.testimonial__slide {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  transition: opacity 0.8s cubic-bezier(0.76, 0, 0.24, 1) 0.35s;

  @include breakpoint(medium) {
    flex-direction: row;
    justify-content: unset;
  }
}

.testimonial__image-container {
  width: 100%;
  margin-bottom: $general-block-margin;

  @include breakpoint(medium) {
    flex: 1 1 30%;
    margin-right: 3rem;
    margin-top: auto;
    margin-bottom: auto;
  }

  @include breakpoint(large) {
    flex: 1 1 30%;
    margin-top: 0;
    margin-bottom: 0;
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
  }
}

.testimonial__content-wrapper {
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    justify-content: space-around;
    flex: 1 1 70%;
  }

  @include breakpoint(large) {
    //flex: 1 1 80%;
  }
}

.testimonial__description {
}

.testimonial__signature {
}

.testimonial__go {
  position: absolute;
  display: flex;
  //background: url(img/icons/testimonial-arrow-right.svg) no-repeat right center;
  height: 2.5rem;
  width: 2.5rem;
  background-size: cover;
  right: 0;
  bottom: 0;
  cursor: pointer;

  &[data-go="-1"] {
    position: absolute;
    transform: rotate(-180deg);
    right: 3rem;

    @include breakpoint(small only) {
      transform: rotate(-180deg) translateY(-20px);
    }
  }

  &[data-go="1"] {
    position: absolute;

    @include breakpoint(small only) {
      transform: translateY(20px);
    }
  }
}
