// Download

.downloads {
  display: flex;
  flex-wrap: wrap;
}

.download__container {
  position: relative;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);

  margin-bottom: $general-block-margin;

  &:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: scaleY(0);
    transition-delay: 0s;
    animation: none;
    background-color: $gold-alpha;
    transition: transform 0.3s cubic-bezier(0, 0, 0.175, 1);
    transform-origin: bottom;
    user-select: none;
    pointer-events: none;
  }

  &:hover {
    &:after {
      transform: scaleY(1);
    }
  }
}

.download__link {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.download__preview {
  position: relative;
  z-index: 10;
  img {
    display: block;
  }
}

.download__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
  padding-left: 1rem;

  @include breakpoint(medium) {
    padding-bottom: 2rem;
    padding-left: 2rem;
  }

  h3 {
    hyphens: auto;
  }
  p {
    color: $black;
    font-weight: normal;
    margin-bottom: 0;
    .download__container--dark & {
      color: $white;
    }
  }
  img {
    @include breakpoint(medium) {
      max-width: 20rem;
    }
    padding-right: 0.5rem;
  }
}

.download--icon {
  margin-top: auto;
  margin-bottom: auto;

  @include breakpoint(medium) {
    margin-left: 6rem;
  }
  .icon {
    height: 5rem;
    width: 5rem;
  }
  svg polygon {
    @extend %topic-theme-color-fill;
  }
  .downloads--grid & {
    display: none;
  }
}

.icon--download {
  color: $gold;
  height: 30px;
  width: auto;
}
