.article-teaser {
  padding-top: $general-margin * 2;

  @include breakpoint(large) {
    padding: $general-margin * 3 0;
  }

  //.page--article & {
  //  padding-top: 0;
  //
  //  @include breakpoint(large) {
  //    padding-top: 0;
  //    padding-bottom: $general-margin * 2 ;
  //  }
  //}
}

.article-teaser__link {
  display: flex;
  align-items: center;
  max-width: fit-content;
}

.thur-teaser {
  position: relative;
}

.thur-teaser__content {
  text-align: center;
}

.thur-teaser__image-container {
  @include breakpoint(small only) {
    transform: translate(0, -20%);
  }
}
