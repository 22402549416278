$black: #202020;
$white: #ffffff;

// main colors

$racing-green: #13322d;
$deep-teal: #17403b;
$viridian: #3d8062;
$atlantis: #8bc147;
$conifer: #b7d253;
$manz: #e5eb50;
$wattle: #d1d64b;
$green: $atlantis;

$secondary: #120d34;
$gold: #ebb43a;
$gold-alpha: rgba(235, 180, 58, 0.1);

$dark-blue: #26418f;
$light-blue: #6197c0;
$dark-pink: #d090a0;
$vivid-red: #e44715;
$dark-red: #4b101f;
$light-red: #8f003d;

$dark-blue-alpha: rgba(38, 65, 143, 0.06);
$light-blue-alpha: rgba(97, 151, 192, 0.06);
$dark-pink-alpha: rgba(208, 144, 160, 0.06);
$vivid-red-alpha: rgba(228, 71, 21, 0.06);
$dark-red-alpha: rgba(75, 16, 31, 0.06);
$light-red-alpha: rgba(143, 0, 61, 0.06);

$light-gray: #f8f8f8;
$medium-gray: #efefef;
$dark-gray: #bfbfbf;
$menu-color: $dark-gray;

$primary: $black;
$headings-color: $black;

$primary-gray: $light-gray;
$secondary-gray: #a09f9f;

$box-shadow: 0 8px 17px -18px rgba(32, 32, 32, 0.6);

$deep-teal-rbga: rgba(19, 50, 45, 0.7);

// gradients

%page-teaser-gradient {
  position: relative;
  background-color: $deep-teal;

  &:before {
    content: "";
    background: transparent linear-gradient(0deg, #13575703 0%, #12304a5c 100%)
      0% 0% no-repeat padding-box;
    mix-blend-mode: multiply;

    position: absolute;
    top: 0;
    height: 30%;
    width: 100%;
    opacity: 0.8;
  }

  &:after {
    content: "";
    background: transparent
      linear-gradient(180deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat
      padding-box;
    mix-blend-mode: multiply;
    opacity: 0.8;
    position: absolute;
    bottom: 0;
    height: 30%;
    width: 100%;
  }
}

$page-header-gradient: transparent
  linear-gradient(0deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat
  padding-box;

%topic-theme-color-background {
  background-color: $gold-alpha;

  .topic--dark-blue & {
    background-color: $dark-blue-alpha;
  }
  .topic--light-blue & {
    background-color: $light-blue-alpha;
  }
  .topic--dark-red & {
    background-color: $dark-red-alpha;
  }
  .topic--dark-pink & {
    background-color: $dark-pink-alpha;
  }
  .topic--vivid-red & {
    background-color: $vivid-red-alpha;
  }
  .topic--light-red & {
    background-color: $light-red-alpha;
  }
}

%topic-theme-color-background-cover {
  background-color: $gold;
  color: $white;

  .topic--dark-blue & {
    background-color: $dark-blue;
  }
  .topic--light-blue & {
    background-color: $light-blue;
  }
  .topic--dark-red & {
    background-color: $dark-red;
  }
  .topic--dark-pink & {
    background-color: $dark-pink;
  }
  .topic--vivid-red & {
    background-color: $vivid-red;
  }
  .topic--light-red & {
    background-color: $light-red;
  }
}

%topic-theme-color-background-small {
  @include breakpoint(small only) {
    background-color: $gold-alpha;

    .topic--dark-blue & {
      background-color: $dark-blue-alpha;
    }
    .topic--light-blue & {
      background-color: $light-blue-alpha;
    }
    .topic--dark-red & {
      background-color: $dark-red-alpha;
    }
    .topic--dark-pink & {
      background-color: $dark-pink-alpha;
    }
    .topic--vivid-red & {
      background-color: $vivid-red-alpha;
    }
    .topic--light-red & {
      background-color: $light-red-alpha;
    }
  }
}

%topic-theme-color-background-medium {
  @include breakpoint(medium) {
    background-color: $gold-alpha;

    .topic--dark-blue & {
      background-color: $dark-blue-alpha;
    }
    .topic--light-blue & {
      background-color: $light-blue-alpha;
    }
    .topic--dark-red & {
      background-color: $dark-red-alpha;
    }
    .topic--dark-pink & {
      background-color: $dark-pink-alpha;
    }
    .topic--vivid-red & {
      background-color: $vivid-red-alpha;
    }
    .topic--light-red & {
      background-color: $light-red-alpha;
    }
  }
}

%topic-theme-color-border {
  border-color: $gold;

  .topic--dark-blue & {
    border-color: $dark-blue;
  }
  .topic--light-blue & {
    border-color: $light-blue;
  }
  .topic--dark-red & {
    border-color: $dark-red;
  }
  .topic--dark-pink & {
    border-color: $dark-pink;
  }
  .topic--vivid-red & {
    border-color: $vivid-red;
  }
  .topic--light-red & {
    border-color: $light-red;
  }
}

%topic-theme-font-color {
  color: $gold;

  .topic--dark-blue & {
    color: $dark-blue;
  }
  .topic--light-blue & {
    color: $light-blue;
  }
  .topic--dark-red & {
    color: $dark-red;
  }
  .topic--dark-pink & {
    color: $dark-pink;
  }
  .topic--vivid-red & {
    color: $vivid-red;
  }
  .topic--light-red & {
    color: $light-red;
  }
}

%topic-theme-color-fill {
  fill: $gold !important;

  .topic--dark-blue & {
    fill: $dark-blue !important;
  }
  .topic--light-blue & {
    fill: $light-blue !important;
  }
  .topic--dark-red & {
    fill: $dark-red !important;
  }
  .topic--dark-pink & {
    fill: $dark-pink !important;
  }
  .topic--vivid-red & {
    fill: $vivid-red !important;
  }
  .topic--light-red & {
    fill: $light-red !important;
  }
}

%topic-theme-color-stroke {
  stroke: $gold !important;

  .topic--dark-blue & {
    stroke: $dark-blue !important;
  }
  .topic--light-blue & {
    stroke: $light-blue !important;
  }
  .topic--dark-red & {
    stroke: $dark-red !important;
  }
  .topic--dark-pink & {
    stroke: $dark-pink !important;
  }
  .topic--vivid-red & {
    stroke: $vivid-red !important;
  }
  .topic--light-red & {
    stroke: $light-red !important;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
br,
p,
i,
a,
label,
blockquote,
figure,
div,
ol,
input,
pre,
sub,
span,
em,
button,
img,
strong,
li,
ul {
  margin: 0;
  padding: 0;

  &::selection {
    background: #ebb43a;
    color: black;
  }
  &.text--yellow,
  &.text--yellow-darken {
    &::selection {
      background: #ebb43a;
    }
  }
}
