.project-entry--section {
  position: relative;

  margin-top: 10rem;
}

.project__categories {
  display: flex;
}

.project__categories--cat {
  display: block;
  margin-bottom: $general-block-margin / 2;

  &:not(:last-child) {
    &:after {
      content: " |";
    }
  }

  &:not(:first-of-type) {
    margin-left: 0.35rem;
  }
}

.project-box {
  padding: 2rem;
  @extend %topic-theme-color-background;

  @include breakpoint(medium) {
    padding: 4rem;
  }
}

.project-box__item {
  margin-bottom: 0.5rem;
}

.project-box__item-wrapper {
  margin-bottom: $general-margin;
  padding-bottom: $general-block-margin;
  border-bottom: 1px solid $dark-gray;

  &.project-box__item-wrapper--last {
    border-bottom: none;
  }
}
