// Nav configs
$sticky-header-height: rem-calc(70);
$sticky-header-height-large: 5rem;

$icon-height: 1.5rem;
$icon-width: 2rem;
$icon-margin: 1rem;
$stroke-height: 0.25rem;

$nav-toggle-transition: 0.25s ease-in-out;

// Navigation
// ==========

.header {
  //padding-top: $sticky-header-height-large;
  background: transparent;
  top: 0;
  transition: $transition-slow;
  z-index: z-index("header");
  width: 100%;

  display: flex;
  justify-content: space-between;

  pointer-events: none;

  .body--404 & {
    filter: invert(1);
  }
}

.fixed-header {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem 1.7rem;
  @include breakpoint(medium) {
    padding: 2rem 2rem;
  }
}

.sticky-header {
  position: fixed;
  right: 1rem;
  padding: 1rem 0.25rem;
  @include breakpoint(medium) {
    padding: 2rem 1.2rem;
  }
}

.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: $white;
}

.navigation__left {
  display: flex;
  flex-direction: column;
  transition: opacity 0.4s ease-in-out;

  [data-overlay="true"] & {
    opacity: 0;
  }
}

.navigation__logo-item {
  display: block;
  max-width: 150px;
  pointer-events: all;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;

  .scrolled & {
    opacity: 0;
    pointer-events: none;
  }

  @include breakpoint(medium) {
    width: 150px;
    position: relative;
  }

  @include breakpoint(large) {
    width: 10vw;
    max-width: unset;
  }

  img {
    display: block;
    width: 100%;
  }
}

.navigation__container {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  transition: transform 0.4s ease-in-out, opacity 200ms ease-in-out;
  z-index: 100;

  .nav-is-open & {
    opacity: 1;
    display: block;
    padding-bottom: 5rem;
    background: transparent;
  }
}

.navigation__container--overlay {
  width: 100%;
  height: 100%;

  @include breakpoint(medium down) {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
}

.navigation__list {
  position: relative;
  transition: transform 0.4s ease-in-out;
  padding-left: 0;
  .nav-is-open & {
    opacity: 1;
    visibility: visible;
    margin-bottom: 1rem;
  }

  @include breakpoint(large) {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }
}

.rotate {
  transform: rotate(180deg);
}

.navigation__item {
  list-style-type: none;
  margin: 0;

  &.navigation__item--cte {
    text-align: left;
    margin-top: 0.5rem;
  }

  @include breakpoint(large) {
    margin-bottom: 0.1rem;
    text-align: center;
    padding: 0;
    margin-left: 2rem;
    &.navigation__item--cte {
      display: none;
    }
    &:last-child {
      padding-right: 1rem;
    }
  }
}

.navigation__link {
  position: relative;
  display: block;
  color: $white;
  opacity: 0;
  transition-delay: 500ms;
  width: fit-content;
  padding: 0.3rem 0 0.1rem;
  text-align: left;

  @extend %menu-font-styles;

  &:after {
    content: "•";
    position: absolute;
    bottom: -2.25rem;
    left: 50%;
    transform: translate(-50%, -50%);
    color: transparent;

    transition: $transition-fast;

    .nav-is-open & {
      position: absolute;
      bottom: -13px;
      left: -10px;
      margin-right: 1rem;
    }
  }

  .active &,
  &:hover {
    .nav-is-open & {
      left: 1rem;
    }

    &:after {
      color: $manz;
    }
  }

  @include breakpoint(large) {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    color: $white;
    width: auto;
    position: relative;
    padding: 0 0.25rem;
  }
  .nav-is-open & {
    opacity: 1;
  }
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

// Navigation Mobile
// =================

$size: 35px;
$default-color: $black;
$hover-color: $black;

.animate {
  transition: all 0.3s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.nav__trigger {
  display: flex;
  align-items: center;
  position: relative;
  width: $size;
  height: $size;
  //margin: auto;
  cursor: pointer;
  @extend .animate;
  z-index: 1100;
  pointer-events: all;

  [data-overlay-wrapper="true"] & {
    transition-delay: 0.2s;
    opacity: 1;
    transform: rotate(0);
  }
}

.nav__icon {
  display: inline-block;
  position: relative;
  width: $size;
  height: 3px;
  background-color: $black;

  &:before,
  &:after {
    content: "";
    display: block;
    width: $size;
    height: 3px;
    position: absolute;
    background: $black;
    transition-property: margin, transform, opacity;
    transition-duration: 300ms;
  }

  &:before {
    margin-top: -10px;
  }

  &:after {
    margin-top: 10px;
  }
  [data-overlay-toggle="close"] &,
  .close & {
    background: none;

    &:before {
      margin-top: 0;
      transform: rotate(45deg);
    }

    &:after {
      margin-top: 0;
      transform: rotate(-45deg);
    }
  }
}

// navigation--right
// =================

.navigation__right {
  display: flex;
  justify-content: flex-end;

  [data-overlay="true"] & {
    @include breakpoint(medium) {
      padding-right: 15px;
    }
  }
}

.language-menu {
  position: relative;
  //z-index: 1100;
  pointer-events: all;
  margin-right: $general-block-margin;
  opacity: 0;
  //transition: opacity 0.4s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
  width: 0;
  [data-overlay="true"] & {
    pointer-events: all;
    opacity: 1;
    width: 100%;
  }
}
