.logos__container {
  border-top: 1px solid $white;
  border-bottom: 1px solid $white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;
  background: $white;

  @include breakpoint(medium) {
    padding: 2rem 4rem;
  }

  .logo {
    flex: 0 1 45%;
    margin-bottom: $general-margin;
    display: block;

    @include breakpoint(medium) {
      margin-bottom: 0;
      flex: 0 1 33%;

      &.logo--small {
        padding: 2rem;
      }
    }

    img {
      display: block;
      margin: 0 auto;
    }
  }

  //&:after {
  //  content: '';
  //  flex: 1 1 25%;
  //}
}

// Fact Check

.fact-check {
  margin-top: -$general-margin;

  @include breakpoint(medium) {
    margin-top: -$general-margin / 2;
    margin-bottom: $general-margin;
  }

  svg {
    width: 100%;
  }
}

.fact-check--text {
  @include breakpoint(small only) {
    font-size: 40px !important;
    baseline-shift: -30px;

    &.fact-check--text--right {
      text-anchor: middle;
    }
  }
}
