.accordion--container {
  background: $white;
  padding: 3rem $general-margin;
}

.accordion--header {
  margin-bottom: $general-margin;
}

.accordion--wrapper {
  //margin-bottom: $general-margin;

  .accordion:last-of-type {
    padding-bottom: $general-block-margin;
    border-bottom: 1px solid $medium-gray;

    &.accordion--substring {
      border: none;
    }
  }

  &:hover,
  &.accordion--active {
    .accordion .accordion--headline p {
      color: rgba(4, 12, 38, 0.4);
    }
  }
}

.accordion {
  padding-bottom: $general-block-margin;

  &:hover p,
  &.toggled p {
    color: $black !important;
  }
}

.js-foldout-wrapper {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 1s cubic-bezier(0.19, 1, 0.22, 1),
    margin 1s cubic-bezier(0.19, 1, 0.22, 1),
    opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;

  .toggled & {
    opacity: 1;
  }
}

.js-foldout-body {
  //padding-bottom: $general-block-margin;
  padding-top: $general-block-margin;

  p {
    margin-bottom: 0;
    max-width: 90%;

    .accordion--substring & {
      max-width: 100%;
      margin-bottom: $general-block-margin;
      @include breakpoint(medium) {
        max-width: 90%;
      }
    }
  }
}

.accordion--headline {
  border-top: 1px solid $medium-gray;
  padding-top: $general-block-margin;
  cursor: pointer;
  .accordion--substring & {
    border: none;
  }
  p {
    margin-bottom: 0;
    max-width: 90%;
  }
}

.accordion--headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .slider--subpage & .accordion--headline-inner-wrapper {
    width: 80%;
  }
}
