@charset "UTF-8";
/* http://jaydenseric.com/blog/forget-normalize-or-resets-lay-your-own-css-foundation */
@import url("http://fast.fonts.net/t/1.css?apiType=css&projectid=9e19379b-13fa-42b8-8618-e21be2aab3b4");
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

*,
*:after,
*:before {
  box-sizing: inherit;
}

body {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font: 16px/1 sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

main {
  display: block;
}

h1,
h2,
h3,
h4 {
  font-size: inherit;
}

strong {
  font-weight: bold;
}

a,
button {
  color: inherit;
}

a {
  text-decoration: none;
}

button {
  overflow: visible;
  border: 0;
  font: inherit;
  -webkit-font-smoothing: inherit;
  letter-spacing: inherit;
  background: none;
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border: 0;
}

img {
  max-width: 100%;
  height: auto;
  border: 0;
}

/**
 * Foundation for Sites by ZURB
 * Version 6.5.0-rc.2
 * foundation.zurb.com
 * Licensed under MIT Open Source
 */
.grid-container {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 80rem;
  margin: 0 auto;
}

@media print, screen and (min-width: 46.875em) {
  .grid-container {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.fluid {
  padding-right: 0.625rem;
  padding-left: 0.625rem;
  max-width: 100%;
  margin: 0 auto;
}

@media print, screen and (min-width: 46.875em) {
  .grid-container.fluid {
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;
  }
}

.grid-container.full {
  padding-right: 0;
  padding-left: 0;
  max-width: 100%;
  margin: 0 auto;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.cell {
  flex: 0 0 auto;
  min-height: 0px;
  min-width: 0px;
  width: 100%;
}

.cell.auto {
  flex: 1 1 0px;
}

.cell.shrink {
  flex: 0 0 auto;
}

.grid-x > .auto {
  width: auto;
}

.grid-x > .shrink {
  width: auto;
}

.grid-x > .small-shrink, .grid-x > .small-full, .grid-x > .small-1, .grid-x > .small-2, .grid-x > .small-3, .grid-x > .small-4, .grid-x > .small-5, .grid-x > .small-6, .grid-x > .small-7, .grid-x > .small-8, .grid-x > .small-9, .grid-x > .small-10, .grid-x > .small-11, .grid-x > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 46.875em) {
  .grid-x > .medium-shrink, .grid-x > .medium-full, .grid-x > .medium-1, .grid-x > .medium-2, .grid-x > .medium-3, .grid-x > .medium-4, .grid-x > .medium-5, .grid-x > .medium-6, .grid-x > .medium-7, .grid-x > .medium-8, .grid-x > .medium-9, .grid-x > .medium-10, .grid-x > .medium-11, .grid-x > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-shrink, .grid-x > .large-full, .grid-x > .large-1, .grid-x > .large-2, .grid-x > .large-3, .grid-x > .large-4, .grid-x > .large-5, .grid-x > .large-6, .grid-x > .large-7, .grid-x > .large-8, .grid-x > .large-9, .grid-x > .large-10, .grid-x > .large-11, .grid-x > .large-12 {
    flex-basis: auto;
  }
}

.grid-x > .small-1 {
  flex: 0 0 auto;
  width: 8.33333%;
}

.grid-x > .small-2 {
  flex: 0 0 auto;
  width: 16.66667%;
}

.grid-x > .small-3 {
  flex: 0 0 auto;
  width: 25%;
}

.grid-x > .small-4 {
  flex: 0 0 auto;
  width: 33.33333%;
}

.grid-x > .small-5 {
  flex: 0 0 auto;
  width: 41.66667%;
}

.grid-x > .small-6 {
  flex: 0 0 auto;
  width: 50%;
}

.grid-x > .small-7 {
  flex: 0 0 auto;
  width: 58.33333%;
}

.grid-x > .small-8 {
  flex: 0 0 auto;
  width: 66.66667%;
}

.grid-x > .small-9 {
  flex: 0 0 auto;
  width: 75%;
}

.grid-x > .small-10 {
  flex: 0 0 auto;
  width: 83.33333%;
}

.grid-x > .small-11 {
  flex: 0 0 auto;
  width: 91.66667%;
}

.grid-x > .small-12 {
  flex: 0 0 auto;
  width: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .grid-x > .medium-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .medium-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .medium-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .grid-x > .medium-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .grid-x > .medium-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .grid-x > .medium-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .grid-x > .medium-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .grid-x > .medium-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .grid-x > .medium-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .grid-x > .medium-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .grid-x > .medium-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .grid-x > .medium-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .grid-x > .medium-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .grid-x > .medium-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-x > .large-auto {
    flex: 1 1 0px;
    width: auto;
  }
  .grid-x > .large-shrink {
    flex: 0 0 auto;
    width: auto;
  }
  .grid-x > .large-1 {
    flex: 0 0 auto;
    width: 8.33333%;
  }
  .grid-x > .large-2 {
    flex: 0 0 auto;
    width: 16.66667%;
  }
  .grid-x > .large-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .grid-x > .large-4 {
    flex: 0 0 auto;
    width: 33.33333%;
  }
  .grid-x > .large-5 {
    flex: 0 0 auto;
    width: 41.66667%;
  }
  .grid-x > .large-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .grid-x > .large-7 {
    flex: 0 0 auto;
    width: 58.33333%;
  }
  .grid-x > .large-8 {
    flex: 0 0 auto;
    width: 66.66667%;
  }
  .grid-x > .large-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .grid-x > .large-10 {
    flex: 0 0 auto;
    width: 83.33333%;
  }
  .grid-x > .large-11 {
    flex: 0 0 auto;
    width: 91.66667%;
  }
  .grid-x > .large-12 {
    flex: 0 0 auto;
    width: 100%;
  }
}

.grid-margin-x:not(.grid-x) > .cell {
  width: auto;
}

.grid-margin-y:not(.grid-y) > .cell {
  height: auto;
}

.grid-margin-x {
  margin-left: -0.3125rem;
  margin-right: -0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-x {
    margin-left: -0;
    margin-right: -0;
  }
}

.grid-margin-x > .cell {
  width: calc(100% - 0.625rem);
  margin-left: 0.3125rem;
  margin-right: 0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-x > .cell {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
  }
}

.grid-margin-x > .auto {
  width: auto;
}

.grid-margin-x > .shrink {
  width: auto;
}

.grid-margin-x > .small-1 {
  width: calc(8.33333% - 0.625rem);
}

.grid-margin-x > .small-2 {
  width: calc(16.66667% - 0.625rem);
}

.grid-margin-x > .small-3 {
  width: calc(25% - 0.625rem);
}

.grid-margin-x > .small-4 {
  width: calc(33.33333% - 0.625rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.66667% - 0.625rem);
}

.grid-margin-x > .small-6 {
  width: calc(50% - 0.625rem);
}

.grid-margin-x > .small-7 {
  width: calc(58.33333% - 0.625rem);
}

.grid-margin-x > .small-8 {
  width: calc(66.66667% - 0.625rem);
}

.grid-margin-x > .small-9 {
  width: calc(75% - 0.625rem);
}

.grid-margin-x > .small-10 {
  width: calc(83.33333% - 0.625rem);
}

.grid-margin-x > .small-11 {
  width: calc(91.66667% - 0.625rem);
}

.grid-margin-x > .small-12 {
  width: calc(100% - 0.625rem);
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-x > .auto {
    width: auto;
  }
  .grid-margin-x > .shrink {
    width: auto;
  }
  .grid-margin-x > .small-1 {
    width: 8.33333%;
  }
  .grid-margin-x > .small-2 {
    width: 16.66667%;
  }
  .grid-margin-x > .small-3 {
    width: 25%;
  }
  .grid-margin-x > .small-4 {
    width: 33.33333%;
  }
  .grid-margin-x > .small-5 {
    width: 41.66667%;
  }
  .grid-margin-x > .small-6 {
    width: 50%;
  }
  .grid-margin-x > .small-7 {
    width: 58.33333%;
  }
  .grid-margin-x > .small-8 {
    width: 66.66667%;
  }
  .grid-margin-x > .small-9 {
    width: 75%;
  }
  .grid-margin-x > .small-10 {
    width: 83.33333%;
  }
  .grid-margin-x > .small-11 {
    width: 91.66667%;
  }
  .grid-margin-x > .small-12 {
    width: 100%;
  }
  .grid-margin-x > .medium-auto {
    width: auto;
  }
  .grid-margin-x > .medium-shrink {
    width: auto;
  }
  .grid-margin-x > .medium-1 {
    width: 8.33333%;
  }
  .grid-margin-x > .medium-2 {
    width: 16.66667%;
  }
  .grid-margin-x > .medium-3 {
    width: 25%;
  }
  .grid-margin-x > .medium-4 {
    width: 33.33333%;
  }
  .grid-margin-x > .medium-5 {
    width: 41.66667%;
  }
  .grid-margin-x > .medium-6 {
    width: 50%;
  }
  .grid-margin-x > .medium-7 {
    width: 58.33333%;
  }
  .grid-margin-x > .medium-8 {
    width: 66.66667%;
  }
  .grid-margin-x > .medium-9 {
    width: 75%;
  }
  .grid-margin-x > .medium-10 {
    width: 83.33333%;
  }
  .grid-margin-x > .medium-11 {
    width: 91.66667%;
  }
  .grid-margin-x > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x > .large-auto {
    width: auto;
  }
  .grid-margin-x > .large-shrink {
    width: auto;
  }
  .grid-margin-x > .large-1 {
    width: 8.33333%;
  }
  .grid-margin-x > .large-2 {
    width: 16.66667%;
  }
  .grid-margin-x > .large-3 {
    width: 25%;
  }
  .grid-margin-x > .large-4 {
    width: 33.33333%;
  }
  .grid-margin-x > .large-5 {
    width: 41.66667%;
  }
  .grid-margin-x > .large-6 {
    width: 50%;
  }
  .grid-margin-x > .large-7 {
    width: 58.33333%;
  }
  .grid-margin-x > .large-8 {
    width: 66.66667%;
  }
  .grid-margin-x > .large-9 {
    width: 75%;
  }
  .grid-margin-x > .large-10 {
    width: 83.33333%;
  }
  .grid-margin-x > .large-11 {
    width: 91.66667%;
  }
  .grid-margin-x > .large-12 {
    width: 100%;
  }
}

.grid-padding-x .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-padding-x .grid-padding-x {
    margin-right: -0;
    margin-left: -0;
  }
}

.grid-container:not(.full) > .grid-padding-x {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-container:not(.full) > .grid-padding-x {
    margin-right: -0;
    margin-left: -0;
  }
}

.grid-padding-x > .cell {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-padding-x > .cell {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
}

.small-up-1 > .cell {
  width: 100%;
}

.small-up-2 > .cell {
  width: 50%;
}

.small-up-3 > .cell {
  width: 33.33333%;
}

.small-up-4 > .cell {
  width: 25%;
}

.small-up-5 > .cell {
  width: 20%;
}

.small-up-6 > .cell {
  width: 16.66667%;
}

.small-up-7 > .cell {
  width: 14.28571%;
}

.small-up-8 > .cell {
  width: 12.5%;
}

@media print, screen and (min-width: 46.875em) {
  .medium-up-1 > .cell {
    width: 100%;
  }
  .medium-up-2 > .cell {
    width: 50%;
  }
  .medium-up-3 > .cell {
    width: 33.33333%;
  }
  .medium-up-4 > .cell {
    width: 25%;
  }
  .medium-up-5 > .cell {
    width: 20%;
  }
  .medium-up-6 > .cell {
    width: 16.66667%;
  }
  .medium-up-7 > .cell {
    width: 14.28571%;
  }
  .medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-up-1 > .cell {
    width: 100%;
  }
  .large-up-2 > .cell {
    width: 50%;
  }
  .large-up-3 > .cell {
    width: 33.33333%;
  }
  .large-up-4 > .cell {
    width: 25%;
  }
  .large-up-5 > .cell {
    width: 20%;
  }
  .large-up-6 > .cell {
    width: 16.66667%;
  }
  .large-up-7 > .cell {
    width: 14.28571%;
  }
  .large-up-8 > .cell {
    width: 12.5%;
  }
}

.grid-margin-x.small-up-1 > .cell {
  width: calc(100% - 0.625rem);
}

.grid-margin-x.small-up-2 > .cell {
  width: calc(50% - 0.625rem);
}

.grid-margin-x.small-up-3 > .cell {
  width: calc(33.33333% - 0.625rem);
}

.grid-margin-x.small-up-4 > .cell {
  width: calc(25% - 0.625rem);
}

.grid-margin-x.small-up-5 > .cell {
  width: calc(20% - 0.625rem);
}

.grid-margin-x.small-up-6 > .cell {
  width: calc(16.66667% - 0.625rem);
}

.grid-margin-x.small-up-7 > .cell {
  width: calc(14.28571% - 0.625rem);
}

.grid-margin-x.small-up-8 > .cell {
  width: calc(12.5% - 0.625rem);
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-x.small-up-1 > .cell {
    width: 100%;
  }
  .grid-margin-x.small-up-2 > .cell {
    width: 50%;
  }
  .grid-margin-x.small-up-3 > .cell {
    width: 33.33333%;
  }
  .grid-margin-x.small-up-4 > .cell {
    width: 25%;
  }
  .grid-margin-x.small-up-5 > .cell {
    width: 20%;
  }
  .grid-margin-x.small-up-6 > .cell {
    width: 16.66667%;
  }
  .grid-margin-x.small-up-7 > .cell {
    width: 14.28571%;
  }
  .grid-margin-x.small-up-8 > .cell {
    width: 12.5%;
  }
  .grid-margin-x.medium-up-1 > .cell {
    width: 100%;
  }
  .grid-margin-x.medium-up-2 > .cell {
    width: 50%;
  }
  .grid-margin-x.medium-up-3 > .cell {
    width: 33.33333%;
  }
  .grid-margin-x.medium-up-4 > .cell {
    width: 25%;
  }
  .grid-margin-x.medium-up-5 > .cell {
    width: 20%;
  }
  .grid-margin-x.medium-up-6 > .cell {
    width: 16.66667%;
  }
  .grid-margin-x.medium-up-7 > .cell {
    width: 14.28571%;
  }
  .grid-margin-x.medium-up-8 > .cell {
    width: 12.5%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-x.large-up-1 > .cell {
    width: 100%;
  }
  .grid-margin-x.large-up-2 > .cell {
    width: 50%;
  }
  .grid-margin-x.large-up-3 > .cell {
    width: 33.33333%;
  }
  .grid-margin-x.large-up-4 > .cell {
    width: 25%;
  }
  .grid-margin-x.large-up-5 > .cell {
    width: 20%;
  }
  .grid-margin-x.large-up-6 > .cell {
    width: 16.66667%;
  }
  .grid-margin-x.large-up-7 > .cell {
    width: 14.28571%;
  }
  .grid-margin-x.large-up-8 > .cell {
    width: 12.5%;
  }
}

.small-margin-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .cell {
  margin-right: 0;
  margin-left: 0;
}

.small-margin-collapse > .small-1 {
  width: 8.33333%;
}

.small-margin-collapse > .small-2 {
  width: 16.66667%;
}

.small-margin-collapse > .small-3 {
  width: 25%;
}

.small-margin-collapse > .small-4 {
  width: 33.33333%;
}

.small-margin-collapse > .small-5 {
  width: 41.66667%;
}

.small-margin-collapse > .small-6 {
  width: 50%;
}

.small-margin-collapse > .small-7 {
  width: 58.33333%;
}

.small-margin-collapse > .small-8 {
  width: 66.66667%;
}

.small-margin-collapse > .small-9 {
  width: 75%;
}

.small-margin-collapse > .small-10 {
  width: 83.33333%;
}

.small-margin-collapse > .small-11 {
  width: 91.66667%;
}

.small-margin-collapse > .small-12 {
  width: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .small-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .medium-3 {
    width: 25%;
  }
  .small-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .medium-6 {
    width: 50%;
  }
  .small-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .medium-9 {
    width: 75%;
  }
  .small-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .small-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .small-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .small-margin-collapse > .large-3 {
    width: 25%;
  }
  .small-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .small-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .small-margin-collapse > .large-6 {
    width: 50%;
  }
  .small-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .small-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .small-margin-collapse > .large-9 {
    width: 75%;
  }
  .small-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .small-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .small-margin-collapse > .large-12 {
    width: 100%;
  }
}

.small-padding-collapse {
  margin-right: 0;
  margin-left: 0;
}

.small-padding-collapse > .cell {
  padding-right: 0;
  padding-left: 0;
}

@media print, screen and (min-width: 46.875em) {
  .medium-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 46.875em) {
  .medium-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .small-3 {
    width: 25%;
  }
  .medium-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .small-6 {
    width: 50%;
  }
  .medium-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .small-9 {
    width: 75%;
  }
  .medium-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 46.875em) {
  .medium-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .medium-3 {
    width: 25%;
  }
  .medium-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .medium-6 {
    width: 50%;
  }
  .medium-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .medium-9 {
    width: 75%;
  }
  .medium-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .medium-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .medium-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .medium-margin-collapse > .large-3 {
    width: 25%;
  }
  .medium-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .medium-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .medium-margin-collapse > .large-6 {
    width: 50%;
  }
  .medium-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .medium-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .medium-margin-collapse > .large-9 {
    width: 75%;
  }
  .medium-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .medium-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .medium-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 46.875em) {
  .medium-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .medium-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-margin-collapse > .cell {
    margin-right: 0;
    margin-left: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .small-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .small-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .small-3 {
    width: 25%;
  }
  .large-margin-collapse > .small-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .small-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .small-6 {
    width: 50%;
  }
  .large-margin-collapse > .small-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .small-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .small-9 {
    width: 75%;
  }
  .large-margin-collapse > .small-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .small-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .small-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .medium-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .medium-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .medium-3 {
    width: 25%;
  }
  .large-margin-collapse > .medium-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .medium-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .medium-6 {
    width: 50%;
  }
  .large-margin-collapse > .medium-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .medium-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .medium-9 {
    width: 75%;
  }
  .large-margin-collapse > .medium-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .medium-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .medium-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-margin-collapse > .large-1 {
    width: 8.33333%;
  }
  .large-margin-collapse > .large-2 {
    width: 16.66667%;
  }
  .large-margin-collapse > .large-3 {
    width: 25%;
  }
  .large-margin-collapse > .large-4 {
    width: 33.33333%;
  }
  .large-margin-collapse > .large-5 {
    width: 41.66667%;
  }
  .large-margin-collapse > .large-6 {
    width: 50%;
  }
  .large-margin-collapse > .large-7 {
    width: 58.33333%;
  }
  .large-margin-collapse > .large-8 {
    width: 66.66667%;
  }
  .large-margin-collapse > .large-9 {
    width: 75%;
  }
  .large-margin-collapse > .large-10 {
    width: 83.33333%;
  }
  .large-margin-collapse > .large-11 {
    width: 91.66667%;
  }
  .large-margin-collapse > .large-12 {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .large-padding-collapse {
    margin-right: 0;
    margin-left: 0;
  }
  .large-padding-collapse > .cell {
    padding-right: 0;
    padding-left: 0;
  }
}

.small-offset-0 {
  margin-left: 0%;
}

.grid-margin-x > .small-offset-0 {
  margin-left: calc(0% + 0.3125rem);
}

.small-offset-1 {
  margin-left: 8.33333%;
}

.grid-margin-x > .small-offset-1 {
  margin-left: calc(8.33333% + 0.3125rem);
}

.small-offset-2 {
  margin-left: 16.66667%;
}

.grid-margin-x > .small-offset-2 {
  margin-left: calc(16.66667% + 0.3125rem);
}

.small-offset-3 {
  margin-left: 25%;
}

.grid-margin-x > .small-offset-3 {
  margin-left: calc(25% + 0.3125rem);
}

.small-offset-4 {
  margin-left: 33.33333%;
}

.grid-margin-x > .small-offset-4 {
  margin-left: calc(33.33333% + 0.3125rem);
}

.small-offset-5 {
  margin-left: 41.66667%;
}

.grid-margin-x > .small-offset-5 {
  margin-left: calc(41.66667% + 0.3125rem);
}

.small-offset-6 {
  margin-left: 50%;
}

.grid-margin-x > .small-offset-6 {
  margin-left: calc(50% + 0.3125rem);
}

.small-offset-7 {
  margin-left: 58.33333%;
}

.grid-margin-x > .small-offset-7 {
  margin-left: calc(58.33333% + 0.3125rem);
}

.small-offset-8 {
  margin-left: 66.66667%;
}

.grid-margin-x > .small-offset-8 {
  margin-left: calc(66.66667% + 0.3125rem);
}

.small-offset-9 {
  margin-left: 75%;
}

.grid-margin-x > .small-offset-9 {
  margin-left: calc(75% + 0.3125rem);
}

.small-offset-10 {
  margin-left: 83.33333%;
}

.grid-margin-x > .small-offset-10 {
  margin-left: calc(83.33333% + 0.3125rem);
}

.small-offset-11 {
  margin-left: 91.66667%;
}

.grid-margin-x > .small-offset-11 {
  margin-left: calc(91.66667% + 0.3125rem);
}

@media print, screen and (min-width: 46.875em) {
  .medium-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .medium-offset-0 {
    margin-left: calc(0% + 0);
  }
  .medium-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .medium-offset-1 {
    margin-left: calc(8.33333% + 0);
  }
  .medium-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .medium-offset-2 {
    margin-left: calc(16.66667% + 0);
  }
  .medium-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .medium-offset-3 {
    margin-left: calc(25% + 0);
  }
  .medium-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .medium-offset-4 {
    margin-left: calc(33.33333% + 0);
  }
  .medium-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .medium-offset-5 {
    margin-left: calc(41.66667% + 0);
  }
  .medium-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .medium-offset-6 {
    margin-left: calc(50% + 0);
  }
  .medium-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .medium-offset-7 {
    margin-left: calc(58.33333% + 0);
  }
  .medium-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .medium-offset-8 {
    margin-left: calc(66.66667% + 0);
  }
  .medium-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .medium-offset-9 {
    margin-left: calc(75% + 0);
  }
  .medium-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .medium-offset-10 {
    margin-left: calc(83.33333% + 0);
  }
  .medium-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .medium-offset-11 {
    margin-left: calc(91.66667% + 0);
  }
}

@media print, screen and (min-width: 64em) {
  .large-offset-0 {
    margin-left: 0%;
  }
  .grid-margin-x > .large-offset-0 {
    margin-left: calc(0% + 0);
  }
  .large-offset-1 {
    margin-left: 8.33333%;
  }
  .grid-margin-x > .large-offset-1 {
    margin-left: calc(8.33333% + 0);
  }
  .large-offset-2 {
    margin-left: 16.66667%;
  }
  .grid-margin-x > .large-offset-2 {
    margin-left: calc(16.66667% + 0);
  }
  .large-offset-3 {
    margin-left: 25%;
  }
  .grid-margin-x > .large-offset-3 {
    margin-left: calc(25% + 0);
  }
  .large-offset-4 {
    margin-left: 33.33333%;
  }
  .grid-margin-x > .large-offset-4 {
    margin-left: calc(33.33333% + 0);
  }
  .large-offset-5 {
    margin-left: 41.66667%;
  }
  .grid-margin-x > .large-offset-5 {
    margin-left: calc(41.66667% + 0);
  }
  .large-offset-6 {
    margin-left: 50%;
  }
  .grid-margin-x > .large-offset-6 {
    margin-left: calc(50% + 0);
  }
  .large-offset-7 {
    margin-left: 58.33333%;
  }
  .grid-margin-x > .large-offset-7 {
    margin-left: calc(58.33333% + 0);
  }
  .large-offset-8 {
    margin-left: 66.66667%;
  }
  .grid-margin-x > .large-offset-8 {
    margin-left: calc(66.66667% + 0);
  }
  .large-offset-9 {
    margin-left: 75%;
  }
  .grid-margin-x > .large-offset-9 {
    margin-left: calc(75% + 0);
  }
  .large-offset-10 {
    margin-left: 83.33333%;
  }
  .grid-margin-x > .large-offset-10 {
    margin-left: calc(83.33333% + 0);
  }
  .large-offset-11 {
    margin-left: 91.66667%;
  }
  .grid-margin-x > .large-offset-11 {
    margin-left: calc(91.66667% + 0);
  }
}

.grid-y {
  display: flex;
  flex-flow: column nowrap;
}

.grid-y > .cell {
  width: auto;
  max-width: none;
}

.grid-y > .auto {
  height: auto;
}

.grid-y > .shrink {
  height: auto;
}

.grid-y > .small-shrink, .grid-y > .small-full, .grid-y > .small-1, .grid-y > .small-2, .grid-y > .small-3, .grid-y > .small-4, .grid-y > .small-5, .grid-y > .small-6, .grid-y > .small-7, .grid-y > .small-8, .grid-y > .small-9, .grid-y > .small-10, .grid-y > .small-11, .grid-y > .small-12 {
  flex-basis: auto;
}

@media print, screen and (min-width: 46.875em) {
  .grid-y > .medium-shrink, .grid-y > .medium-full, .grid-y > .medium-1, .grid-y > .medium-2, .grid-y > .medium-3, .grid-y > .medium-4, .grid-y > .medium-5, .grid-y > .medium-6, .grid-y > .medium-7, .grid-y > .medium-8, .grid-y > .medium-9, .grid-y > .medium-10, .grid-y > .medium-11, .grid-y > .medium-12 {
    flex-basis: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-shrink, .grid-y > .large-full, .grid-y > .large-1, .grid-y > .large-2, .grid-y > .large-3, .grid-y > .large-4, .grid-y > .large-5, .grid-y > .large-6, .grid-y > .large-7, .grid-y > .large-8, .grid-y > .large-9, .grid-y > .large-10, .grid-y > .large-11, .grid-y > .large-12 {
    flex-basis: auto;
  }
}

.grid-y > .small-1 {
  height: 8.33333%;
}

.grid-y > .small-2 {
  height: 16.66667%;
}

.grid-y > .small-3 {
  height: 25%;
}

.grid-y > .small-4 {
  height: 33.33333%;
}

.grid-y > .small-5 {
  height: 41.66667%;
}

.grid-y > .small-6 {
  height: 50%;
}

.grid-y > .small-7 {
  height: 58.33333%;
}

.grid-y > .small-8 {
  height: 66.66667%;
}

.grid-y > .small-9 {
  height: 75%;
}

.grid-y > .small-10 {
  height: 83.33333%;
}

.grid-y > .small-11 {
  height: 91.66667%;
}

.grid-y > .small-12 {
  height: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .grid-y > .medium-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .medium-shrink {
    height: auto;
  }
  .grid-y > .medium-1 {
    height: 8.33333%;
  }
  .grid-y > .medium-2 {
    height: 16.66667%;
  }
  .grid-y > .medium-3 {
    height: 25%;
  }
  .grid-y > .medium-4 {
    height: 33.33333%;
  }
  .grid-y > .medium-5 {
    height: 41.66667%;
  }
  .grid-y > .medium-6 {
    height: 50%;
  }
  .grid-y > .medium-7 {
    height: 58.33333%;
  }
  .grid-y > .medium-8 {
    height: 66.66667%;
  }
  .grid-y > .medium-9 {
    height: 75%;
  }
  .grid-y > .medium-10 {
    height: 83.33333%;
  }
  .grid-y > .medium-11 {
    height: 91.66667%;
  }
  .grid-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y > .large-auto {
    flex: 1 1 0px;
    height: auto;
  }
  .grid-y > .large-shrink {
    height: auto;
  }
  .grid-y > .large-1 {
    height: 8.33333%;
  }
  .grid-y > .large-2 {
    height: 16.66667%;
  }
  .grid-y > .large-3 {
    height: 25%;
  }
  .grid-y > .large-4 {
    height: 33.33333%;
  }
  .grid-y > .large-5 {
    height: 41.66667%;
  }
  .grid-y > .large-6 {
    height: 50%;
  }
  .grid-y > .large-7 {
    height: 58.33333%;
  }
  .grid-y > .large-8 {
    height: 66.66667%;
  }
  .grid-y > .large-9 {
    height: 75%;
  }
  .grid-y > .large-10 {
    height: 83.33333%;
  }
  .grid-y > .large-11 {
    height: 91.66667%;
  }
  .grid-y > .large-12 {
    height: 100%;
  }
}

.grid-padding-y .grid-padding-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-padding-y .grid-padding-y {
    margin-top: -0;
    margin-bottom: -0;
  }
}

.grid-padding-y > .cell {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-padding-y > .cell {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
}

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y {
    margin-top: -0;
    margin-bottom: -0;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 0.625rem);
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y > .cell {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 0.625rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.66667% - 0.625rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 0.625rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.33333% - 0.625rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.66667% - 0.625rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 0.625rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.33333% - 0.625rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.66667% - 0.625rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 0.625rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.33333% - 0.625rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.66667% - 0.625rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 0.625rem);
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: 8.33333%;
  }
  .grid-margin-y > .small-2 {
    height: 16.66667%;
  }
  .grid-margin-y > .small-3 {
    height: 25%;
  }
  .grid-margin-y > .small-4 {
    height: 33.33333%;
  }
  .grid-margin-y > .small-5 {
    height: 41.66667%;
  }
  .grid-margin-y > .small-6 {
    height: 50%;
  }
  .grid-margin-y > .small-7 {
    height: 58.33333%;
  }
  .grid-margin-y > .small-8 {
    height: 66.66667%;
  }
  .grid-margin-y > .small-9 {
    height: 75%;
  }
  .grid-margin-y > .small-10 {
    height: 83.33333%;
  }
  .grid-margin-y > .small-11 {
    height: 91.66667%;
  }
  .grid-margin-y > .small-12 {
    height: 100%;
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: 8.33333%;
  }
  .grid-margin-y > .medium-2 {
    height: 16.66667%;
  }
  .grid-margin-y > .medium-3 {
    height: 25%;
  }
  .grid-margin-y > .medium-4 {
    height: 33.33333%;
  }
  .grid-margin-y > .medium-5 {
    height: 41.66667%;
  }
  .grid-margin-y > .medium-6 {
    height: 50%;
  }
  .grid-margin-y > .medium-7 {
    height: 58.33333%;
  }
  .grid-margin-y > .medium-8 {
    height: 66.66667%;
  }
  .grid-margin-y > .medium-9 {
    height: 75%;
  }
  .grid-margin-y > .medium-10 {
    height: 83.33333%;
  }
  .grid-margin-y > .medium-11 {
    height: 91.66667%;
  }
  .grid-margin-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: 8.33333%;
  }
  .grid-margin-y > .large-2 {
    height: 16.66667%;
  }
  .grid-margin-y > .large-3 {
    height: 25%;
  }
  .grid-margin-y > .large-4 {
    height: 33.33333%;
  }
  .grid-margin-y > .large-5 {
    height: 41.66667%;
  }
  .grid-margin-y > .large-6 {
    height: 50%;
  }
  .grid-margin-y > .large-7 {
    height: 58.33333%;
  }
  .grid-margin-y > .large-8 {
    height: 66.66667%;
  }
  .grid-margin-y > .large-9 {
    height: 75%;
  }
  .grid-margin-y > .large-10 {
    height: 83.33333%;
  }
  .grid-margin-y > .large-11 {
    height: 91.66667%;
  }
  .grid-margin-y > .large-12 {
    height: 100%;
  }
}

.grid-frame {
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100vw;
}

.cell .grid-frame {
  width: 100%;
}

.cell-block {
  overflow-x: auto;
  max-width: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-y {
  overflow-y: auto;
  max-height: 100%;
  min-height: 100%;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.cell-block-container {
  display: flex;
  flex-direction: column;
  max-height: 100%;
}

.cell-block-container > .grid-x {
  max-height: 100%;
  flex-wrap: nowrap;
}

@media print, screen and (min-width: 46.875em) {
  .medium-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .medium-grid-frame {
    width: 100%;
  }
  .medium-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .medium-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .medium-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .medium-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

@media print, screen and (min-width: 64em) {
  .large-grid-frame {
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    width: 100vw;
  }
  .cell .large-grid-frame {
    width: 100%;
  }
  .large-cell-block {
    overflow-x: auto;
    max-width: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
  .large-cell-block-container {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }
  .large-cell-block-container > .grid-x {
    max-height: 100%;
    flex-wrap: nowrap;
  }
  .large-cell-block-y {
    overflow-y: auto;
    max-height: 100%;
    min-height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
  }
}

.grid-y.grid-frame {
  width: auto;
  overflow: hidden;
  position: relative;
  flex-wrap: nowrap;
  align-items: stretch;
  height: 100vh;
}

@media print, screen and (min-width: 46.875em) {
  .grid-y.medium-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-y.large-grid-frame {
    width: auto;
    overflow: hidden;
    position: relative;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100vh;
  }
}

.cell .grid-y.grid-frame {
  height: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .cell .grid-y.medium-grid-frame {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .cell .grid-y.large-grid-frame {
    height: 100%;
  }
}

.grid-margin-y {
  margin-top: -0.3125rem;
  margin-bottom: -0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y {
    margin-top: -0;
    margin-bottom: -0;
  }
}

.grid-margin-y > .cell {
  height: calc(100% - 0.625rem);
  margin-top: 0.3125rem;
  margin-bottom: 0.3125rem;
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y > .cell {
    height: 100%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.grid-margin-y > .auto {
  height: auto;
}

.grid-margin-y > .shrink {
  height: auto;
}

.grid-margin-y > .small-1 {
  height: calc(8.33333% - 0.625rem);
}

.grid-margin-y > .small-2 {
  height: calc(16.66667% - 0.625rem);
}

.grid-margin-y > .small-3 {
  height: calc(25% - 0.625rem);
}

.grid-margin-y > .small-4 {
  height: calc(33.33333% - 0.625rem);
}

.grid-margin-y > .small-5 {
  height: calc(41.66667% - 0.625rem);
}

.grid-margin-y > .small-6 {
  height: calc(50% - 0.625rem);
}

.grid-margin-y > .small-7 {
  height: calc(58.33333% - 0.625rem);
}

.grid-margin-y > .small-8 {
  height: calc(66.66667% - 0.625rem);
}

.grid-margin-y > .small-9 {
  height: calc(75% - 0.625rem);
}

.grid-margin-y > .small-10 {
  height: calc(83.33333% - 0.625rem);
}

.grid-margin-y > .small-11 {
  height: calc(91.66667% - 0.625rem);
}

.grid-margin-y > .small-12 {
  height: calc(100% - 0.625rem);
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y > .auto {
    height: auto;
  }
  .grid-margin-y > .shrink {
    height: auto;
  }
  .grid-margin-y > .small-1 {
    height: 8.33333%;
  }
  .grid-margin-y > .small-2 {
    height: 16.66667%;
  }
  .grid-margin-y > .small-3 {
    height: 25%;
  }
  .grid-margin-y > .small-4 {
    height: 33.33333%;
  }
  .grid-margin-y > .small-5 {
    height: 41.66667%;
  }
  .grid-margin-y > .small-6 {
    height: 50%;
  }
  .grid-margin-y > .small-7 {
    height: 58.33333%;
  }
  .grid-margin-y > .small-8 {
    height: 66.66667%;
  }
  .grid-margin-y > .small-9 {
    height: 75%;
  }
  .grid-margin-y > .small-10 {
    height: 83.33333%;
  }
  .grid-margin-y > .small-11 {
    height: 91.66667%;
  }
  .grid-margin-y > .small-12 {
    height: 100%;
  }
  .grid-margin-y > .medium-auto {
    height: auto;
  }
  .grid-margin-y > .medium-shrink {
    height: auto;
  }
  .grid-margin-y > .medium-1 {
    height: 8.33333%;
  }
  .grid-margin-y > .medium-2 {
    height: 16.66667%;
  }
  .grid-margin-y > .medium-3 {
    height: 25%;
  }
  .grid-margin-y > .medium-4 {
    height: 33.33333%;
  }
  .grid-margin-y > .medium-5 {
    height: 41.66667%;
  }
  .grid-margin-y > .medium-6 {
    height: 50%;
  }
  .grid-margin-y > .medium-7 {
    height: 58.33333%;
  }
  .grid-margin-y > .medium-8 {
    height: 66.66667%;
  }
  .grid-margin-y > .medium-9 {
    height: 75%;
  }
  .grid-margin-y > .medium-10 {
    height: 83.33333%;
  }
  .grid-margin-y > .medium-11 {
    height: 91.66667%;
  }
  .grid-margin-y > .medium-12 {
    height: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y > .large-auto {
    height: auto;
  }
  .grid-margin-y > .large-shrink {
    height: auto;
  }
  .grid-margin-y > .large-1 {
    height: 8.33333%;
  }
  .grid-margin-y > .large-2 {
    height: 16.66667%;
  }
  .grid-margin-y > .large-3 {
    height: 25%;
  }
  .grid-margin-y > .large-4 {
    height: 33.33333%;
  }
  .grid-margin-y > .large-5 {
    height: 41.66667%;
  }
  .grid-margin-y > .large-6 {
    height: 50%;
  }
  .grid-margin-y > .large-7 {
    height: 58.33333%;
  }
  .grid-margin-y > .large-8 {
    height: 66.66667%;
  }
  .grid-margin-y > .large-9 {
    height: 75%;
  }
  .grid-margin-y > .large-10 {
    height: 83.33333%;
  }
  .grid-margin-y > .large-11 {
    height: 91.66667%;
  }
  .grid-margin-y > .large-12 {
    height: 100%;
  }
}

.grid-frame.grid-margin-y {
  height: calc(100vh + 0.625rem);
}

@media print, screen and (min-width: 46.875em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 0);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-frame.grid-margin-y {
    height: calc(100vh + 0);
  }
}

@media print, screen and (min-width: 46.875em) {
  .grid-margin-y.medium-grid-frame {
    height: calc(100vh + 0);
  }
}

@media print, screen and (min-width: 64em) {
  .grid-margin-y.large-grid-frame {
    height: calc(100vh + 0);
  }
}

.responsive-embed,
.flex-video {
  position: relative;
  height: 0;
  margin-bottom: 1rem;
  padding-bottom: 75%;
  overflow: hidden;
}

.responsive-embed iframe,
.responsive-embed object,
.responsive-embed embed,
.responsive-embed video,
.flex-video iframe,
.flex-video object,
.flex-video embed,
.flex-video video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-embed.widescreen,
.flex-video.widescreen {
  padding-bottom: 56.25%;
}

.align-left {
  justify-content: flex-start;
}

.align-right {
  justify-content: flex-end;
}

.align-center {
  justify-content: center;
}

.align-justify {
  justify-content: space-between;
}

.align-spaced {
  justify-content: space-around;
}

.align-left.vertical.menu > li > a {
  justify-content: flex-start;
}

.align-right.vertical.menu > li > a {
  justify-content: flex-end;
}

.align-center.vertical.menu > li > a {
  justify-content: center;
}

.align-top {
  align-items: flex-start;
}

.align-self-top {
  align-self: flex-start;
}

.align-bottom {
  align-items: flex-end;
}

.align-self-bottom {
  align-self: flex-end;
}

.align-middle {
  align-items: center;
}

.align-self-middle {
  align-self: center;
}

.align-stretch {
  align-items: stretch;
}

.align-self-stretch {
  align-self: stretch;
}

.align-center-middle {
  justify-content: center;
  align-items: center;
  align-content: center;
}

.small-order-1 {
  order: 1;
}

.small-order-2 {
  order: 2;
}

.small-order-3 {
  order: 3;
}

.small-order-4 {
  order: 4;
}

.small-order-5 {
  order: 5;
}

.small-order-6 {
  order: 6;
}

@media print, screen and (min-width: 46.875em) {
  .medium-order-1 {
    order: 1;
  }
  .medium-order-2 {
    order: 2;
  }
  .medium-order-3 {
    order: 3;
  }
  .medium-order-4 {
    order: 4;
  }
  .medium-order-5 {
    order: 5;
  }
  .medium-order-6 {
    order: 6;
  }
}

@media print, screen and (min-width: 64em) {
  .large-order-1 {
    order: 1;
  }
  .large-order-2 {
    order: 2;
  }
  .large-order-3 {
    order: 3;
  }
  .large-order-4 {
    order: 4;
  }
  .large-order-5 {
    order: 5;
  }
  .large-order-6 {
    order: 6;
  }
}

.flex-container {
  display: flex;
}

.flex-child-auto {
  flex: 1 1 auto;
}

.flex-child-grow {
  flex: 1 0 auto;
}

.flex-child-shrink {
  flex: 0 1 auto;
}

.flex-dir-row {
  flex-direction: row;
}

.flex-dir-row-reverse {
  flex-direction: row-reverse;
}

.flex-dir-column {
  flex-direction: column;
}

.flex-dir-column-reverse {
  flex-direction: column-reverse;
}

@media print, screen and (min-width: 46.875em) {
  .medium-flex-container {
    display: flex;
  }
  .medium-flex-child-auto {
    flex: 1 1 auto;
  }
  .medium-flex-child-grow {
    flex: 1 0 auto;
  }
  .medium-flex-child-shrink {
    flex: 0 1 auto;
  }
  .medium-flex-dir-row {
    flex-direction: row;
  }
  .medium-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .medium-flex-dir-column {
    flex-direction: column;
  }
  .medium-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

@media print, screen and (min-width: 64em) {
  .large-flex-container {
    display: flex;
  }
  .large-flex-child-auto {
    flex: 1 1 auto;
  }
  .large-flex-child-grow {
    flex: 1 0 auto;
  }
  .large-flex-child-shrink {
    flex: 0 1 auto;
  }
  .large-flex-dir-row {
    flex-direction: row;
  }
  .large-flex-dir-row-reverse {
    flex-direction: row-reverse;
  }
  .large-flex-dir-column {
    flex-direction: column;
  }
  .large-flex-dir-column-reverse {
    flex-direction: column-reverse;
  }
}

.hide {
  display: none !important;
}

.invisible {
  visibility: hidden;
}

@media screen and (max-width: 46.87375em) {
  .hide-for-small-only {
    display: none !important;
  }
}

@media screen and (max-width: 0em), screen and (min-width: 46.875em) {
  .show-for-small-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 46.875em) {
  .hide-for-medium {
    display: none !important;
  }
}

@media screen and (max-width: 46.87375em) {
  .show-for-medium {
    display: none !important;
  }
}

@media screen and (min-width: 46.875em) and (max-width: 63.99875em) {
  .hide-for-medium-only {
    display: none !important;
  }
}

@media screen and (max-width: 46.87375em), screen and (min-width: 64em) {
  .show-for-medium-only {
    display: none !important;
  }
}

@media print, screen and (min-width: 64em) {
  .hide-for-large {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em) {
  .show-for-large {
    display: none !important;
  }
}

@media screen and (min-width: 64em) and (max-width: 104.99875em) {
  .hide-for-large-only {
    display: none !important;
  }
}

@media screen and (max-width: 63.99875em), screen and (min-width: 105em) {
  .show-for-large-only {
    display: none !important;
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute !important;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.show-on-focus:active, .show-on-focus:focus {
  position: static !important;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.show-for-landscape,
.hide-for-portrait {
  display: block !important;
}

@media screen and (orientation: landscape) {
  .show-for-landscape,
  .hide-for-portrait {
    display: block !important;
  }
}

@media screen and (orientation: portrait) {
  .show-for-landscape,
  .hide-for-portrait {
    display: none !important;
  }
}

.hide-for-landscape,
.show-for-portrait {
  display: none !important;
}

@media screen and (orientation: landscape) {
  .hide-for-landscape,
  .show-for-portrait {
    display: none !important;
  }
}

@media screen and (orientation: portrait) {
  .hide-for-landscape,
  .show-for-portrait {
    display: block !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-center {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.clearfix::before, .clearfix::after {
  display: table;
  content: ' ';
  flex-basis: 0;
  order: 1;
}

.clearfix::after {
  clear: both;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

@media print, screen and (min-width: 46.875em) {
  .medium-text-left {
    text-align: left;
  }
  .medium-text-right {
    text-align: right;
  }
  .medium-text-center {
    text-align: center;
  }
  .medium-text-justify {
    text-align: justify;
  }
}

@media print, screen and (min-width: 64em) {
  .large-text-left {
    text-align: left;
  }
  .large-text-right {
    text-align: right;
  }
  .large-text-center {
    text-align: center;
  }
  .large-text-justify {
    text-align: justify;
  }
}

@media screen and (min-width: 46.875em) {
  .medium-align-left {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 64em) {
  .large-align-left {
    justify-content: flex-start;
  }
}

.page-header {
  position: relative;
  background-color: #17403b;
}

.page-header:before {
  content: "";
  background: transparent linear-gradient(0deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  position: absolute;
  top: 0;
  height: 30%;
  width: 100%;
  opacity: 0.8;
}

.page-header:after {
  content: "";
  background: transparent linear-gradient(180deg, #13575703 0%, #12304a5c 100%) 0% 0% no-repeat padding-box;
  mix-blend-mode: multiply;
  opacity: 0.8;
  position: absolute;
  bottom: 0;
  height: 30%;
  width: 100%;
}

.section--full, .topic-entry__square, .topic__box, .next-page-teaser, .project-box, .slider--subpage.slider--subpage-filled {
  background-color: rgba(235, 180, 58, 0.1);
}

.topic--dark-blue .section--full, .topic--dark-blue .topic-entry__square, .topic--dark-blue .topic__box, .topic--dark-blue .next-page-teaser, .topic--dark-blue .project-box, .topic--dark-blue .slider--subpage.slider--subpage-filled {
  background-color: rgba(38, 65, 143, 0.06);
}

.topic--light-blue .section--full, .topic--light-blue .topic-entry__square, .topic--light-blue .topic__box, .topic--light-blue .next-page-teaser, .topic--light-blue .project-box, .topic--light-blue .slider--subpage.slider--subpage-filled {
  background-color: rgba(97, 151, 192, 0.06);
}

.topic--dark-red .section--full, .topic--dark-red .topic-entry__square, .topic--dark-red .topic__box, .topic--dark-red .next-page-teaser, .topic--dark-red .project-box, .topic--dark-red .slider--subpage.slider--subpage-filled {
  background-color: rgba(75, 16, 31, 0.06);
}

.topic--dark-pink .section--full, .topic--dark-pink .topic-entry__square, .topic--dark-pink .topic__box, .topic--dark-pink .next-page-teaser, .topic--dark-pink .project-box, .topic--dark-pink .slider--subpage.slider--subpage-filled {
  background-color: rgba(208, 144, 160, 0.06);
}

.topic--vivid-red .section--full, .topic--vivid-red .topic-entry__square, .topic--vivid-red .topic__box, .topic--vivid-red .next-page-teaser, .topic--vivid-red .project-box, .topic--vivid-red .slider--subpage.slider--subpage-filled {
  background-color: rgba(228, 71, 21, 0.06);
}

.topic--light-red .section--full, .topic--light-red .topic-entry__square, .topic--light-red .topic__box, .topic--light-red .next-page-teaser, .topic--light-red .project-box, .topic--light-red .slider--subpage.slider--subpage-filled {
  background-color: rgba(143, 0, 61, 0.06);
}

.anchor-item__rect, .link-item__rect, .section--full.section--full-cover, .navigation--trigger-icon.trigger-icon--theme, .navigation--trigger-icon.trigger-icon--theme:after, .navigation--trigger-icon.trigger-icon--theme:before, .appendix-box, .chart-image__thumb a:hover, .topic-breadcrumb--link.active, .media-slider__thumbs a[data-thumb][data-current] {
  background-color: #ebb43a;
  color: #ffffff;
}

.topic--dark-blue .anchor-item__rect, .topic--dark-blue .link-item__rect, .topic--dark-blue .section--full.section--full-cover, .topic--dark-blue .navigation--trigger-icon.trigger-icon--theme, .topic--dark-blue .navigation--trigger-icon.trigger-icon--theme:after, .topic--dark-blue .navigation--trigger-icon.trigger-icon--theme:before, .topic--dark-blue .appendix-box, .topic--dark-blue .chart-image__thumb a:hover, .chart-image__thumb .topic--dark-blue a:hover, .topic--dark-blue .topic-breadcrumb--link.active, .topic--dark-blue .media-slider__thumbs a[data-thumb][data-current], .media-slider__thumbs .topic--dark-blue a[data-thumb][data-current] {
  background-color: #26418f;
}

.topic--light-blue .anchor-item__rect, .topic--light-blue .link-item__rect, .topic--light-blue .section--full.section--full-cover, .topic--light-blue .navigation--trigger-icon.trigger-icon--theme, .topic--light-blue .navigation--trigger-icon.trigger-icon--theme:after, .topic--light-blue .navigation--trigger-icon.trigger-icon--theme:before, .topic--light-blue .appendix-box, .topic--light-blue .chart-image__thumb a:hover, .chart-image__thumb .topic--light-blue a:hover, .topic--light-blue .topic-breadcrumb--link.active, .topic--light-blue .media-slider__thumbs a[data-thumb][data-current], .media-slider__thumbs .topic--light-blue a[data-thumb][data-current] {
  background-color: #6197c0;
}

.topic--dark-red .anchor-item__rect, .topic--dark-red .link-item__rect, .topic--dark-red .section--full.section--full-cover, .topic--dark-red .navigation--trigger-icon.trigger-icon--theme, .topic--dark-red .navigation--trigger-icon.trigger-icon--theme:after, .topic--dark-red .navigation--trigger-icon.trigger-icon--theme:before, .topic--dark-red .appendix-box, .topic--dark-red .chart-image__thumb a:hover, .chart-image__thumb .topic--dark-red a:hover, .topic--dark-red .topic-breadcrumb--link.active, .topic--dark-red .media-slider__thumbs a[data-thumb][data-current], .media-slider__thumbs .topic--dark-red a[data-thumb][data-current] {
  background-color: #4b101f;
}

.topic--dark-pink .anchor-item__rect, .topic--dark-pink .link-item__rect, .topic--dark-pink .section--full.section--full-cover, .topic--dark-pink .navigation--trigger-icon.trigger-icon--theme, .topic--dark-pink .navigation--trigger-icon.trigger-icon--theme:after, .topic--dark-pink .navigation--trigger-icon.trigger-icon--theme:before, .topic--dark-pink .appendix-box, .topic--dark-pink .chart-image__thumb a:hover, .chart-image__thumb .topic--dark-pink a:hover, .topic--dark-pink .topic-breadcrumb--link.active, .topic--dark-pink .media-slider__thumbs a[data-thumb][data-current], .media-slider__thumbs .topic--dark-pink a[data-thumb][data-current] {
  background-color: #d090a0;
}

.topic--vivid-red .anchor-item__rect, .topic--vivid-red .link-item__rect, .topic--vivid-red .section--full.section--full-cover, .topic--vivid-red .navigation--trigger-icon.trigger-icon--theme, .topic--vivid-red .navigation--trigger-icon.trigger-icon--theme:after, .topic--vivid-red .navigation--trigger-icon.trigger-icon--theme:before, .topic--vivid-red .appendix-box, .topic--vivid-red .chart-image__thumb a:hover, .chart-image__thumb .topic--vivid-red a:hover, .topic--vivid-red .topic-breadcrumb--link.active, .topic--vivid-red .media-slider__thumbs a[data-thumb][data-current], .media-slider__thumbs .topic--vivid-red a[data-thumb][data-current] {
  background-color: #e44715;
}

.topic--light-red .anchor-item__rect, .topic--light-red .link-item__rect, .topic--light-red .section--full.section--full-cover, .topic--light-red .navigation--trigger-icon.trigger-icon--theme, .topic--light-red .navigation--trigger-icon.trigger-icon--theme:after, .topic--light-red .navigation--trigger-icon.trigger-icon--theme:before, .topic--light-red .appendix-box, .topic--light-red .chart-image__thumb a:hover, .chart-image__thumb .topic--light-red a:hover, .topic--light-red .topic-breadcrumb--link.active, .topic--light-red .media-slider__thumbs a[data-thumb][data-current], .media-slider__thumbs .topic--light-red a[data-thumb][data-current] {
  background-color: #8f003d;
}

@media screen and (max-width: 46.87375em) {
  .slider {
    background-color: rgba(235, 180, 58, 0.1);
  }
  .topic--dark-blue .slider {
    background-color: rgba(38, 65, 143, 0.06);
  }
  .topic--light-blue .slider {
    background-color: rgba(97, 151, 192, 0.06);
  }
  .topic--dark-red .slider {
    background-color: rgba(75, 16, 31, 0.06);
  }
  .topic--dark-pink .slider {
    background-color: rgba(208, 144, 160, 0.06);
  }
  .topic--vivid-red .slider {
    background-color: rgba(228, 71, 21, 0.06);
  }
  .topic--light-red .slider {
    background-color: rgba(143, 0, 61, 0.06);
  }
}

.navigation--trigger-icon--circle:after, .project-teaser {
  border-color: #ebb43a;
}

.topic--dark-blue .navigation--trigger-icon--circle:after, .topic--dark-blue .project-teaser {
  border-color: #26418f;
}

.topic--light-blue .navigation--trigger-icon--circle:after, .topic--light-blue .project-teaser {
  border-color: #6197c0;
}

.topic--dark-red .navigation--trigger-icon--circle:after, .topic--dark-red .project-teaser {
  border-color: #4b101f;
}

.topic--dark-pink .navigation--trigger-icon--circle:after, .topic--dark-pink .project-teaser {
  border-color: #d090a0;
}

.topic--vivid-red .navigation--trigger-icon--circle:after, .topic--vivid-red .project-teaser {
  border-color: #e44715;
}

.topic--light-red .navigation--trigger-icon--circle:after, .topic--light-red .project-teaser {
  border-color: #8f003d;
}

.plugin__richtext a:not(.link-item), .font--theme-color, .link-item:hover:not(.no-hover) .link-item__text, .link-item--hover:hover .link-item__text, .breadcrumb__link, .factslide a:not(.link-item), .project-teaser:hover .project-teaser__title {
  color: #ebb43a;
}

.topic--dark-blue .plugin__richtext a:not(.link-item), .plugin__richtext .topic--dark-blue a:not(.link-item), .topic--dark-blue .font--theme-color, .topic--dark-blue .link-item:hover:not(.no-hover) .link-item__text, .link-item:hover:not(.no-hover) .topic--dark-blue .link-item__text, .topic--dark-blue .link-item--hover:hover .link-item__text, .link-item--hover:hover .topic--dark-blue .link-item__text, .topic--dark-blue .breadcrumb__link, .topic--dark-blue .factslide a:not(.link-item), .factslide .topic--dark-blue a:not(.link-item), .topic--dark-blue .project-teaser:hover .project-teaser__title, .project-teaser:hover .topic--dark-blue .project-teaser__title {
  color: #26418f;
}

.topic--light-blue .plugin__richtext a:not(.link-item), .plugin__richtext .topic--light-blue a:not(.link-item), .topic--light-blue .font--theme-color, .topic--light-blue .link-item:hover:not(.no-hover) .link-item__text, .link-item:hover:not(.no-hover) .topic--light-blue .link-item__text, .topic--light-blue .link-item--hover:hover .link-item__text, .link-item--hover:hover .topic--light-blue .link-item__text, .topic--light-blue .breadcrumb__link, .topic--light-blue .factslide a:not(.link-item), .factslide .topic--light-blue a:not(.link-item), .topic--light-blue .project-teaser:hover .project-teaser__title, .project-teaser:hover .topic--light-blue .project-teaser__title {
  color: #6197c0;
}

.topic--dark-red .plugin__richtext a:not(.link-item), .plugin__richtext .topic--dark-red a:not(.link-item), .topic--dark-red .font--theme-color, .topic--dark-red .link-item:hover:not(.no-hover) .link-item__text, .link-item:hover:not(.no-hover) .topic--dark-red .link-item__text, .topic--dark-red .link-item--hover:hover .link-item__text, .link-item--hover:hover .topic--dark-red .link-item__text, .topic--dark-red .breadcrumb__link, .topic--dark-red .factslide a:not(.link-item), .factslide .topic--dark-red a:not(.link-item), .topic--dark-red .project-teaser:hover .project-teaser__title, .project-teaser:hover .topic--dark-red .project-teaser__title {
  color: #4b101f;
}

.topic--dark-pink .plugin__richtext a:not(.link-item), .plugin__richtext .topic--dark-pink a:not(.link-item), .topic--dark-pink .font--theme-color, .topic--dark-pink .link-item:hover:not(.no-hover) .link-item__text, .link-item:hover:not(.no-hover) .topic--dark-pink .link-item__text, .topic--dark-pink .link-item--hover:hover .link-item__text, .link-item--hover:hover .topic--dark-pink .link-item__text, .topic--dark-pink .breadcrumb__link, .topic--dark-pink .factslide a:not(.link-item), .factslide .topic--dark-pink a:not(.link-item), .topic--dark-pink .project-teaser:hover .project-teaser__title, .project-teaser:hover .topic--dark-pink .project-teaser__title {
  color: #d090a0;
}

.topic--vivid-red .plugin__richtext a:not(.link-item), .plugin__richtext .topic--vivid-red a:not(.link-item), .topic--vivid-red .font--theme-color, .topic--vivid-red .link-item:hover:not(.no-hover) .link-item__text, .link-item:hover:not(.no-hover) .topic--vivid-red .link-item__text, .topic--vivid-red .link-item--hover:hover .link-item__text, .link-item--hover:hover .topic--vivid-red .link-item__text, .topic--vivid-red .breadcrumb__link, .topic--vivid-red .factslide a:not(.link-item), .factslide .topic--vivid-red a:not(.link-item), .topic--vivid-red .project-teaser:hover .project-teaser__title, .project-teaser:hover .topic--vivid-red .project-teaser__title {
  color: #e44715;
}

.topic--light-red .plugin__richtext a:not(.link-item), .plugin__richtext .topic--light-red a:not(.link-item), .topic--light-red .font--theme-color, .topic--light-red .link-item:hover:not(.no-hover) .link-item__text, .link-item:hover:not(.no-hover) .topic--light-red .link-item__text, .topic--light-red .link-item--hover:hover .link-item__text, .link-item--hover:hover .topic--light-red .link-item__text, .topic--light-red .breadcrumb__link, .topic--light-red .factslide a:not(.link-item), .factslide .topic--light-red a:not(.link-item), .topic--light-red .project-teaser:hover .project-teaser__title, .project-teaser:hover .topic--light-red .project-teaser__title {
  color: #8f003d;
}

.chart-thumbnail-svg > path, .download--icon svg polygon, .media-slider__go-wrapper--testimonials .svg-bg-theme-color {
  fill: #ebb43a !important;
}

.topic--dark-blue .chart-thumbnail-svg > path, .topic--dark-blue .download--icon svg polygon, .download--icon svg .topic--dark-blue polygon, .topic--dark-blue .media-slider__go-wrapper--testimonials .svg-bg-theme-color, .media-slider__go-wrapper--testimonials .topic--dark-blue .svg-bg-theme-color {
  fill: #26418f !important;
}

.topic--light-blue .chart-thumbnail-svg > path, .topic--light-blue .download--icon svg polygon, .download--icon svg .topic--light-blue polygon, .topic--light-blue .media-slider__go-wrapper--testimonials .svg-bg-theme-color, .media-slider__go-wrapper--testimonials .topic--light-blue .svg-bg-theme-color {
  fill: #6197c0 !important;
}

.topic--dark-red .chart-thumbnail-svg > path, .topic--dark-red .download--icon svg polygon, .download--icon svg .topic--dark-red polygon, .topic--dark-red .media-slider__go-wrapper--testimonials .svg-bg-theme-color, .media-slider__go-wrapper--testimonials .topic--dark-red .svg-bg-theme-color {
  fill: #4b101f !important;
}

.topic--dark-pink .chart-thumbnail-svg > path, .topic--dark-pink .download--icon svg polygon, .download--icon svg .topic--dark-pink polygon, .topic--dark-pink .media-slider__go-wrapper--testimonials .svg-bg-theme-color, .media-slider__go-wrapper--testimonials .topic--dark-pink .svg-bg-theme-color {
  fill: #d090a0 !important;
}

.topic--vivid-red .chart-thumbnail-svg > path, .topic--vivid-red .download--icon svg polygon, .download--icon svg .topic--vivid-red polygon, .topic--vivid-red .media-slider__go-wrapper--testimonials .svg-bg-theme-color, .media-slider__go-wrapper--testimonials .topic--vivid-red .svg-bg-theme-color {
  fill: #e44715 !important;
}

.topic--light-red .chart-thumbnail-svg > path, .topic--light-red .download--icon svg polygon, .download--icon svg .topic--light-red polygon, .topic--light-red .media-slider__go-wrapper--testimonials .svg-bg-theme-color, .media-slider__go-wrapper--testimonials .topic--light-red .svg-bg-theme-color {
  fill: #8f003d !important;
}

.breadcrumb__link .breadcrumb__link--arrow svg path, .svg-stroke-theme-color {
  stroke: #ebb43a !important;
}

.topic--dark-blue .breadcrumb__link .breadcrumb__link--arrow svg path, .breadcrumb__link .breadcrumb__link--arrow svg .topic--dark-blue path, .topic--dark-blue .svg-stroke-theme-color {
  stroke: #26418f !important;
}

.topic--light-blue .breadcrumb__link .breadcrumb__link--arrow svg path, .breadcrumb__link .breadcrumb__link--arrow svg .topic--light-blue path, .topic--light-blue .svg-stroke-theme-color {
  stroke: #6197c0 !important;
}

.topic--dark-red .breadcrumb__link .breadcrumb__link--arrow svg path, .breadcrumb__link .breadcrumb__link--arrow svg .topic--dark-red path, .topic--dark-red .svg-stroke-theme-color {
  stroke: #4b101f !important;
}

.topic--dark-pink .breadcrumb__link .breadcrumb__link--arrow svg path, .breadcrumb__link .breadcrumb__link--arrow svg .topic--dark-pink path, .topic--dark-pink .svg-stroke-theme-color {
  stroke: #d090a0 !important;
}

.topic--vivid-red .breadcrumb__link .breadcrumb__link--arrow svg path, .breadcrumb__link .breadcrumb__link--arrow svg .topic--vivid-red path, .topic--vivid-red .svg-stroke-theme-color {
  stroke: #e44715 !important;
}

.topic--light-red .breadcrumb__link .breadcrumb__link--arrow svg path, .breadcrumb__link .breadcrumb__link--arrow svg .topic--light-red path, .topic--light-red .svg-stroke-theme-color {
  stroke: #8f003d !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
br,
p,
i,
a,
label,
blockquote,
figure,
div,
ol,
input,
pre,
sub,
span,
em,
button,
img,
strong,
li,
ul {
  margin: 0;
  padding: 0;
}

h1::selection,
h2::selection,
h3::selection,
h4::selection,
h5::selection,
h6::selection,
br::selection,
p::selection,
i::selection,
a::selection,
label::selection,
blockquote::selection,
figure::selection,
div::selection,
ol::selection,
input::selection,
pre::selection,
sub::selection,
span::selection,
em::selection,
button::selection,
img::selection,
strong::selection,
li::selection,
ul::selection {
  background: #ebb43a;
  color: black;
}

h1.text--yellow::selection, h1.text--yellow-darken::selection,
h2.text--yellow::selection,
h2.text--yellow-darken::selection,
h3.text--yellow::selection,
h3.text--yellow-darken::selection,
h4.text--yellow::selection,
h4.text--yellow-darken::selection,
h5.text--yellow::selection,
h5.text--yellow-darken::selection,
h6.text--yellow::selection,
h6.text--yellow-darken::selection,
br.text--yellow::selection,
br.text--yellow-darken::selection,
p.text--yellow::selection,
p.text--yellow-darken::selection,
i.text--yellow::selection,
i.text--yellow-darken::selection,
a.text--yellow::selection,
a.text--yellow-darken::selection,
label.text--yellow::selection,
label.text--yellow-darken::selection,
blockquote.text--yellow::selection,
blockquote.text--yellow-darken::selection,
figure.text--yellow::selection,
figure.text--yellow-darken::selection,
div.text--yellow::selection,
div.text--yellow-darken::selection,
ol.text--yellow::selection,
ol.text--yellow-darken::selection,
input.text--yellow::selection,
input.text--yellow-darken::selection,
pre.text--yellow::selection,
pre.text--yellow-darken::selection,
sub.text--yellow::selection,
sub.text--yellow-darken::selection,
span.text--yellow::selection,
span.text--yellow-darken::selection,
em.text--yellow::selection,
em.text--yellow-darken::selection,
button.text--yellow::selection,
button.text--yellow-darken::selection,
img.text--yellow::selection,
img.text--yellow-darken::selection,
strong.text--yellow::selection,
strong.text--yellow-darken::selection,
li.text--yellow::selection,
li.text--yellow-darken::selection,
ul.text--yellow::selection,
ul.text--yellow-darken::selection {
  background: #ebb43a;
}

@font-face {
  font-family: "Helvetica Neue LT W05_55 Roman";
  src: url("Fonts/08b57253-2e0d-4c12-9c57-107f6c67bc49.woff2") format("woff2"), url("Fonts/08edde9d-c27b-4731-a27f-d6cd9b01cd06.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue LT W05_65 Medium";
  src: url("Fonts/240c57a0-fdce-440d-9ce3-85e0cb56f470.woff2") format("woff2"), url("Fonts/7802e576-2ffa-4f22-a409-534355fbea79.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue LT W05_75 Bold";
  src: url("Fonts/800da3b0-675f-465f-892d-d76cecbdd5b1.woff2") format("woff2"), url("Fonts/7b415a05-784a-4a4c-8c94-67e9288312f5.woff") format("woff");
}

@font-face {
  font-family: "Helvetica Neue LT W05_77 Bd Cn";
  src: url("Fonts/02980b3d-1e37-4378-a5c6-4cf0d6ce0ba5.woff2") format("woff2"), url("Fonts/2d1e8a5f-eb7a-47a1-be1d-878bb9a46b6e.woff") format("woff");
}

h1,
.h1 {
  font-size: 11vw;
}

@media screen and (min-width: 46.875em) {
  h1,
  .h1 {
    font-size: 6.6vw;
  }
}

@media screen and (min-width: 64em) {
  h1,
  .h1 {
    font-size: 5.5vw;
  }
}

@media screen and (min-width: 105em) {
  h1,
  .h1 {
    font-size: 5.625rem;
  }
}

h2,
.h2 {
  font-size: 1.75rem;
}

@media screen and (min-width: 46.875em) {
  h2,
  .h2 {
    font-size: 2rem;
  }
}

@media screen and (min-width: 64em) {
  h2,
  .h2 {
    font-size: 2.5rem;
  }
}

h3 {
  font-size: 1.6rem;
}

@media screen and (min-width: 46.875em) {
  h3 {
    font-size: 2rem;
  }
}

h4 {
  font-size: 1.225rem;
}

@media screen and (min-width: 46.875em) {
  h4 {
    font-size: 1.4rem;
  }
}

@media screen and (min-width: 64em) {
  h4 {
    font-size: 1.75rem;
  }
}

h5 {
  font-size: 1.0125rem;
}

@media screen and (min-width: 46.875em) {
  h5 {
    font-size: 1.125rem;
  }
}

.navigation__link {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  text-transform: uppercase;
}

.page-header__toptitle {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
}

h1,
h2,
h3,
h4,
h5 {
  margin-bottom: 1.5rem;
  font-family: "Helvetica Neue LT W05_75 Bold", sans-serif;
  line-height: 1.2;
}

h1,
.h1 {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
}

h1.heading--smaller,
.h1.heading--smaller {
  font-size: 11vw;
}

@media screen and (min-width: 46.875em) {
  h1.heading--smaller,
  .h1.heading--smaller {
    font-size: 6.6vw;
  }
}

@media screen and (min-width: 64em) {
  h1.heading--smaller,
  .h1.heading--smaller {
    font-size: 4.4vw;
  }
}

@media screen and (min-width: 105em) {
  h1.heading--smaller,
  .h1.heading--smaller {
    font-size: 4.95vw;
  }
}

h2,
.h2 {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  line-height: 1.2;
}

h2 a,
.h2 a {
  color: #202020 !important;
}

.plugin__richtext h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.page--home h3,
.overlay h3 {
  font-size: 1.4rem;
}

@media screen and (min-width: 46.875em) {
  .page--home h3,
  .overlay h3 {
    font-size: 1.6rem;
  }
}

@media screen and (min-width: 64em) {
  .page--home h3,
  .overlay h3 {
    font-size: 2rem;
  }
}

.factslide__content h3 {
  font-size: 1.6rem;
  margin-bottom: 1.5rem;
}

.home--title {
  margin-top: 2.5rem;
}

@media screen and (min-width: 105em) {
  .home--title {
    margin-bottom: 3.75rem;
  }
}

h4 {
  margin-bottom: 0.9rem;
}

h5 {
  margin-bottom: 0.9rem;
  margin-top: 0;
}

body {
  font-family: "Helvetica Neue LT W05_55 Roman", sans-serif;
  font-size: 0.9375rem;
  line-height: 1.6;
  color: #202020;
}

@media screen and (min-width: 64em) {
  body {
    font-size: 1.125rem;
  }
}

p,
form,
table {
  margin-bottom: 1.5rem;
}

ul,
ol {
  margin-bottom: 1.5rem;
  padding-left: 1rem;
}

input,
textarea,
select,
option {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-style: inherit;
  line-height: inherit;
  color: #202020;
}

strong {
  font-family: "Helvetica Neue LT W05_75 Bold", sans-serif;
}

address {
  font-style: normal;
}

.plugin__richtext sub {
  font-size: 60%;
}

.plugin__richtext ul,
.plugin__richtext ol {
  margin-left: 1.5em;
}

.plugin__richtext ul li,
.plugin__richtext ol li {
  padding-left: 0.2em;
}

.plugin__richtext ul li:not(:last-child),
.plugin__richtext ol li:not(:last-child) {
  margin-bottom: 0.5em;
}

.plugin__richtext h2,
.plugin__richtext h3,
.plugin__richtext h4 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.plugin__richtext h2:first-child,
.plugin__richtext h3:first-child,
.plugin__richtext h4:first-child {
  margin-top: 0;
}

@media print, screen and (min-width: 46.875em) {
  .plugin__richtext h2,
  .plugin__richtext h3,
  .plugin__richtext h4 {
    margin-top: 3rem;
  }
}

.plugin__richtext h2[style="text-align:center"],
.plugin__richtext h3[style="text-align:center"],
.plugin__richtext h4[style="text-align:center"] {
  max-width: 80%;
  margin: 3rem auto;
  line-height: 1.4;
}

@media print, screen and (min-width: 46.875em) {
  .plugin__richtext h2[style="text-align:center"],
  .plugin__richtext h3[style="text-align:center"],
  .plugin__richtext h4[style="text-align:center"] {
    margin: 3.75rem auto;
  }
}

.list {
  margin: 0;
  padding: 0;
}

.list.list--anchors {
  margin-top: 2.5rem;
}

.list li {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list li:not(:last-child) {
  margin-bottom: 1rem;
}

.text--white {
  color: #ffffff;
}

.text--gray {
  color: #a09f9f;
}

@media screen and (max-width: 46.87375em) {
  .home-teaser__box .text--gray {
    color: #535353;
  }
}

.text--black {
  color: #202020;
}

.text--margin {
  display: block;
  margin-bottom: 1.5rem;
}

.text--no-margin {
  margin-bottom: 0;
}

.text--oversized {
  font-size: 80px;
}

.text--large {
  line-height: normal;
  font-size: 1.25rem;
}

@media print, screen and (min-width: 46.875em) {
  .text--large {
    font-size: 1.5625rem;
  }
}

.text--medium {
  font-size: 1.25rem;
}

.text--small {
  font-size: 0.9375rem;
}

.text--tiny {
  font-size: 0.875rem;
}

.text--ultra-large {
  font-size: 7.03125rem;
  line-height: 0;
}

@media screen and (min-width: 46.875em) {
  .text--ultra-large {
    font-size: 7.5rem;
  }
}

@media screen and (min-width: 64em) {
  .text--ultra-large {
    font-size: 9.375rem;
  }
}

.font--bold {
  font-family: "Helvetica Neue LT W05_75 Bold", sans-serif;
}

.font--semibold {
  font-family: "Helvetica Neue LT W05_65 Medium", sans-serif;
}

.text--yellow {
  color: #e5eb50;
}

.text--gold {
  color: #ebb43a;
}

.text--yellow-darken {
  color: #d1d64b;
}

.text--uppercase {
  text-transform: uppercase;
  letter-spacing: 1px;
}

.font--condensed {
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  line-height: normal;
}

.font--margin {
  margin-bottom: 1.5rem;
  display: block;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}

.back-link {
  margin-bottom: 1.5rem;
}

.back-link a img {
  transform: rotate(180deg);
}

.text--marked {
  background-repeat: no-repeat;
  text-shadow: none;
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  padding: 0.1rem 0.5rem;
  line-height: 1.6;
  background: #e5eb50;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 60%, #e5eb50 60%, #e5eb50 100%);
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
}

.anchor-item {
  margin-top: 2.5rem;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.anchor-item__rect {
  position: relative;
  height: 8px;
  margin-left: 1rem;
  width: 120px;
}

.button,
input[type="submit"] {
  display: inline-block;
  color: #202020;
  background: #ebb43a;
  font-weight: 700;
  padding: 0.5rem 2rem;
  text-align: center;
  font-family: "Helvetica Neue LT W05_77 Bd Cn", sans-serif;
  transition: all 0.15s ease-in-out;
}

.button:hover,
input[type="submit"]:hover {
  background-color: #dc9e16;
}

.button.button--large,
input[type="submit"].button--large {
  font-size: 1.125rem;
}

.button.button--secondary,
input[type="submit"].button--secondary {
  border-color: #120d34;
  border: 3px solid #120d34;
  color: #ffffff;
  position: relative;
  transition: transform 300ms cubic-bezier(0.215, 0.61, 0.355, 1);
  margin-top: 0.5rem;
  word-break: keep-all;
  white-space: nowrap;
}

.button.button--secondary:hover,
input[type="submit"].button--secondary:hover {
  color: #202020;
  background: #120d34;
}

.button.button--share,
input[type="submit"].button--share {
  padding: 0.4rem 2rem;
  width: 18rem;
}

.button.button--flex,
input[type="submit"].button--flex {
  display: flex;
  align-items: center;
}

.button.button--testimonial,
input[type="submit"].button--testimonial {
  padding: 0.5rem 2rem;
}

@media screen and (min-width: 46.875em) {
  .button.button--testimonial,
  input[type="submit"].button--testimonial {
    margin-bottom: 2rem;
  }
}

.button.button--nobreak,
input[type="submit"].button--nobreak {
  padding: 0.8rem 1.8rem;
  width: 100%;
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.button__block {
  margin: 1.25rem 0 2.5rem 0;
}

.button__block--center {
  width: 100%;
}

.button__block--center button {
  margin: 0 auto;
  display: block;
}

.download__icon {
  margin-left: 1rem;
}

.download__icon svg {
  width: 1rem;
  height: 100%;
  transform: translateY(2px);
}

@media print, screen and (min-width: 46.875em) {
  .download__icon svg {
    width: 1.25rem;
  }
}

.link-item {
  display: flex;
  align-items: center;
}

.appendix-box--projects .link-item {
  margin-left: auto;
  width: fit-content;
}

.link-item.link-item--small {
  width: fit-content;
}

.link-item--hover:hover .link-item__rect {
  transform: scaleX(1);
}

.link-item--hover:hover .link-item__text {
  transform: translateX(0);
}

@media screen and (max-width: 46.87375em) {
  .home-teaser__box .link-item--hover:hover .link-item__text {
    color: #202020;
  }
}

.link-item__rect {
  position: relative;
  width: 30px;
  height: 8px;
  margin-right: 1rem;
  transform-origin: left;
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.link-item--sleeping .link-item__rect {
  transform: scaleX(0);
  transition-delay: 0s;
  animation: none;
}

@media screen and (max-width: 46.87375em) {
  .home-teaser__box .link-item__rect {
    background-color: #202020;
  }
}

.link-item__rect.link-item__rect--white {
  background-color: #ffffff;
}

.link-item__text {
  display: block;
  transition: transform 0.4s cubic-bezier(0.25, 1, 0.5, 1), color 0.4s cubic-bezier(0.25, 1, 0.5, 1);
}

.link-item--sleeping .link-item__text {
  transform: translateX(-42px);
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  -webkit-overflow-scrolling: touch;
  background-color: #f8f8f8;
}

.main-content {
  height: 100%;
  flex: 1 0 auto;
  overflow: hidden;
}

.main-content.main--invert {
  background-color: #ffffff;
}

.page--home .main-content {
  background-color: #ffffff;
  margin-bottom: 0;
}

@media print, screen and (min-width: 46.875em) {
  .page--home .main-content {
    padding-top: 10vh;
  }
}

.page--topic .main-content {
  margin-bottom: 5rem;
}

.visuallyhidden {
  position: absolute;
  width: 1px;
  height: 1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(0 0 0 0);
  margin: -1px;
  padding: 0;
  border: 0;
}

.section {
  position: relative;
  padding: 2rem 0;
}

@media print, screen and (min-width: 46.875em) {
  .section {
    padding: 4rem 0;
  }
}

.section.section--no-paddings {
  padding-bottom: 0;
}

.section.slider--topics {
  padding-top: 0;
}

.subpage-entry--section {
  margin-top: 10rem;
}

@media print, screen and (min-width: 46.875em) {
  .subpage-entry--section {
    margin-top: 15rem;
  }
}

@media screen and (max-width: 46.87375em) {
  .align-center--small {
    justify-content: center;
  }
}

@media screen and (max-width: 63.99875em) {
  .align-center--medium {
    justify-content: center;
  }
}

.grid-container--margin {
  margin-bottom: 2.5rem;
}

@media screen and (min-width: 46.875em) {
  .grid-container--margin {
    margin-bottom: 3.75rem;
  }
}

.full.grid-container--margin {
  margin-bottom: 2.5rem;
}

@media screen and (min-width: 46.875em) {
  .full.grid-container--margin {
    margin-bottom: 3.75rem;
  }
}

.grid-container--margin-half {
  margin-bottom: 0.83333rem;
}

@media screen and (min-width: 46.875em) {
  .grid-container--margin-half {
    margin-bottom: 1.25rem;
  }
}

.breadcrumb__link {
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  line-height: 0;
}

.breadcrumb__link .breadcrumb__link--arrow {
  margin-right: 1rem;
}

.breadcrumb__link .breadcrumb__link--arrow svg {
  width: 8px;
}

.section--full {
  padding-top: 2.5rem;
  padding-bottom: 1px;
}

@media screen and (min-width: 46.875em) {
  .section--full {
    padding-top: 3.75rem;
  }
}

.section--full.section--full-teaser {
  padding: 5rem 0;
}

@media print, screen and (min-width: 46.875em) {
  .section--full.section--full-teaser {
    padding: 0;
  }
}

.show-for-medium-down {
  display: none;
}

@media screen and (max-width: 63.99875em) {
  .show-for-medium-down {
    display: block;
  }
}

@media screen and (max-width: 46.87375em) {
  .flex-ordering .flex-order--1 {
    order: 1;
  }
  .flex-ordering .flex-order--2 {
    order: 2;
  }
}

@media print, screen and (min-width: 46.875em) {
  .cell--right {
    margin-left: auto;
  }
}

@media print, screen and (min-width: 46.875em) {
  .cell--left {
    margin-right: auto;
  }
}

.margin-top {
  margin-top: 2.5rem;
}

.page--notfound {
  position: relative;
  width: 100%;
  height: 100vh;
}

.page--background-img {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}

.page--background-img:after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  background: rgba(19, 50, 45, 0.5);
  mix-blend-mode: overlay;
  z-index: 0;
}

.notfound-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  text-align: center;
  z-index: 100;
}

.notfound-container h1 {
  line-height: 0.9;
}

.notfound-container span {
  display: block;
  font-weight: 500;
}

.grid-vh100 {
  min-height: 100%;
}

@media screen and (min-width: 46.875em) {
  .grid-vh100 {
    min-height: 100vh;
  }
}

@media screen and (min-width: 64em) {
  .grid-vh100 {
    min-height: 100vh;
  }
}

@media print, screen and (min-width: 46.875em) {
  .grid-vh100 {
    padding-top: 10rem;
  }
}

.grid-vh80 {
  min-height: 100%;
}

@media screen and (min-width: 46.875em) {
  .grid-vh80 {
    min-height: 80vh;
  }
}

@media screen and (min-width: 64em) {
  .grid-vh80 {
    min-height: 80vh;
  }
}

.grid-vh90 {
  min-height: 100%;
}

@media screen and (min-width: 46.875em) {
  .grid-vh90 {
    min-height: 90vh;
  }
}

@media screen and (min-width: 64em) {
  .grid-vh90 {
    min-height: 90vh;
  }
}

.z10 {
  z-index: 10;
  position: relative;
}

.no-pointer-event {
  pointer-events: none;
  user-select: none;
}

@media print, screen and (min-width: 46.875em) {
  .align-middle {
    height: 1px;
  }
}

.debug-viewport {
  position: absolute;
  top: 0;
  right: 20rem;
  width: 10rem;
  height: 2rem;
  background-color: rgba(0, 0, 0, 0.3);
  color: white;
  text-align: center;
}

.icon {
  display: inline-block;
  font-size: 0;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  background-size: cover;
  background: no-repeat 50% 50%;
  margin-right: 0.75rem;
}

.icon.icon--small {
  width: 25px;
  height: 25px;
}

.icon.black {
  border-radius: 40px;
}

.icon-large {
  width: 50px;
  height: 50px;
}

.social.social {
  background-repeat: no-repeat;
  transition: all 0.2s ease;
}

.social.social.facebook {
  background-image: url("img/icons/facebook.svg");
}

.social.social.twitter {
  background-image: url("img/icons/twitter.svg");
}

.social.social.instagram {
  background-image: url("img/icons/instagram.svg");
}

.social.social.whatsapp {
  background-image: url("img/icons/whatsapp.svg");
}

.social.social.twitter-white {
  background-image: url("img/icons/twitter-white.svg");
}

.social.social.twitter-black {
  background-image: url("img/icons/twitter-black.svg");
}

.social.social.arrow-black-right {
  background-image: url("img/icons/arrow-black-right.svg");
}

.social.social.arrow_black {
  background-image: url("img/icons/arrow_black.svg");
}

.social.social.arrow-right-yellow {
  background-image: url("img/icons/arrow-right-yellow.svg");
}

.social.social.twitter-green {
  background-image: url("img/icons/twitter-green.svg");
}

.social.social.facebook-green {
  background-image: url("img/icons/facebook-green.svg");
}

.social.social.mail-green {
  background-image: url("img/icons/mail-green.svg");
}

.social.social:hover {
  opacity: 0.7;
  transition: all 0.2s ease;
}

.appear {
  opacity: 1;
}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes fadeInRight {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fadeOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30px);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.footer__main {
  background-color: #120d34;
  padding: 3rem 0 2rem 0;
}

.footer__meta {
  background-color: #120d34;
  padding-bottom: 2rem;
}

.footer__second {
  background-color: #ebb43a;
  padding: 0.25rem 0 0.4rem 0;
}

.footer__link-container {
  margin-bottom: 2.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .footer__link-container {
    margin-bottom: 0;
  }
}

.footer__link {
  border-top: 1px solid #ebb43a;
}

.footer__link:last-of-type {
  border-bottom: 1px solid #ebb43a;
}

.footer__link a {
  padding: 0.5rem 0;
  display: block;
}

.footer__link-container--contact {
  margin-top: 3rem;
}

@media print, screen and (min-width: 46.875em) {
  .footer__link-container--contact {
    margin-top: 0;
  }
}

.footer--logos {
  display: flex;
  align-items: center;
  height: 100%;
}

.footer--logo {
  display: block;
  margin-right: 2rem;
}

.footer--logo img {
  width: 20rem;
  display: block;
}

.footer--logo.footer--logo-small img {
  width: 10rem;
}

.header {
  background: transparent;
  top: 0;
  transition: all 0.4s ease-in-out;
  z-index: 1500;
  width: 100%;
  display: flex;
  justify-content: space-between;
  pointer-events: none;
}

.body--404 .header {
  filter: invert(1);
}

.fixed-header {
  position: absolute;
  left: 0;
  top: 0;
  padding: 1rem 1.7rem;
}

@media print, screen and (min-width: 46.875em) {
  .fixed-header {
    padding: 2rem 2rem;
  }
}

.sticky-header {
  position: fixed;
  right: 1rem;
  padding: 1rem 0.25rem;
}

@media print, screen and (min-width: 46.875em) {
  .sticky-header {
    padding: 2rem 1.2rem;
  }
}

.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  color: #ffffff;
}

.navigation__left {
  display: flex;
  flex-direction: column;
  transition: opacity 0.4s ease-in-out;
}

[data-overlay="true"] .navigation__left {
  opacity: 0;
}

.navigation__logo-item {
  display: block;
  max-width: 150px;
  pointer-events: all;
  transition: opacity 0.4s ease-in-out;
  opacity: 1;
}

.scrolled .navigation__logo-item {
  opacity: 0;
  pointer-events: none;
}

@media print, screen and (min-width: 46.875em) {
  .navigation__logo-item {
    width: 150px;
    position: relative;
  }
}

@media print, screen and (min-width: 64em) {
  .navigation__logo-item {
    width: 10vw;
    max-width: unset;
  }
}

.navigation__logo-item img {
  display: block;
  width: 100%;
}

.navigation__container {
  position: absolute;
  display: none;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  transition: transform 0.4s ease-in-out, opacity 200ms ease-in-out;
  z-index: 100;
}

.nav-is-open .navigation__container {
  opacity: 1;
  display: block;
  padding-bottom: 5rem;
  background: transparent;
}

.navigation__container--overlay {
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 63.99875em) {
  .navigation__container--overlay {
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
    padding-right: 0.3125rem;
    padding-left: 0.3125rem;
  }
}

.navigation__list {
  position: relative;
  transition: transform 0.4s ease-in-out;
  padding-left: 0;
}

.nav-is-open .navigation__list {
  opacity: 1;
  visibility: visible;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 64em) {
  .navigation__list {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    height: 100%;
  }
}

.rotate {
  transform: rotate(180deg);
}

.navigation__item {
  list-style-type: none;
  margin: 0;
}

.navigation__item.navigation__item--cte {
  text-align: left;
  margin-top: 0.5rem;
}

@media print, screen and (min-width: 64em) {
  .navigation__item {
    margin-bottom: 0.1rem;
    text-align: center;
    padding: 0;
    margin-left: 2rem;
  }
  .navigation__item.navigation__item--cte {
    display: none;
  }
  .navigation__item:last-child {
    padding-right: 1rem;
  }
}

.navigation__link {
  position: relative;
  display: block;
  color: #ffffff;
  opacity: 0;
  transition-delay: 500ms;
  width: fit-content;
  padding: 0.3rem 0 0.1rem;
  text-align: left;
}

.navigation__link:after {
  content: "•";
  position: absolute;
  bottom: -2.25rem;
  left: 50%;
  transform: translate(-50%, -50%);
  color: transparent;
  transition: all 0.15s ease-in-out;
}

.nav-is-open .navigation__link:after {
  position: absolute;
  bottom: -13px;
  left: -10px;
  margin-right: 1rem;
}

.nav-is-open .active .navigation__link, .nav-is-open .navigation__link:hover {
  left: 1rem;
}

.active .navigation__link:after, .navigation__link:hover:after {
  color: #e5eb50;
}

@media print, screen and (min-width: 64em) {
  .navigation__link {
    opacity: 1;
    transition: opacity 300ms ease-in-out;
    color: #ffffff;
    width: auto;
    position: relative;
    padding: 0 0.25rem;
  }
}

.nav-is-open .navigation__link {
  opacity: 1;
}

@keyframes paintLeft {
  from {
    opacity: 0;
    transform: translateX(5%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

.animate, .nav__trigger {
  transition: all 0.3s cubic-bezier(0.31, -0.105, 0.43, 1.59);
}

.nav__trigger {
  display: flex;
  align-items: center;
  position: relative;
  width: 35px;
  height: 35px;
  cursor: pointer;
  z-index: 1100;
  pointer-events: all;
}

[data-overlay-wrapper="true"] .nav__trigger {
  transition-delay: 0.2s;
  opacity: 1;
  transform: rotate(0);
}

.nav__icon {
  display: inline-block;
  position: relative;
  width: 35px;
  height: 3px;
  background-color: #202020;
}

.nav__icon:before, .nav__icon:after {
  content: "";
  display: block;
  width: 35px;
  height: 3px;
  position: absolute;
  background: #202020;
  transition-property: margin, transform, opacity;
  transition-duration: 300ms;
}

.nav__icon:before {
  margin-top: -10px;
}

.nav__icon:after {
  margin-top: 10px;
}

[data-overlay-toggle="close"] .nav__icon,
.close .nav__icon {
  background: none;
}

[data-overlay-toggle="close"] .nav__icon:before,
.close .nav__icon:before {
  margin-top: 0;
  transform: rotate(45deg);
}

[data-overlay-toggle="close"] .nav__icon:after,
.close .nav__icon:after {
  margin-top: 0;
  transform: rotate(-45deg);
}

.navigation__right {
  display: flex;
  justify-content: flex-end;
}

@media print, screen and (min-width: 46.875em) {
  [data-overlay="true"] .navigation__right {
    padding-right: 15px;
  }
}

.language-menu {
  position: relative;
  pointer-events: all;
  margin-right: 1.5rem;
  opacity: 0;
  width: 0;
}

[data-overlay="true"] .language-menu {
  pointer-events: all;
  opacity: 1;
  width: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .home-entry__teaser-text {
    margin-top: 2rem;
    margin-left: 6rem;
  }
}

@media screen and (min-width: 105em) {
  .home-entry__teaser-text {
    margin-top: 3rem;
  }
}

.home-entry--section {
  position: relative;
  margin-top: 10rem;
}

@media print, screen and (min-width: 46.875em) {
  .home-entry--section {
    margin-top: 0;
  }
}

.home-teaser__box {
  color: #202020;
  padding: 4vh 0 0 0;
}

.page--en .home-teaser__box {
  padding: 4vh 0 10vw 0;
}

@media print, screen and (min-width: 46.875em) {
  .home-teaser__box {
    padding: 8vh 3vw 0 3vw;
    background-color: #ffffff;
    transform: translateY(4rem);
  }
  .page--en .home-teaser__box {
    padding: 8vh 3vw 10vw 3vw;
  }
}

.page-teaser__button-wrapper {
  margin-top: 2rem;
}

@media print, screen and (min-width: 46.875em) {
  .page-teaser__button-wrapper {
    margin-top: 50vh;
  }
}

.page-teaser__button-wrapper a {
  display: block;
  margin-left: auto;
  width: fit-content;
}

.page-scroll__icon-wrapper {
  position: absolute;
  right: 15%;
  bottom: 5vh;
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s, transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
  transform: translateY(0);
  opacity: 1;
}

@media screen and (max-width: 63.99875em) {
  .page-scroll__icon-wrapper {
    display: none;
  }
}

.page-scroll__icon-wrapper .page-scroll__icon {
  height: 5rem;
  width: 5rem;
  display: block;
}

.scrolled .page-scroll__icon-wrapper {
  transform: translateY(-50px);
  opacity: 0;
}

body[data-overlay="true"] {
  overflow: hidden;
}

@media print, screen and (min-width: 46.875em) {
  body[data-overlay="true"] {
    padding-right: 15px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 1000;
  pointer-events: none;
}

.overlay:before {
  content: "";
  background: #eeeeeeb3;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 600ms cubic-bezier(0.41, 0.29, 0.18, 0.97);
}

[data-overlay="true"] .overlay:before, .overlay:before.active {
  opacity: 1;
}

[data-overlay="true"] .overlay {
  pointer-events: all;
}

.overlay__content {
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  right: 0;
  z-index: 1000;
  transform: translateX(100%);
  height: 100vh;
}

@media screen and (max-width: 46.87375em) {
  .overlay__content {
    overflow: scroll;
    height: 100%;
  }
}

@media print, screen and (min-width: 46.875em) {
  .overlay__content {
    width: 100%;
  }
}

@media print, screen and (min-width: 64em) {
  .overlay__content {
    width: 80vw;
  }
}

[data-overlay="true"] .overlay__content {
  z-index: 1000;
  transition: transform 0.8s cubic-bezier(0, 0, 0.175, 1);
}

[data-overlay="false"] .overlay__content {
  z-index: 1000;
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
}

[data-overlay-level="first"] .overlay__content {
  transform: translateX(0);
}

[data-overlay-level="second"] .overlay__content {
  transform: translateX(0);
}

@media print, screen and (min-width: 46.875em) {
  [data-overlay-level="second"] .overlay__content {
    transform: translateX(20%);
  }
}

@media print, screen and (min-width: 64em) {
  [data-overlay-level="second"] .overlay__content {
    transform: translateX(40%);
  }
}

.help--button {
  position: fixed;
  top: 50%;
  z-index: 1000;
  right: 50px;
  transform-origin: right;
  transform: rotate(-90deg) translate(50%, 0%);
}

.help--button a {
  display: block;
}

[data-overlay="true"] .help--button {
  right: 65px;
}

.overlay__content--container {
  display: flex;
  height: 100%;
}

@media screen and (max-width: 46.87375em) {
  .overlay__content--container {
    flex-direction: column;
    justify-content: space-between;
  }
}

.overlay__content--left-wrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-right: 2rem;
  padding: 100px 20px 0px 20px;
}

@media print, screen and (min-width: 46.875em) {
  .overlay__content--left-wrapper {
    height: 100%;
    padding: 80px 0 0 120px;
  }
}

@media print, screen and (min-width: 64em) {
  .overlay__content--left-wrapper {
    width: 50rem;
  }
}

@media screen and (min-width: 105em) {
  .overlay__content--left-wrapper {
    padding: 140px 0 0 120px;
  }
}

.overlay__content--left-wrapper[data-overlay-content-level="first"] {
  display: none;
}

[data-overlay-level="first"] .overlay__content--left-wrapper[data-overlay-content-level="first"] {
  display: flex;
}

.overlay__content--left-wrapper[data-overlay-content-level="second"] {
  display: none;
}

[data-overlay-level="second"] .overlay__content--left-wrapper[data-overlay-content-level="second"] {
  display: flex;
}

@media print, screen and (min-width: 46.875em) {
  [data-overlay-level="second"] .overlay__content--left-wrapper[data-overlay-content-level="second"] {
    width: 30rem;
  }
}

.overlay__content--right-wrapper {
  display: none;
  position: relative;
  width: 35rem;
  height: 100%;
  background-color: rgba(235, 180, 58, 0.1);
  margin-left: auto;
}

@media print, screen and (min-width: 46.875em) {
  .overlay__content--right-wrapper {
    display: block;
    transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
    transform: translateX(100%);
    transition-delay: 0s;
    animation: none;
    transform-origin: right;
  }
  .active--token .overlay__content--right-wrapper {
    transform: translateX(0);
  }
}

.overlay--navigation {
  display: flex;
  flex-direction: column;
  transition: height 0.55s cubic-bezier(0, 0, 0.175, 1);
  margin: 0;
  padding: 0;
  width: 100%;
}

.overlay__menu--container {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.overlay__menu--container:hover .navigation--item {
  opacity: 0.6;
}

.overlay--menu-breadcrumb {
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
}

.active--token .overlay--menu-breadcrumb {
  transform: translateX(-2.75rem);
}

.navigation--item {
  list-style: none;
  position: relative;
  transition: all 0.55s cubic-bezier(0, 0, 0.175, 1);
}

@media screen and (max-width: 46.87375em) {
  .navigation--item {
    padding-bottom: 0.75rem;
  }
}

@media print, screen and (min-width: 46.875em) {
  .navigation--item {
    margin-bottom: 1.5rem;
  }
}

.navigation--item.active, .navigation--item:hover {
  opacity: 1 !important;
}

.navigation--item[data-overlay-accordion="true"] {
  transition: all 300ms ease;
}

@media print, screen and (min-width: 46.875em) {
  .navigation--item {
    display: flex;
    align-content: center;
    align-items: center;
    transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
    position: relative;
  }
  .active--token .navigation--item {
    transform: translateX(-2.75rem);
  }
  .navigation--item:before {
    content: "";
    width: 2rem;
    height: 6px;
    background-color: #ebb43a;
    left: -2.75rem;
    position: absolute;
    transition: transform 0.8s cubic-bezier(0.76, 0, 0.24, 1), opacity 0.8s cubic-bezier(0.76, 0, 0.24, 1);
    opacity: 0;
    transform: scaleX(0.5);
    transform-origin: left;
  }
  .navigation--item.active {
    transition: color 0.3s ease-out, transform 0.8s cubic-bezier(0.76, 0, 0.24, 1);
    padding-bottom: 0;
    transform: translateX(0);
  }
  .navigation--item.active:before {
    opacity: 1;
    transform: scaleX(1);
  }
}

.navigation--item-link {
  display: block;
  padding: 0.25rem 0;
  cursor: pointer;
  margin: 0;
}

[data-overlay-accordion="true"] .navigation--item-link {
  display: flex;
  cursor: pointer;
  position: relative;
  align-items: center;
  justify-content: space-between;
  margin: 0;
}

.navigation--trigger-icon--circle {
  position: relative;
}

.navigation--trigger-icon--circle:after {
  position: absolute;
  min-width: 3rem;
  height: 3rem;
  content: "";
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;
  transform: translate(-25%, -48%);
  transform-origin: 0 0;
}

.navigation--trigger-icon--circle.trigger-icon--circle--black:after {
  border-color: #202020;
}

.navigation--children {
  position: absolute;
  counter-reset: list;
  margin-left: 0;
  width: 100%;
  pointer-events: none;
  margin-bottom: 0;
  opacity: 0;
}

@media print, screen and (min-width: 46.875em) {
  .navigation--children {
    transform: translate(30px);
    top: 100px;
    transition: opacity 0.35s;
  }
  .navigation--children.active {
    transition: opacity 0.35s 0.2s;
    opacity: 1;
  }
}

.navigation--children:hover .navigation--child-item {
  color: #a0a0a0;
}

[data-overlay-accordion="true"] .navigation--children {
  position: relative;
  visibility: hidden;
  overflow: hidden;
  max-height: 0;
  transform: translate(0, 50%);
}

.navigation--children.active {
  pointer-events: all;
  margin-bottom: 1.5rem;
}

[data-overlay-accordion="true"] .navigation--children.active {
  max-height: 800px;
  transform: translate(0, 0);
  opacity: 1;
  visibility: visible;
  margin-top: 1.5rem;
}

[data-overlay-level="second"] [data-overlay-accordion="true"] .navigation--children.active {
  margin-bottom: 1.5rem;
}

[data-overlay-accordion="true"] .navigation--children.active .navigation--child-item:last-child {
  border-bottom: none;
}

@media screen and (max-width: 46.87375em) {
  .navigation--children {
    height: 0;
    overflow: hidden;
    transition: height 1.2s cubic-bezier(0.19, 1, 0.22, 1), margin 1.2s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.35s;
  }
  .navigation--children.active {
    opacity: 1;
  }
}

.navigation--child-item {
  display: flex;
  border-bottom: 1px solid #fff;
  line-height: normal;
  margin-bottom: 0 !important;
  list-style: none;
  opacity: 0;
  transform: translate(30px);
  transition: transform 0.35s var(--var-delay-out), opacity 0.1s, color 0.1s ease-out;
}

.active .navigation--child-item {
  transform: translate(0);
  opacity: 1;
  transition: transform 0.35s var(--var-delay-out), opacity 0.35s var(--var-delay-out), color 0.1s ease-out;
}

@media screen and (max-width: 46.87375em) {
  .active .navigation--child-item {
    transform: translate(0);
  }
}

@media print, screen and (min-width: 46.875em) {
  .navigation--child-item {
    border-bottom: 0;
  }
}

.navigation--child-item:hover {
  color: #202020 !important;
}

@media screen and (max-width: 46.87375em) {
  .navigation--child-item {
    display: flex;
    align-items: center;
    margin-left: 0;
    transform: translateX(30px);
    opacity: 0;
  }
  .navigation--child-item:before {
    content: "";
    height: 2px;
    min-width: 1rem;
    background-color: #ebb43a;
    display: block;
    margin-right: 1rem;
    transition: width 0.55s cubic-bezier(0, 0, 0.175, 1) 0.15s, transform 0.25s;
    transform-origin: center center;
    transform: scaleY(1) translateY(0px);
  }
}

.navigation--child-item-link {
  padding: 0.8rem 0 0.9rem;
}

.help--button {
  display: none;
}

@media print, screen and (min-width: 46.875em) {
  .help--button {
    display: block;
  }
}

.navigation--trigger-icon {
  position: relative;
  display: none;
  align-items: center;
  background-color: #0000;
  transition: background-color 0.3s, border 0.3s;
  text-decoration: none;
  margin: 0;
}

[data-overlay-accordion="true"] .navigation--trigger-icon,
.accordion--headline-wrapper .navigation--trigger-icon,
.dropdown--toggle .navigation--trigger-icon {
  display: flex;
  height: 100%;
  width: 2rem;
}

.navigation--trigger-icon:after, .navigation--trigger-icon:before {
  content: "";
  background-color: #ffffff;
  height: 3px;
  width: 25px;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.5s ease;
}

.navigation--trigger-icon.trigger-icon--black:after, .navigation--trigger-icon.trigger-icon--black:before {
  background-color: #202020;
}

.navigation--trigger-icon.icon--small {
  width: 1rem;
}

.navigation--trigger-icon.icon--small:after, .navigation--trigger-icon.icon--small:before {
  width: 16px;
  height: 0.12rem;
}

.navigation--trigger-icon:before {
  transform: rotate(180deg);
}

.navigation--trigger-icon:after {
  transform: rotate(90deg);
}

.active .navigation--trigger-icon:before,
.toggled .navigation--trigger-icon:before,
.toggle--collapsed .navigation--trigger-icon:before {
  transform: rotate(0deg);
}

.active .navigation--trigger-icon:after,
.toggled .navigation--trigger-icon:after,
.toggle--collapsed .navigation--trigger-icon:after {
  transform: rotate(0deg);
}

.overlay--language {
  display: flex;
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
}

.active--token .overlay--language {
  transform: translateX(-2.75rem);
}

.overlay--language li {
  padding: 0;
  margin-left: 0;
  margin-right: 0.25rem;
}

.overlay--language li:last-of-type {
  margin-left: 0.25rem;
}

.topic-entry--section {
  position: relative;
}

@media screen and (max-width: 46.87375em) {
  .topic-entry--section {
    margin-top: 10rem;
  }
}

.topic-entry__square {
  display: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, 25%);
  transform-origin: center center;
}

@media print, screen and (min-width: 46.875em) {
  .topic-entry__square {
    display: block;
    height: 500px;
    width: 500px;
    transform: translate(-25%, -50%);
    transform-origin: center center;
  }
}

@media print, screen and (min-width: 64em) {
  .topic-entry__square {
    height: 600px;
    width: 600px;
    transform: translate(-10%, -50%);
    transform-origin: center center;
  }
}

.project-filter__teaser-box {
  margin-bottom: 15rem;
}

.appendix-box__wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}

.appendix-box {
  display: block;
  padding: 3rem 3rem 1.5rem 1.5rem;
  margin-top: 4rem;
  margin-left: 0;
  pointer-events: all;
  user-select: all;
  max-width: 100%;
}

.page--home .appendix-box {
  background-color: #120d34;
}

.appendix-box.appendix-box--projects {
  position: relative;
  text-align: right;
  padding-top: 0;
  margin-top: 0;
  margin-left: 15vw;
}

.section--full .appendix-box.appendix-box--projects {
  margin-top: -6rem;
  transform: translate(0, 42%);
}

.appendix-box.appendix-box--page-teaser {
  margin-top: 0;
}

@media print, screen and (min-width: 46.875em) {
  .appendix-box {
    position: absolute;
    max-width: 20rem;
    margin-top: 0;
    right: 0;
    top: 50%;
    transform: translate(0, 40%);
    padding: 9rem 3.5rem 1.5rem 1.5rem;
  }
  .appendix-box.appendix-box--page-teaser {
    position: relative;
    margin-top: auto;
    margin-left: auto;
    transform: translate(0);
  }
  .appendix-box.appendix-box--projects {
    margin-top: 0;
    margin-left: auto;
    position: relative;
    top: auto;
    transform: translate(0, 0);
  }
  .section--full .appendix-box.appendix-box--projects {
    transform: translate(0, 40%);
  }
  .project-teaser--default .appendix-box {
    position: relative;
    left: 0;
    top: 0;
  }
}

@media print, screen and (min-width: 64em) {
  .appendix-box {
    transform: translate(0, 40%);
    padding: 9rem 3.5rem 2rem 1.5rem;
  }
  .appendix-box.appendix-box--projects {
    margin-top: 0;
  }
  .project-teaser--default .appendix-box {
    transform: none;
    margin-left: 0;
  }
}

.appendix-box--projects-numbers {
  display: block;
  transform: translateY(0.5rem);
}

@media print, screen and (min-width: 46.875em) {
  .appendix-box--projects-numbers {
    transform: translateY(1.5rem);
  }
}

.page--home .appendix-box--projects-numbers {
  color: #ebb43a;
}

.topic__box {
  padding: 2rem;
}

@media print, screen and (min-width: 46.875em) {
  .topic__box {
    padding: 4rem;
  }
}

.chart-image {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media print, screen and (min-width: 46.875em) {
  .chart-image {
    flex-direction: row;
    align-items: center;
  }
}

.chart-image__custom {
  width: 100%;
}

.chart-image--with-thumb {
  height: 8rem;
  display: block;
}

.chart-image--with-thumb img {
  display: block;
  object-fit: contain;
  height: 100%;
}

.chart-image__thumb {
  margin-bottom: 1.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .chart-image__thumb {
    margin-bottom: 0;
  }
}

.chart-image__thumb a {
  margin-right: 2.5rem;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 50%;
  min-width: 160px;
  height: 160px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.35s;
}

.chart-image__thumb a.chart-image__thumb--no-circle {
  border: 0;
  background: none;
}

.chart-image__thumb a.chart-image__thumb--no-circle:hover {
  background: none;
}

.chart-image__thumb a:hover .chart-thumbnail-svg > path {
  fill: #ffffff !important;
}

.bp-xc {
  display: none !important;
}

.chart-thumbnail-svg > path {
  transition: fill 0.35s;
}

.topic-links__container {
  position: fixed;
  right: 0;
  top: 10rem;
  z-index: 800;
  cursor: pointer;
}

.page--topic .main-content:hover .topic-link--icon-wrapper,
.page--topic .main-content:hover .topic-link--icon-wrapper .topic-link--image {
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s, transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
}

.topic-link {
  margin-bottom: 0.45rem;
}

.topic-link:hover .topic-link--icon-wrapper,
.topic-link:hover .topic-link--icon-wrapper .topic-link--image,
.topic-link.topic-link--active:hover .topic-link--icon-wrapper,
.topic-link.topic-link--active:hover .topic-link--icon-wrapper .topic-link--image {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s, transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
}

@keyframes initTopicIcons {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translate(40px);
  }
}

.topic-link--icon-wrapper {
  display: none;
  justify-content: center;
  align-items: center;
  height: 2.75rem;
  width: 3.125rem;
  background-color: #ebb43a;
  position: relative;
  transform: translateX(40px);
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s, transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
}

@media print, screen and (min-width: 46.875em) {
  .topic-link--icon-wrapper {
    display: flex;
  }
}

.scrolled .topic-link--icon-wrapper,
.scrolled .topic-link--icon-wrapper .topic-link--image {
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s, transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s !important;
}

.topic-link--active .topic-link--icon-wrapper {
  transform: translateX(0);
}

.topic-link--active .topic-link--icon-wrapper .topic-link--image {
  opacity: 1;
}

.scrolled .topic-link--active .topic-link--icon-wrapper {
  transform: translateX(40px);
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1) 0s;
}

.scrolled .topic-link--active .topic-link--icon-wrapper .topic-link--image {
  opacity: 0;
}

.topic-link--icon-wrapper--dark-blue {
  background-color: #26418f;
}

.topic-link--icon-wrapper--light-blue {
  background-color: #6197c0;
}

.topic-link--icon-wrapper--dark-red {
  background-color: #4b101f;
}

.topic-link--icon-wrapper--dark-pink {
  background-color: #d090a0;
}

.topic-link--icon-wrapper--vivid-red {
  background-color: #e44715;
}

.topic-link--icon-wrapper--light-red {
  background-color: #8f003d;
}

.topic-link--icon-wrapper img {
  width: 100%;
  height: 1.5625rem;
  transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 0s;
  opacity: 0;
}

.topic-breadcrumbs__container {
  position: fixed;
  right: 1rem;
  bottom: 1rem;
  transition: opacity 0.5s cubic-bezier(0.76, 0, 0.24, 1) 0s;
  opacity: 0;
}

.scrolled--top .topic-breadcrumbs__container {
  opacity: 1;
}

.topic-breadcrumbs__container:hover {
  opacity: 1 !important;
}

.topic-breadcrumbs {
  display: flex;
}

.topic-breadcrumb {
  margin-right: 0.6875rem;
}

.topic-breadcrumb--link {
  display: block;
  height: 1.125rem;
  width: 0.6875rem;
  background-color: #ffffff;
}

.next-page-teaser {
  padding: 3rem 0;
  margin-top: 15rem;
  position: relative;
}

.next-page-teaser:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: scaleX(0);
  transition-delay: 0s;
  animation: none;
  background-color: #ebb43a;
  transition: transform 0.55s cubic-bezier(0, 0, 0.175, 1);
  transform-origin: left;
}

.next-page-teaser:hover:after {
  transform: scaleX(1);
}

.next-page-teaser:hover,
.next-page-teaser:hover .next-page-teaser__headline span {
  color: #ffffff;
}

.next-page-teaser__headline {
  position: relative;
  z-index: 100;
}

.next-page-teaser__headline span {
  transition: color 0.4s cubic-bezier(0, 0, 0.175, 1);
}

.next-page-teaser__result-wrapper {
  position: relative;
  margin-left: auto;
  max-width: 70%;
  padding: 3rem 0 2rem 0;
  z-index: 100;
  transition: color 0.4s cubic-bezier(0, 0, 0.175, 1);
}

.plugin--image {
  margin-bottom: 1.5rem;
}

.plugin--image img {
  width: 100%;
}

#bp_container img {
  background-color: #fff !important;
}

@media print, screen and (min-width: 46.875em) {
  #bp_container img {
    max-height: 80% !important;
    max-width: 80% !important;
    padding: 4rem;
  }
}

.project-entry--section {
  position: relative;
  margin-top: 10rem;
}

.project__categories {
  display: flex;
}

.project__categories--cat {
  display: block;
  margin-bottom: 0.75rem;
}

.project__categories--cat:not(:last-child):after {
  content: " |";
}

.project__categories--cat:not(:first-of-type) {
  margin-left: 0.35rem;
}

.project-box {
  padding: 2rem;
}

@media print, screen and (min-width: 46.875em) {
  .project-box {
    padding: 4rem;
  }
}

.project-box__item {
  margin-bottom: 0.5rem;
}

.project-box__item-wrapper {
  margin-bottom: 2.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #bfbfbf;
}

.project-box__item-wrapper.project-box__item-wrapper--last {
  border-bottom: none;
}

.downloads {
  display: flex;
  flex-wrap: wrap;
}

.download__container {
  position: relative;
  transition: background-color 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  margin-bottom: 1.5rem;
}

.download__container:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  transform: scaleY(0);
  transition-delay: 0s;
  animation: none;
  background-color: rgba(235, 180, 58, 0.1);
  transition: transform 0.3s cubic-bezier(0, 0, 0.175, 1);
  transform-origin: bottom;
  user-select: none;
  pointer-events: none;
}

.download__container:hover:after {
  transform: scaleY(1);
}

.download__link {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.download__preview {
  position: relative;
  z-index: 10;
}

.download__preview img {
  display: block;
}

.download__content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 1rem;
  padding-left: 1rem;
}

@media print, screen and (min-width: 46.875em) {
  .download__content {
    padding-bottom: 2rem;
    padding-left: 2rem;
  }
}

.download__content h3 {
  hyphens: auto;
}

.download__content p {
  color: #202020;
  font-weight: normal;
  margin-bottom: 0;
}

.download__container--dark .download__content p {
  color: #ffffff;
}

.download__content img {
  padding-right: 0.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .download__content img {
    max-width: 20rem;
  }
}

.download--icon {
  margin-top: auto;
  margin-bottom: auto;
}

@media print, screen and (min-width: 46.875em) {
  .download--icon {
    margin-left: 6rem;
  }
}

.download--icon .icon {
  height: 5rem;
  width: 5rem;
}

.downloads--grid .download--icon {
  display: none;
}

.icon--download {
  color: #ebb43a;
  height: 30px;
  width: auto;
}

.moodboard__container {
  height: 85vh;
  position: relative;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard__container {
    height: 80vh;
  }
}

@media print, screen and (min-width: 64em) {
  .moodboard__container {
    height: 100vh;
  }
}

.moodboard__inner-wrapper {
  align-items: center;
  height: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard__inner-wrapper {
    display: flex;
  }
}

.moodboard__image {
  position: relative;
  background-size: cover;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
}

.moodboard__image:before {
  content: "";
  background-color: rgba(19, 50, 45, 0.2);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.moodboard__image:after {
  content: "";
  background: transparent linear-gradient(180deg, #13575703 0%, white 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 8vh;
  z-index: 0;
}

@media print, screen and (min-width: 64em) {
  .moodboard__image:after {
    background: transparent linear-gradient(180deg, #13575703 0%, rgba(23, 64, 59, 0.9) 100%) 0% 0% no-repeat padding-box;
    height: 20vh;
  }
}

.moodboard-clip-path {
  position: absolute;
  bottom: 0;
  background: #ffffff;
  height: 8rem;
  width: 49%;
  transition: all 200ms ease;
  z-index: 10;
}

.moodboard-clip-path.moodboard-clip-path--reversed {
  top: 0;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard-clip-path {
    width: 49.5%;
    height: 10rem;
  }
}

@media print, screen and (min-width: 64em) {
  .moodboard-clip-path {
    width: 49.5%;
    height: 15rem;
  }
}

.moodboard-clip-path--left {
  left: 0;
  border-top-right-radius: 100px;
}

.moodboard-clip-path--left.moodboard-clip-path--reversed {
  border-bottom-right-radius: 100px;
  border-top-right-radius: 0;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard-clip-path--left.moodboard-clip-path--reversed {
    border-bottom-right-radius: 200px;
  }
}

@media print, screen and (min-width: 46.875em) {
  .moodboard-clip-path--left {
    border-top-right-radius: 200px;
  }
}

.moodboard-clip-path--right {
  right: 0;
  border-top-left-radius: 100px;
}

.moodboard-clip-path--right.moodboard-clip-path--reversed {
  border-bottom-left-radius: 100px;
  border-top-left-radius: 0;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard-clip-path--right.moodboard-clip-path--reversed {
    border-bottom-left-radius: 200px;
  }
}

@media print, screen and (min-width: 46.875em) {
  .moodboard-clip-path--right {
    border-top-left-radius: 200px;
  }
}

.moodboard__content {
  height: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;
  padding-top: 20vh;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard__content {
    padding-top: 0;
    display: block;
  }
}

.moodboard__content-wrapper {
  max-width: 80%;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard__content-wrapper {
    max-width: 100%;
  }
}

.moodboard__playbutton {
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}

.moodboard__playbutton:after {
  content: url("img/icons/play-button.svg");
  position: relative;
  width: 2rem;
  height: 2rem;
  display: block;
  transition: all 0.15s ease-in-out;
}

.moodboard__playbutton:hover:after {
  opacity: 0.9;
}

.moodboard-cta {
  background-color: rgba(19, 50, 45, 0.7);
  padding: 1rem 2rem 0.25rem 1rem;
  position: relative;
  z-index: 30;
  width: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .moodboard-cta {
    padding: 2rem 3rem 0.25rem 3rem;
  }
}

.moodboard-cta__content {
  display: flex;
  flex-direction: column;
}

.moodboard-cta__button {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.moodboard-cta__newsletter {
  margin-top: 2rem;
}

.page-header {
  padding: 6rem 0;
  color: #ffffff;
}

@media print, screen and (min-width: 46.875em) {
  .page-header {
    padding: 12rem 0;
  }
}

@media print, screen and (min-width: 64em) {
  .page-header__content {
    margin-top: 3rem;
  }
}

.page-header__image-container {
  margin-left: 3rem;
  padding: 1rem;
}

@media print, screen and (min-width: 64em) {
  .page-header__image-container {
    margin-left: 5rem;
  }
}

.page-header__image-container img {
  max-width: 100%;
  display: block;
}

.page-header__toptitle {
  margin-bottom: 0.5rem;
}

.letter-teaser {
  background-color: #17403b;
  position: relative;
}

.letter-teaser:after {
  content: "";
  background: transparent linear-gradient(180deg, white 0%, #13575703 100%) 0% 0% no-repeat padding-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 8vh;
  z-index: 0;
}

@media print, screen and (min-width: 64em) {
  .letter-teaser:after {
    display: none;
  }
}

.letter-teaser__content {
  padding-top: 30vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media print, screen and (min-width: 46.875em) {
  .letter-teaser__content {
    padding-top: 30vh;
  }
}

@media print, screen and (min-width: 64em) {
  .letter-teaser__content {
    padding-top: 40vh;
  }
}

.letter-teaser__content--container {
  text-align: center;
  width: 100%;
  margin-top: 2rem;
}

@media print, screen and (min-width: 46.875em) {
  .letter-teaser__content--container {
    margin-top: 0;
    text-align: left;
    width: auto;
  }
}

.letter-teaser__image-container {
  overflow: hidden;
  height: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .letter-teaser__image-container {
    padding-top: 20vh;
  }
}

@media print, screen and (min-width: 64em) {
  .letter-teaser__image-container {
    padding-top: 30vh;
  }
}

.letter-teaser__image-container img {
  transform: translateY(10%);
  max-width: 80%;
  display: block;
}

@media print, screen and (min-width: 46.875em) {
  .letter-teaser__image-container img {
    margin-left: auto;
  }
}

.letter__button {
  margin-top: 2rem;
}

.article-teaser {
  padding-top: 5rem;
}

@media print, screen and (min-width: 64em) {
  .article-teaser {
    padding: 7.5rem 0;
  }
}

.article-teaser__link {
  display: flex;
  align-items: center;
  max-width: fit-content;
}

.thur-teaser {
  position: relative;
}

.thur-teaser__content {
  text-align: center;
}

@media screen and (max-width: 46.87375em) {
  .thur-teaser__image-container {
    transform: translate(0, -20%);
  }
}

.textblock {
  padding: 2.5rem 0;
}

@media print, screen and (min-width: 46.875em) {
  .textblock {
    padding: 5rem 0;
  }
}

.textblock.textblock--intro {
  padding: 0;
}

.home--main .textblock {
  background-color: #17403b;
  color: #ffffff;
}

.home--outro .textblock {
  color: #ffffff;
  position: relative;
  z-index: 10;
}

@media print, screen and (min-width: 64em) {
  .textblock__intro-content {
    max-width: 60%;
  }
}

.image-comparison {
  background-color: #17403b;
  padding-bottom: 2.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .image-comparison {
    padding-bottom: 5rem;
  }
}

.image-comparison__container {
  position: relative;
  z-index: 100;
}

img-comparison-slider {
  margin: 0 auto;
  --hint-size: 300px;
  --hint-opacity: 1;
  --divider: 5px solid #e5eb50;
}

img-comparison-slider:focus {
  outline: none;
}

.custom-hint {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-38%, -50%);
}

.slider {
  overflow: hidden;
}

@media screen and (max-width: 46.87375em) {
  .slider {
    padding: 2rem 1rem;
  }
}

.factslider__slides {
  overflow: hidden;
  position: relative;
}

.factslider__slide {
  position: relative;
  z-index: 100;
}

.factslider__slider {
  display: flex;
  overflow: hidden;
}

.facts {
  position: relative;
  overflow: hidden;
}

@media print, screen and (min-width: 46.875em) {
  .facts {
    padding-bottom: 10rem;
  }
}

@media print, screen and (min-width: 64em) {
  .facts {
    padding-bottom: 25rem;
  }
}

.facts__container {
  position: relative;
  display: flex;
  z-index: 100;
  flex-wrap: wrap;
  flex-direction: row;
  padding-top: 5vh;
  width: 100%;
  height: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .facts__container {
    overflow: hidden;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 0;
  }
}

.fact {
  flex: 0 1 50%;
}

@media print, screen and (min-width: 46.875em) {
  .fact {
    flex: 0 1 50%;
  }
}

.fact__content-wrapper {
  display: flex;
}

.fact__content {
  max-width: 80%;
}

.fact__divider {
  height: 30rem;
  width: 2px;
  background: #ffffff;
  position: relative;
  display: none;
}

.fact__divider:after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ffffff;
  transform: translate(calc(-50% + 1px), 0);
}

@media print, screen and (min-width: 46.875em) {
  .fact__divider {
    display: block;
  }
}

.fact__divider.fact__divider--large {
  height: 30rem;
}

.fact__divider.fact__divider--medium {
  height: 24rem;
}

.fact__divider.fact__divider--small {
  height: 18rem;
}

@keyframes factsAnimationMobile {
  from {
    transform: translateY(-150px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 0.2;
  }
}

.slider__container {
  display: flex;
  overflow: hidden;
  height: 100%;
  position: relative;
}

.left__wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 20;
}

@media screen and (max-width: 46.87375em) {
  .left__wrapper {
    background-color: transparent !important;
  }
}

.left__inner-wrapper {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
}

@media screen and (max-width: 46.87375em) {
  .left__inner-wrapper {
    min-height: 10rem;
    width: 100%;
  }
}

.slider--left-content {
  position: absolute;
  text-align: left;
  width: 80%;
}

@media screen and (max-width: 46.87375em) {
  .slider--left-content {
    display: flex;
    flex-direction: column;
  }
  .slider--left-content [data-metric] {
    order: 2;
    margin-top: 0.5rem;
    color: #202020;
  }
  .slider--left-content .slider--subline {
    color: #202020;
  }
}

@media print, screen and (min-width: 46.875em) {
  .slider--left-content {
    text-align: right;
    position: absolute;
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media print, screen and (min-width: 64em) {
  .slider--left-content {
    width: 65%;
    margin-left: 10%;
    margin-right: 25%;
  }
}

@keyframes factsAnimationRight {
  from {
    transform: translateX(150px);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

@keyframes factsAnimationLeft {
  from {
    transform: translateX(-150px);
    visibility: hidden;
  }
  to {
    transform: translateX(0);
    visibility: visible;
  }
}

[data-metric] {
  position: relative;
  line-height: normal;
  visibility: hidden;
  width: 100%;
  color: #ffffff;
}

[data-metric][data-current] {
  animation: 0.8s ease-out 0.1s 1 normal forwards running fadeInSlide;
}

.slider--subline {
  position: relative;
  line-height: normal;
  visibility: hidden;
  width: 100%;
  color: #ffffff;
  display: block;
}

.slider--subline[data-current] {
  animation: 0.8s ease-out 0.2s 1 normal forwards running fadeInSlide;
}

.right__wrapper {
  position: relative;
  z-index: 20;
}

.factslide__content {
  margin-bottom: 6rem;
}

@media print, screen and (min-width: 46.875em) {
  .factslide__content {
    margin: 3rem 3rem 4rem 2rem;
  }
  .slider--subpage .factslide__content {
    max-width: 90%;
  }
}

@media print, screen and (min-width: 64em) {
  .factslide__content {
    margin: 4rem 4rem 6rem 4rem;
  }
  .slider--subpage .factslide__content {
    max-width: 80%;
  }
}

.media-slider__thumbs {
  display: flex;
  position: absolute;
  bottom: 1rem;
  justify-content: flex-end;
  right: 0.5rem;
}

.media-slider__thumbs a[data-thumb] {
  position: relative;
  height: 12px;
  width: 8px;
  margin-right: 0.5rem;
  padding: 0;
  background-color: #ffffff;
  border: 0 none;
  outline: none;
  cursor: pointer;
}

.media-slider__thumbs a[data-thumb][data-current] {
  opacity: 1;
  position: relative;
  transition: all ease-in-out 200ms;
}

.media-slider__go-wrapper {
  position: absolute;
  bottom: 0;
  display: flex;
  z-index: 900;
}

@media print, screen and (min-width: 46.875em) {
  .media-slider__go-wrapper {
    left: -100px;
  }
}

.media-slider__go {
  display: block;
  line-height: 0;
}

.media-slider__go[data-go="-1"] {
  margin-right: 2px;
}

.slider__title {
  font-size: 11vw;
  margin-bottom: 3.75rem;
  margin-top: 2.5rem;
}

@media screen and (min-width: 46.875em) {
  .slider__title {
    font-size: 4.125vw;
  }
}

@media screen and (min-width: 64em) {
  .slider__title {
    font-size: 4.95vw;
  }
}

@media screen and (min-width: 105em) {
  .slider__title {
    font-size: 4.5rem;
  }
}

.factslide {
  opacity: 0;
  transition: opacity 0.8s cubic-bezier(0.76, 0, 0.24, 1) 0.35s;
}

.slider--subpage .factslide {
  display: flex;
  align-items: center;
}

.slider--subpage.slider--subpage-home {
  position: relative;
}

@media print, screen and (min-width: 46.875em) {
  .slider--subpage.slider--subpage-home:after, .slider--subpage.slider--subpage-home:before {
    content: "";
    position: absolute;
    height: 100%;
    width: 50%;
    top: 0;
    z-index: 0;
  }
  .slider--subpage.slider--subpage-home:after {
    background-color: #ebb43a;
    left: 0;
  }
  .slider--subpage.slider--subpage-home:before {
    background-color: rgba(235, 180, 58, 0.1);
    right: 0;
  }
}

.subpage-slider__list--links,
.topic-slider__list--links {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.subpage-slider__list-element {
  list-style: none;
  display: flex;
  align-items: center;
  color: #202020;
  width: 100%;
  margin-bottom: 1rem;
}

@media print, screen and (min-width: 46.875em) {
  .subpage-slider__list-element {
    height: 4rem;
    margin-bottom: 0;
  }
}

.subpage-slider__list-element span {
  width: 100%;
}

.subpage-slider__list-element:before {
  content: "";
  height: 2px;
  width: 1rem;
  background-color: #ebb43a;
  display: block;
  transform: translateY(2px);
  margin-right: 1rem;
}

@media print, screen and (min-width: 46.875em) {
  .subpage-slider__list-element:before {
    background-color: #ffffff;
  }
}

.topic-slider__list-element {
  list-style: none;
  display: flex;
  align-items: center;
  color: #202020;
  width: 100%;
  margin-bottom: 1rem;
}

.topic-slider__list-element .topic-slider__page-links {
  transition: opacity 0.35s;
  opacity: 0.4;
}

@media print, screen and (min-width: 46.875em) {
  .topic-slider__list-element {
    height: 4rem;
    margin-bottom: 0;
  }
}

.topic-slider__list-element span {
  width: 100%;
}

.topic-slider__list-element:before {
  content: "";
  height: 2px;
  width: 1rem;
  background-color: #ebb43a;
  display: block;
  margin-right: 1rem;
  transition: width 0.55s cubic-bezier(0, 0, 0.175, 1) 0.15s, transform 0.25s;
  transform-origin: center center;
  transform: scaleY(1) translateY(0px);
}

.topic-slider__list-element[data-current]:before {
  transform: scaleY(2.5) translateY(0px);
  width: 2rem;
}

.subpage-slider__list {
  display: flex;
  align-content: center;
  height: 100%;
  position: relative;
  width: 100%;
}

.subpage-slider__list-container,
.topic-slider__list-container {
  display: flex;
  padding: 0;
  margin-left: 0;
  margin-top: 2rem;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  height: 100%;
  opacity: 0;
  transition: opacity 0.35s;
  pointer-events: none;
}

.subpage-slider__list-container:hover .subpage-slider__page-links,
.topic-slider__list-container:hover .subpage-slider__page-links {
  opacity: 0.6;
}

.subpage-slider__list-container[data-current],
.topic-slider__list-container[data-current] {
  opacity: 1;
  pointer-events: all;
}

.topic-slider__list-container {
  opacity: 1;
  pointer-events: all;
}

.subpage-slider__page-links {
  display: block;
  width: fit-content;
  transition: opacity 0.35s;
}

.subpage-slider__page-links:hover {
  opacity: 1 !important;
}

.media-slider__go-wrapper--testimonials {
  left: auto;
  position: relative;
  margin-top: 1rem;
  display: flex;
  justify-items: flex-end;
}

.media-slider__go-wrapper--testimonials [data-go="-1"] {
  margin-left: auto;
}

.media-slider__go-wrapper--testimonials .svg-stroke-theme-color {
  stroke: #ffffff !important;
}

.topic-slider__content {
  margin-bottom: 3rem;
}

@media print, screen and (min-width: 46.875em) {
  .topic-slider__content {
    padding-top: 8vh;
  }
}

@media print, screen and (min-width: 64em) {
  .topic-slider__content {
    padding-top: 12vh;
  }
}

.topic-slider__go-wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  bottom: 0;
  right: 0;
  z-index: 100;
  background: #ebb43a;
  padding: 1rem 1rem;
  width: 230px;
}

@media print, screen and (min-width: 46.875em) {
  .topic-slider__go-wrapper {
    right: 30%;
    width: 300px;
  }
}

.topic-slider__go-wrapper .topic-slider__go.topic-slider__go--right svg {
  transform: rotate(-180deg) translateY(-4px);
}

.topic-slider__go-wrapper .topic-slider__go.topic-slider__go--left svg {
  transform: translateY(4px);
}

.topic-slider__go-inner-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

[data-current] .topic-slider__page-links {
  opacity: 1;
}

.logos__container {
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 2rem;
  background: #ffffff;
}

@media print, screen and (min-width: 46.875em) {
  .logos__container {
    padding: 2rem 4rem;
  }
}

.logos__container .logo {
  flex: 0 1 45%;
  margin-bottom: 2.5rem;
  display: block;
}

@media print, screen and (min-width: 46.875em) {
  .logos__container .logo {
    margin-bottom: 0;
    flex: 0 1 33%;
  }
  .logos__container .logo.logo--small {
    padding: 2rem;
  }
}

.logos__container .logo img {
  display: block;
  margin: 0 auto;
}

.fact-check {
  margin-top: -2.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .fact-check {
    margin-top: -1.25rem;
    margin-bottom: 2.5rem;
  }
}

.fact-check svg {
  width: 100%;
}

@media screen and (max-width: 46.87375em) {
  .fact-check--text {
    font-size: 40px !important;
    baseline-shift: -30px;
  }
  .fact-check--text.fact-check--text--right {
    text-anchor: middle;
  }
}

.letter__wrapper {
  height: 100%;
  flex: 1;
  position: relative;
}

@media print, screen and (min-width: 46.875em) {
  .letter__wrapper {
    top: -10rem;
  }
}

.letter__viewer {
  padding: 2rem 4rem 3rem 2rem;
  position: relative;
  filter: drop-shadow(0px 8px 6px #00000029);
  background: #ffffff;
}

@media print, screen and (min-width: 64em) {
  .letter__viewer {
    padding: 5rem 4rem 3rem 6rem;
  }
}

.letter__viewer:after {
  content: "";
  display: block;
  height: 9rem;
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  bottom: -8.99rem;
  z-index: 10;
  left: 0;
  clip-path: polygon(50% 20%, 0 0, 100% 0);
  border-top: 15px solid #ffffff;
}

.letter__footer {
  padding: 2rem 4rem 3rem 2rem;
  margin-top: 3rem;
  position: relative;
  filter: drop-shadow(0px 8px 6px #00000029);
  background: #f2f2f2;
}

@media print, screen and (min-width: 64em) {
  .letter__footer {
    padding: 2rem 4rem 3rem 6rem;
  }
}

.letter__footer:after {
  content: "";
  display: block;
  height: 9rem;
  width: 100%;
  position: absolute;
  background-color: #f2f2f2;
  top: -8.99rem;
  z-index: 10;
  left: 0;
  clip-path: polygon(100% 100%, 0 100%, 0 80%, 50% 100%, 100% 80%);
  border-top: 15px solid #ffffff;
}

.letter__element--thur,
.letter__element--concept {
  transition: all 0.25s ease-in-out;
  animation-duration: 0.8s;
  animation-name: slidein;
}

.letter__element--thur:not(:first-child):before {
  content: ", ";
}

.letter__element--thur.letter__element--thur-1:not(:first-child):before, .letter__element--thur.letter__element--thur-2:not(:first-child):before {
  content: " und ";
}

.letter__element--thur.letter__element--thur-1:not(:last-child):before, .letter__element--thur.letter__element--thur-2:not(:last-child):before {
  content: ", ";
}

.letter__element--thur.letter__element--thur-0:last-child:after, .letter__element--thur.letter__element--thur-1:last-child:after {
  content: ".";
}

.letter__element--thur.letter__element--thur-thurgauer:last-child:after {
  content: " ";
}

.letter__element--concept:not(:first-child):before {
  content: " ";
}

@keyframes slidein {
  from {
    color: #8bc147;
  }
  to {
    color: #202020;
  }
}

.letter__list {
  padding: 0;
  margin-bottom: 2.5rem;
}

.letter__list li {
  list-style: none;
  margin-bottom: 0.75rem;
}

.letter__list li label {
  display: flex;
  cursor: pointer;
}

.letter__list li label input {
  margin-right: 0.75rem;
  margin-top: 0.25rem;
}

.letter__element--info:after {
  content: " ";
}

.letter__element--info.letter__element--id_email {
  display: none;
}

.letter__element--info.letter__element--info-1:after, .letter__element--info.letter__element--info-2:after {
  content: "\a";
  white-space: pre;
}

.sharing-image {
  position: relative;
  margin-top: 2.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .sharing-image {
    margin-top: 0;
    top: -8rem;
  }
}

@media print, screen and (min-width: 64em) {
  .sharing-image {
    top: -10rem;
  }
}

.at-share-btn-elements a {
  margin-right: 0.75rem !important;
}

.social-btns {
  display: flex;
}

.social-btns a {
  background: #3d8062;
  border-radius: 50%;
  margin-right: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
}

.social-btns a div {
  margin-right: 0;
}

.poi-text h3 {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.poi-text span.text--bold {
  font-size: 0.875rem;
  line-height: 1.4;
  display: block;
  margin-bottom: 0.75rem;
}

@media print, screen and (min-width: 46.875em) {
  .poi-text span.text--bold {
    max-width: 80%;
  }
}

.poi-text p {
  font-size: 0.7em;
}

.poi-text .button__block {
  margin-bottom: 0;
}

.poi-text .button__block a {
  font-size: 0.875rem;
}

.poi-text .button__block a .download__icon {
  margin-left: 0.5rem;
}

.poi-text .button__block a svg {
  height: 1rem;
}

@media print, screen and (min-width: 46.875em) {
  .poi-image {
    width: 100%;
    height: auto;
    max-height: 200px;
    object-fit: cover;
    object-position: bottom;
  }
  .poi-image.poi-image--center {
    object-position: center 40%;
  }
}

.km-button-label {
  font-size: 12px;
  line-height: normal;
}

@media print, screen and (min-width: 46.875em) {
  .km-button-label {
    margin-top: -30px !important;
  }
}

@media print, screen and (min-width: 46.875em) {
  .km-button {
    width: 8.5px !important;
    height: 8.5px !important;
  }
}

@media print, screen and (min-width: 46.875em) {
  .vertical-line {
    height: 583px !important;
  }
}

.poi-card__image-caption.text--small {
  font-size: 0.65rem;
  padding: 0 10px 10px 30px;
  display: block;
  margin-bottom: -1rem;
}

.text--city {
  opacity: 0.6;
}

.accordion--container {
  background: #ffffff;
  padding: 3rem 2.5rem;
}

.accordion--header {
  margin-bottom: 2.5rem;
}

.accordion--wrapper .accordion:last-of-type {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #efefef;
}

.accordion--wrapper .accordion:last-of-type.accordion--substring {
  border: none;
}

.accordion--wrapper:hover .accordion .accordion--headline p, .accordion--wrapper.accordion--active .accordion .accordion--headline p {
  color: rgba(4, 12, 38, 0.4);
}

.accordion {
  padding-bottom: 1.5rem;
}

.accordion:hover p,
.accordion.toggled p {
  color: #202020 !important;
}

.js-foldout-wrapper {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 1s cubic-bezier(0.19, 1, 0.22, 1), margin 1s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.12s;
}

.toggled .js-foldout-wrapper {
  opacity: 1;
}

.js-foldout-body {
  padding-top: 1.5rem;
}

.js-foldout-body p {
  margin-bottom: 0;
  max-width: 90%;
}

.accordion--substring .js-foldout-body p {
  max-width: 100%;
  margin-bottom: 1.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .accordion--substring .js-foldout-body p {
    max-width: 90%;
  }
}

.accordion--headline {
  border-top: 1px solid #efefef;
  padding-top: 1.5rem;
  cursor: pointer;
}

.accordion--substring .accordion--headline {
  border: none;
}

.accordion--headline p {
  margin-bottom: 0;
  max-width: 90%;
}

.accordion--headline-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slider--subpage .accordion--headline-wrapper .accordion--headline-inner-wrapper {
  width: 80%;
}

.project-teaser {
  display: block;
  border-top-width: 2px;
  border-top-style: solid;
  padding-top: 2rem;
  margin-bottom: 2.5rem;
}

@media screen and (max-width: 46.87375em) {
  .home-teaser__box .project-teaser {
    border-color: #202020;
  }
  .home-teaser__box .project-teaser:hover .project-teaser__title {
    color: #202020;
  }
}

.project-teaser__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.project-teaser__title {
  margin: 1rem 0 3rem 0;
}

@media print, screen and (min-width: 46.875em) {
  .project-teaser__title {
    max-width: 80%;
  }
}

.project-teaser__title h3 {
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

.section--projects {
  margin-top: 8rem;
}

@media print, screen and (min-width: 46.875em) {
  .section--projects {
    margin-top: 15rem;
  }
}

.project--english-hint {
  display: block;
  margin-top: -3rem;
  margin-bottom: 3rem;
}

.project-list__controls {
  display: flex;
  flex-direction: column;
}

@media print, screen and (min-width: 46.875em) {
  .project-list__controls {
    justify-content: space-between;
    flex-direction: row;
  }
}

.project-list__filter--toggle {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  order: 2;
  margin-bottom: 1.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .project-list__filter--toggle {
    order: 1;
    margin-bottom: 0;
  }
}

.project-list__search--toggle {
  display: flex;
  align-items: center;
  align-content: center;
  cursor: pointer;
  position: relative;
  order: 2;
  margin-bottom: 1.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .project-list__search--toggle {
    justify-content: flex-end;
  }
}

.navigation--trigger-search .js-foldout-body {
  padding-top: 0;
}

.navigation--trigger-search svg {
  width: 12px;
  margin-left: 1rem;
}

.project-list__filter--trigger {
  display: flex;
  align-items: center;
}

.project-list__filter--trigger svg {
  width: 8px;
  transform: rotate(-90deg);
  margin-left: 1rem;
  transition: transform 1.2s cubic-bezier(0.19, 1, 0.22, 1) 0.1s;
  transform-origin: 50% 50%;
  will-change: transform;
}

.toggled .project-list__filter--trigger svg {
  transform: rotate(90deg);
}

@media screen and (max-width: 46.87375em) {
  .project-list__filter--content .js-foldout-body {
    padding-top: 0;
  }
}

.project-list__filter--content:hover, .project-list__filter--content.project-list__filter--item {
  color: rgba(4, 12, 38, 0.4);
}

@media print, screen and (min-width: 46.875em) {
  .project-list__filter--list {
    max-width: 70%;
  }
}

.project-list__filter--list li {
  display: inline-block;
  margin-bottom: 1.5rem;
  margin-right: 1.5rem;
}

.project-list__filter--list li:hover a {
  color: #202020 !important;
}

.project-list__filter--list li a.active {
  color: #ebb43a !important;
}

.project-list__filter--left {
  display: flex;
  flex-direction: column;
}

@media print, screen and (min-width: 46.875em) {
  .project-list__filter--left {
    flex-direction: row;
  }
}

.project-filter__result-container {
  display: flex;
  order: 1;
}

@media print, screen and (min-width: 46.875em) {
  .project-filter__result-container {
    margin-left: 3rem;
    order: 2;
  }
}

.project-filter__current-category {
  display: flex;
  justify-content: center;
  border: 1px solid #d7d7d7;
  border-radius: 16px;
  padding: 8px 16px 6px 18px;
  line-height: 1;
  margin-left: 1rem;
}

@media screen and (max-width: 46.87375em) {
  .project-filter__current-category {
    margin-left: 0;
    width: fit-content;
    margin-bottom: 1.5rem;
  }
}

.project-filter__current-category::selection {
  background: none;
}

.project-filter__current-category svg {
  margin-left: 0.75rem;
  width: 16px;
  height: 16px;
}

.project-list--search-form .field-container {
  border: 0;
  padding: 0.5em 0.5em 0.5em 1em;
}

.project-list--search-form .field-container input {
  background: transparent;
  border: 0;
  color: inherit;
  font: inherit;
  outline: 0;
  background: rgba(235, 180, 58, 0.1);
  margin: 0.5em 0;
  padding: 0.5em 0.5em 0.5em 1em;
  width: 100%;
}

@media print, screen and (min-width: 46.875em) {
  .project-list--search-form .field-container input {
    width: 300px;
  }
}

.project-list--search-form .field-container #search:hover,
.project-list--search-form .field-container #search:focus {
  background: rgba(235, 180, 58, 0.1);
}

.js-foldout-body--search {
  padding-top: 0;
}

.pagination {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ebb43a;
  width: 300px;
  margin: 0 auto;
  padding: 1rem 1rem;
}

.pagination__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination-arrow.pagination-arrow--right svg {
  transform: rotate(-180deg) translateY(-4px);
}

.pagination-arrow.pagination-arrow--left svg {
  transform: translateY(4px);
}

.testimonial-wrapper {
  margin: 1rem 0 3rem 0;
}

.testimonial-slide {
  position: relative;
}

.testimonial__slides {
  overflow-x: hidden;
}

.testimonial__slider {
  display: flex;
}

.testimonial__slide {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2rem;
  justify-content: center;
  transition: opacity 0.8s cubic-bezier(0.76, 0, 0.24, 1) 0.35s;
}

@media print, screen and (min-width: 46.875em) {
  .testimonial__slide {
    flex-direction: row;
    justify-content: unset;
  }
}

.testimonial__image-container {
  width: 100%;
  margin-bottom: 1.5rem;
}

@media print, screen and (min-width: 46.875em) {
  .testimonial__image-container {
    flex: 1 1 30%;
    margin-right: 3rem;
    margin-top: auto;
    margin-bottom: auto;
  }
}

@media print, screen and (min-width: 64em) {
  .testimonial__image-container {
    flex: 1 1 30%;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.testimonial__image-container img {
  display: block;
  max-width: 100%;
  height: auto;
}

.testimonial__content-wrapper {
  display: flex;
  flex-direction: column;
}

@media print, screen and (min-width: 46.875em) {
  .testimonial__content-wrapper {
    justify-content: space-around;
    flex: 1 1 70%;
  }
}

.testimonial__go {
  position: absolute;
  display: flex;
  height: 2.5rem;
  width: 2.5rem;
  background-size: cover;
  right: 0;
  bottom: 0;
  cursor: pointer;
}

.testimonial__go[data-go="-1"] {
  position: absolute;
  transform: rotate(-180deg);
  right: 3rem;
}

@media screen and (max-width: 46.87375em) {
  .testimonial__go[data-go="-1"] {
    transform: rotate(-180deg) translateY(-20px);
  }
}

.testimonial__go[data-go="1"] {
  position: absolute;
}

@media screen and (max-width: 46.87375em) {
  .testimonial__go[data-go="1"] {
    transform: translateY(20px);
  }
}

.edit-tools {
  position: fixed;
  z-index: 500;
  left: 0.5rem;
  bottom: 0.5rem;
  background: #8bc147;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  font-size: 0.9rem;
  font-weight: bold;
  border-radius: 0.6rem;
  color: #ffffff;
}

.edit-tools .button {
  background: #638c2f;
  font-size: 1em;
  margin: 0.25rem;
  padding: 0.5rem 0.6rem;
  display: inline-block;
  min-width: auto;
  border-radius: 0.6rem;
}

.show-breakpoint {
  position: fixed;
  opacity: 0.7;
  z-index: 10;
  right: 0.5rem;
  bottom: 4rem;
  color: #ffffff;
  font-weight: bold;
}

.show-breakpoint > div {
  padding: 0.1em 0.5em;
  border-radius: 0.6rem;
}

.show-breakpoint .show-for-small-only {
  background: lightseagreen;
}

.show-breakpoint .show-for-medium-only {
  background: orange;
}

.show-breakpoint .show-for-large {
  background: red;
}
