.appear {
  opacity: 1;
}

//.appear--fadeinleft {
//  animation-duration: 0.4s;
//  animation-timing-function: ease-in-out;
//  animation-iteration-count: 1;
//  animation-direction: normal;
//  animation-fill-mode: forwards;
//  animation-play-state: running;
//}

@keyframes fadeInSlide {
  from {
    opacity: 0;
    visibility: hidden;
  }
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fadeUp {
  from {
    transform: translateY(50px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fadeInRight {
  from {
    transform: translateX(-30px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes fadeOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-30px);
  }
}
@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translateX(-30px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

$fade-out: cubic-bezier(0.41, 0.29, 0.18, 0.97);
