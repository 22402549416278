// Moodboard

.moodboard {
}

.moodboard__container {
  //position: relative;
  height: 85vh;
  position: relative;

  @include breakpoint(medium) {
    height: 80vh;
  }

  @include breakpoint(large) {
    height: 100vh;
  }
}

.moodboard__inner-wrapper {
  align-items: center;
  height: 100%;

  @include breakpoint(medium) {
    display: flex;
  }
}

.moodboard__image {
  position: relative;
  background-size: cover;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;

  &:before {
    content: "";
    background-color: rgba(19, 50, 45, 0.2);
    //background-color: rgba(19, 50, 45, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &:after {
    content: "";
    background: transparent
      linear-gradient(180deg, #13575703 0%, rgba(255, 255, 255, 1) 100%) 0% 0%
      no-repeat padding-box;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 8vh;
    z-index: 0;

    @include breakpoint(large) {
      background: transparent
        linear-gradient(180deg, #13575703 0%, rgba(23, 64, 59, 0.9) 100%) 0% 0%
        no-repeat padding-box;
      height: 20vh;
    }
  }
}

.moodboard-clip-path {
  position: absolute;
  bottom: 0;
  background: $white;
  height: 8rem;
  width: 49%;
  transition: all 200ms ease;
  z-index: 10;

  &.moodboard-clip-path--reversed {
    top: 0;
  }

  @include breakpoint(medium) {
    width: 49.5%;
    height: 10rem;
  }

  @include breakpoint(large) {
    width: 49.5%;
    height: 15rem;
  }
}

.moodboard-clip-path--left {
  left: 0;
  border-top-right-radius: 100px;

  &.moodboard-clip-path--reversed {
    border-bottom-right-radius: 100px;
    border-top-right-radius: 0;

    @include breakpoint(medium) {
      border-bottom-right-radius: 200px;
    }
  }

  @include breakpoint(medium) {
    border-top-right-radius: 200px;
  }
}

.moodboard-clip-path--right {
  right: 0;
  border-top-left-radius: 100px;

  &.moodboard-clip-path--reversed {
    border-bottom-left-radius: 100px;
    border-top-left-radius: 0;

    @include breakpoint(medium) {
      border-bottom-left-radius: 200px;
    }
  }

  @include breakpoint(medium) {
    border-top-left-radius: 200px;
  }
}

.moodboard__content {
  height: 100%;
  position: relative;
  z-index: 20;
  display: flex;
  align-items: center;

  padding-top: 20vh;

  @include breakpoint(medium) {
    padding-top: 0;
    display: block;
  }
}

.moodboard__content-wrapper {
  max-width: 80%;
  @include breakpoint(medium) {
    max-width: 100%;
  }
}

.moodboard__playbutton {
  height: 3rem;
  width: 3rem;
  cursor: pointer;

  &:after {
    content: url("img/icons/play-button.svg");
    position: relative;
    width: 2rem;
    height: 2rem;
    display: block;
    transition: $transition-fast;
  }

  &:hover {
    &:after {
      opacity: 0.9;
    }
  }
}

.moodboard-cta {
  //margin-top: 12rem;
  background-color: $deep-teal-rbga;
  padding: 1rem 2rem 0.25rem 1rem;
  position: relative;
  z-index: 30;
  width: 100%;

  @include breakpoint(medium) {
    padding: 2rem 3rem 0.25rem 3rem;
  }
}

.moodboard-cta__content {
  display: flex;
  flex-direction: column;
  //border-bottom: 1px solid rgba(255, 255, 255, 0.4);
}

.moodboard-cta__button {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.moodboard-cta__newsletter {
  margin-top: 2rem;
}
